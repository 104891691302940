import React, { useState } from "react";
import ComplaintClasses from "./Complaint.module.css";
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/react";
import { useForm } from "react-hook-form";
import Result from "./TransactionResult";
import useStore from "../../../Store";
import { toast } from "react-toastify";
import Load from "../../../Utils/loader";
import { Services } from "../../../Services";
import TransactionResult from "./TransactionResult";
function ComplaintStatus() {
    const Token = useStore((state) => state.Token);
    const token = { Authorization: `token ${Token}` };
    const [spinner, setspinner] = useState(false);
    const [search, setSearch] = useState();
    const [form, setform] = useState(true);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const {
        register: register2,
        formState: { errors: errors2 },
        handleSubmit: handleSubmit2,
    } = useForm();
    // eslint-disable-next-line
    let [loading, setLoading] = useState(false);

    // spinner css
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const getstatus = (data) => {
        setspinner(true);
        Services.TrackComplaint("POST", JSON.stringify(data), token).then(
            (res) => {
                if (res?.Status == 1) {
                    setSearch(res?.TransactionStatus);
                    setform(false);
                } else if (res?.Status == 0) {
                    setspinner(false);
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        );
    };
    const onSubmit = (data) => {
        getstatus({ ...data, trackType: "TRANSACTION_ID" });
    };
    const formtwo = (data) => {
        getstatus({ ...data, trackType: "MOBILE_NO" });
    };

    return (
        <>
            {spinner && <Load bg={true} />}
            <div className={ComplaintClasses["transactions"]}>
                {form ? (
                    <div
                        className={`${ComplaintClasses["complaint-status-wrapper"]} ${ComplaintClasses["complaint-search-wrapper"]}`}>
                        <h3>Search Transaction</h3>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div
                                className={
                                    ComplaintClasses["status-form-control"]
                                }>
                                <label htmlFor="id">Complaint ID</label>
                                <div>
                                    <input
                                        className={`input ${
                                            errors?.complaintId &&
                                            ComplaintClasses["inputerror"]
                                        }`}
                                        placeholder="Enter Complaint ID"
                                        type="text"
                                        {...register("complaintId", {
                                            required: "complaintId is required",
                                        })}
                                    />
                                    {errors?.complaintId && (
                                        <p
                                            className={
                                                ComplaintClasses["error-text"]
                                            }>
                                            {errors?.complaintId.message}
                                        </p>
                                    )}
                                </div>
                            </div>

                            <button type="submit">Submit</button>
                        </form>

                        <p className={ComplaintClasses["or"]}>OR</p>

                        <form
                            className={
                                ComplaintClasses["search-transaction-form-two"]
                            }
                            onSubmit={handleSubmit2(formtwo)}>
                            <div
                                className={
                                    ComplaintClasses["status-form-control"]
                                }>
                                <label htmlFor=" FromDate">From Date</label>
                                <div>
                                    <input
                                        className={`input ${
                                            errors2?.fromDate &&
                                            ComplaintClasses["inputerror"]
                                        }`}
                                        type="date"
                                        {...register2("fromDate", {
                                            required: " From Date is required",
                                        })}
                                    />

                                    <p
                                        className={
                                            ComplaintClasses["error-text"]
                                        }>
                                        {errors2?.fromDate?.message}
                                    </p>
                                </div>
                            </div>
                            <div
                                className={
                                    ComplaintClasses["status-form-control"]
                                }>
                                <label htmlFor=" ToDate">To Date</label>
                                <div>
                                    <input
                                        className={`input ${
                                            errors2?.toDate &&
                                            ComplaintClasses["inputerror"]
                                        }`}
                                        type="date"
                                        {...register2("toDate", {
                                            required: " To Date is required",
                                        })}
                                    />
                                    <p
                                        className={
                                            ComplaintClasses["error-text"]
                                        }>
                                        {errors2?.toDate?.message}
                                    </p>
                                </div>
                            </div>
                            <div
                                className={
                                    ComplaintClasses["status-form-control"]
                                }>
                                <label htmlFor=" MobileNumber">
                                    Mobile Number
                                </label>
                                <div>
                                    <input
                                        className={`input ${
                                            errors2?.mobileNumber &&
                                            ComplaintClasses["inputerror"]
                                        }`}
                                        type="number"
                                        {...register2("mobileNumber", {
                                            required:
                                                " Mobile number is required",
                                        })}
                                    />

                                    <p
                                        className={
                                            ComplaintClasses["error-text"]
                                        }>
                                        {errors2?.mobileNumber?.message}
                                    </p>
                                </div>
                            </div>

                            <button type="submit">Search</button>
                        </form>
                    </div>
                ) : (
                    <TransactionResult status={search} />
                )}
            </div>
        </>
    );
}

export default ComplaintStatus;
