import { loginServices } from "../../Services/loginServices";
import { Services } from "../../Services";
import ReactPaginate from "react-paginate";
import ReferralClasses from "./referral.module.css";
import Layout from "../../Utils/layout/layout";
import { BsSearch } from "react-icons/bs";
import profile from "../../Assets/navbar/profile.jpg";
import { css } from "@emotion/react";
import React, { useState, useEffect } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import useStore from "../../Store";
import Moment from "react-moment";
function Commissions() {
    const Token = useStore((state) => state.Token);
    const token = { Authorization: `token ${Token}` };
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const userData = useStore((state) => state.userData);

    const [loading, setLoading] = useState(false);
    const [referrals, setReferrals] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [pagsize, setpagesize] = useState(1);
    // eslint-disable-next-line

    const getReferrals = (offset) => {
        Services.getreferralCommissions(
            "GET",
            null,
            token,
            userData?.userId,
            offset
        )
            .then((ApiData) => {
                console.log(ApiData);
                if (ApiData?.Status === 1) {
                    setLoading(false);
                    setReferrals(ApiData?.WalletTransactions);
                    setpagesize(ApiData?.PageSize);
                    setPageCount(
                        Math.ceil(
                            Number(ApiData?.TotalRows) /
                                Number(ApiData?.PageSize)
                        )
                    );
                } else if (ApiData?.Status === 0) {
                    alert(ApiData?.Message);
                }
            })
            .catch((err) => {
                console.log(err);
                alert("something went wrong ");
            });
    };
    useEffect(() => {
        getReferrals(0);
    }, []);

    const handlePageChange = (selectedObject) => {
        getReferrals(selectedObject.selected * pagsize);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        <div className={ReferralClasses["referral"]}>
            {loading ? (
                <div className={ReferralClasses["loader"]}>
                    <ScaleLoader
                        color={"black"}
                        loading={true}
                        css={override}
                        size={150}
                    />
                </div>
            ) : (
                <div
                    className={ReferralClasses["tabel-wrap"]}
                    style={{ overflow: "auto" }}>
                    <table>
                        <thead>
                            <tr>
                                <th>User ID</th>
                                <th>Name</th>
                                <th>wallet_transactionId</th>
                                <th>razorpayTransactionId</th>
                                <th>Date & Time</th>
                                <th>Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {referrals.length ? (
                                referrals.map((item, index) => {
                                    return (
                                        <tr>
                                            <td> {item?.userId} </td>
                                            <td> {item?.firstName} </td>

                                            <td>
                                                {" "}
                                                {
                                                    item?.wallet_transactionId
                                                }{" "}
                                            </td>

                                            <td>
                                                {" "}
                                                {
                                                    item?.razorpay_TransactionId
                                                }{" "}
                                            </td>
                                            <td>
                                                <Moment
                                                    format="DD MMM YYYY hh:mm A"
                                                    subtract={{
                                                        hours: 5,
                                                        minutes: 30,
                                                    }}>
                                                    {item?.transactionDateTime}
                                                </Moment>
                                            </td>

                                            <td>₹{item?.after_balance}</td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr className={ReferralClasses["box-shadow"]}>
                                    <td
                                        colSpan={6}
                                        style={{ textAlign: "center" }}>
                                        No Transactions found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {referrals?.length > 0 && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "1rem",
                            }}>
                            <ReactPaginate
                                // pageCount={pageCount}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                onPageChange={handlePageChange}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default Commissions;
