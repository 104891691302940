import React, { useEffect, useState } from "react";
import Layout from "../../../Utils/layout/layout";
import ComplaintClasses from "./Complaint.module.css";
function ComplaintSuccess() {
    const [success, setsuccess] = useState();
    const registersuccess = localStorage.getItem("registersuccess");
    useEffect(() => {
        if (registersuccess) {
            setsuccess(JSON.parse(registersuccess));
        }
    }, []);
    return (
        <Layout Active={"Bbps"}>
            <div className={ComplaintClasses["success-Container"]}>
                <h3>Your Complaint is Successfully Registered..!</h3>
                <div className={ComplaintClasses["details"]}>
                    <div className={ComplaintClasses["detail"]}>
                        <h3>Complaint ID </h3>
                        <p>:</p>
                        <h5>{success?.complaintId}</h5>
                    </div>
                    <div className={ComplaintClasses["detail"]}>
                        <h3>Complaint Status</h3>
                        <p>:</p>
                        <h5>{success?.responseReason}</h5>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default ComplaintSuccess;
