import React, { useState } from "react";
import TopNavClasses from "./topnav.module.css";
// import Moment from "react-moment";
import Profile from "../../Assets/navbar/profile.jpg";

// import { AiOutlineDown, AiOutlineLock } from "react-icons/ai";
// import { FiUser } from "react-icons/fi";
// import { CgPassword } from "react-icons/cg";
// import { IoLinkSharp } from "react-icons/io5";
// import { RiErrorWarningLine } from "react-icons/ri";
// import { IoIosArrowForward } from "react-icons/io";
import Password from "./links/password";
import Pin from "./links/PinChange";
import ModalProfile from "./links/profile";
import Payment from "./links/payment";
import Business from "./links/BusinessInfo";
import useStore from "../../Store";
function Topnav({ children }) {
    const [colorChange, setColorchange] = useState(false);
    const [profileModal, setProfileModal] = useState(false);
    const [password, setpassword] = useState(false);
    const [pin, setPin] = useState(false);
    const [paymentModal, setPaymentModal] = useState(false);
    const [businessModal, setBusinessModal] = useState(false);
    const userData = JSON.parse(localStorage.getItem("userDetails"));
    const changeNavbarColor = () => {
        if (window.scrollY >= 80) {
            setColorchange(true);
        } else {
            setColorchange(false);
        }
    };
    window.addEventListener("scroll", changeNavbarColor);
    // const linkHandler = (e) => {
    //     let value = e.currentTarget.getAttribute("value");
    //     if (value === "password") {
    //         setpassword(true);
    //     } else if (value === "pin") {
    //         setPin(true);
    //     } else if (value === "profile") {
    //         setProfileModal(true);
    //     } else if (value === "links") {
    //         setPaymentModal(true);
    //     } else if (value === "business") {
    //         setBusinessModal(true);
    //     }
    // };

    return (
        <>
            {password && <Password modal={password} setmodal={setpassword} />}
            {pin && <Pin modal={pin} setmodal={setPin} />}
            {paymentModal && (
                <Payment modal={paymentModal} setmodal={setPaymentModal} />
            )}
            {profileModal && (
                <ModalProfile
                    modal={profileModal}
                    setmodal={setProfileModal}
                    userData={userData}
                />
            )}
            {businessModal && (
                <Business modal={businessModal} setmodal={setBusinessModal} />
            )}
            <div
                className={`${TopNavClasses["Container"]} ${
                    colorChange ? TopNavClasses["black-nav"] : ""
                }`}>
                <h3>
                    {/* Welcome Back, <span>{userData?.firstName}</span> */}
                </h3>
                <div className={TopNavClasses["Details"]}>
                    <p>
                        {/* <Moment format="dddd, MMM D YYYY">{new Date()}</Moment>{" "} */}
                    </p>
                    <img
                        src={userData.profilePic}
                        alt="profile"
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = Profile;
                        }}
                        onClick={() => {
                            setProfileModal(true);
                        }}
                    />
                    {/* <ul className={TopNavClasses["profile1"]}>
                        <li className={TopNavClasses["dropdown"]}>
                            <button className={TopNavClasses["dropbtn"]}>
                                <AiOutlineDown size={20} />
                            </button>
                            <div className={TopNavClasses["dropdown-content"]}>
                                <button value={"profile"} onClick={linkHandler}>
                                    <div>
                                        <FiUser size={22} />
                                        <p>profile</p>
                                    </div>
                                    <IoIosArrowForward />
                                </button>
                                <button value="password" onClick={linkHandler}>
                                    <div>
                                        <AiOutlineLock size={22} />
                                        <p>Reset Password</p>
                                    </div>
                                    <IoIosArrowForward />
                                </button>
                                <button value={"pin"} onClick={linkHandler}>
                                    <div>
                                        <CgPassword size={22} />
                                        <p>Change Pin</p>
                                    </div>
                                    <IoIosArrowForward />
                                </button>
                                <button value={"links"} onClick={linkHandler}>
                                    <div>
                                        <IoLinkSharp size={22} />
                                        <p>Payment Links</p>
                                    </div>
                                    <IoIosArrowForward />
                                </button>
                                <button
                                    value={"business"}
                                    onClick={linkHandler}>
                                    <div>
                                        <RiErrorWarningLine size={22} />
                                        <p>Business Info</p>
                                    </div>
                                    <IoIosArrowForward />
                                </button>
                            </div>
                        </li>
                    </ul> */}
                </div>
            </div>
        </>
    );
}

export default Topnav;
