import { Services } from "../../Services";
import ReactPaginate from "react-paginate";
import ReferralClasses from "./referral.module.css";
import Layout from "../../Utils/layout/layout";
import { BsSearch } from "react-icons/bs";
import profile from "../../Assets/navbar/profile.jpg";
import { css } from "@emotion/react";
import React, { useState, useEffect } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import useStore from "../../Store";
function Commissions() {
    const Token = useStore((state) => state.Token);
    const token = { Authorization: `token ${Token}` };
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const userData = useStore((state) => state.userData);

    const [loading, setLoading] = useState(false);
    const [referrals, setReferrals] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [pagsize, setpagesize] = useState(1);
    // eslint-disable-next-line

    const getReferrals = (offset) => {
        Services.getreferralUsers("GET", null, token, userData?.userId, offset)
            .then((ApiData) => {
                console.log(ApiData);
                if (ApiData?.Status === 1) {
                    setLoading(false);
                    setReferrals(ApiData?.Users);
                    setpagesize(ApiData?.PageSize);
                    setPageCount(
                        Math.ceil(
                            Number(ApiData?.TotalRows) /
                                Number(ApiData?.PageSize)
                        )
                    );
                } else if (ApiData?.Status === 0) {
                    alert(ApiData?.Message);
                }
            })
            .catch((err) => {
                console.log(err);
                alert("something went wrong ");
            });
    };
    useEffect(() => {
        getReferrals(0);
    }, []);
    const handlePageChange = (selectedObject) => {
        getReferrals(selectedObject.selected * pagsize);
    };
    return (
        <div className={ReferralClasses["referral"]}>
            {loading ? (
                <div className={ReferralClasses["loader"]}>
                    <ScaleLoader
                        color={"black"}
                        loading={true}
                        css={override}
                        size={150}
                    />
                </div>
            ) : (
                <div
                    className={ReferralClasses["tabel-wrap"]}
                    style={{ overflow: "auto" }}>
                    <table>
                        <thead>
                            <tr>
                                <th>User ID</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Mobile Number</th>
                                <th style={{ textAlign: "center" }}>
                                    Wallet amount
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {referrals.length ? (
                                referrals.map((item, index) => {
                                    return (
                                        <tr>
                                            <td>{item.userId}</td>
                                            <td
                                                className={
                                                    ReferralClasses["profile"]
                                                }>
                                                <p>{item.firstName}</p>
                                            </td>
                                            <td>{item.lastName}</td>
                                            <td>{item.mobileNumber}</td>
                                            <td
                                                style={{
                                                    textAlign: "center",
                                                }}>
                                                ₹ {item.walletAmount}{" "}
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr className={ReferralClasses["box-shadow"]}>
                                    <td
                                        colSpan={4}
                                        style={{ textAlign: "center" }}>
                                        No Transactions found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {referrals?.length > 0 && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "1rem",
                            }}>
                            <ReactPaginate
                                // pageCount={pageCount}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                onPageChange={handlePageChange}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default Commissions;
