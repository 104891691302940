import React, { useEffect, useState } from "react";
import DashboardClasses from "./dashboard.module.css";
import Layout from "../../Utils/layout/layout";
// import Wallet from "../../Assets/dashboard/wallet.png";
import Chart from "./charts";
import Profile from "../../Assets/dashboard/img.png";
import dashboard from "../../Assets/dashboard/dashboard.png";
import { BsArrowUpRight } from "react-icons/bs";
import { Services } from "../../Services";
import useStore from "../../Store";
import { toast } from "react-toastify";
function Dashboard() {
    const userData = useStore((state) => state.userData);
    const [userDetails, setUserDetails] = useState({ walletAmount: 0 });
    const Token = useStore((state) => state.Token);
    const token = { Authorization: `token ${Token}` };
    useEffect(() => {
        Services.getUserDetails("GET", null, token, userData.userId)
            .then((response) => {
                console.log(response);
                if (response.Status === 1) {
                    setUserDetails(response.UserDetails);
                } else {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    let data = [
        {
            name: "warren",
            type: "Transfer",
            Amount: "4500.23",
            time: "4:23:21 PM",
            Date: "	Thursday, 10 Nov 2022",
        },
        {
            name: "warren",
            type: "Transfer",
            Amount: "4500.23",
            time: "4:23:21 PM",
            Date: "	Thursday, 10 Nov 2022",
        },
        {
            name: "warren",
            Amount: "4500.23",
            type: "Transfer",
            time: "4:23:21 PM",
            Date: "	Thursday, 10 Nov 2022",
        },
        {
            name: "warren",
            Amount: "4500.23",
            type: "Received",
            time: "4:23:21 PM",
            Date: "	Thursday, 10 Nov 2022",
        },
        {
            name: "warren",
            Amount: "4500.23",
            time: "4:23:21 PM",
            type: "Received",
            Date: "	Thursday, 10 Nov 2022",
        },
        {
            name: "warren",
            Amount: "4500.23",
            type: "Transfer",
            time: "4:23:21 PM",
            Date: "	Thursday, 10 Nov 2022",
        },
        {
            name: "warren",
            Amount: "4500.23",
            type: "Transfer",
            time: "4:23:21 PM",
            Date: "	Thursday, 10 Nov 2022",
        },
        {
            name: "warren",
            Amount: "4500.23",
            type: "Received",
            time: "4:23:21 PM",
            Date: "	Thursday, 10 Nov 2022",
        },
        {
            name: "warren",
            Amount: "4500.23",
            time: "4:23:21 PM",
            type: "Transfer",
            Date: "	Thursday, 10 Nov 2022",
        },
        {
            name: "warren",
            Amount: "4500.23",
            type: "Received",
            time: "4:23:21 PM",
            Date: "	Thursday, 10 Nov 2022",
        },
        {
            name: "warren",
            Amount: "4500.23",
            time: "4:23:21 PM",
            type: "Received",
            Date: "	Thursday, 10 Nov 2022",
        },
    ];

    return (
        <Layout Active={"dashboard"}>
            <div className={DashboardClasses["Container"]}>
                <div className={DashboardClasses["section-1"]}>
                    <div className={DashboardClasses["balance"]}>
                        {/* <p> total Wallet balance</p>
                        <h3>₹ {userDetails?.walletAmount}</h3> */}
                        <p>Hello User</p>
                    </div>
                    {/* <div className={DashboardClasses["money-flow"]}>
                        <div className={DashboardClasses["header"]}>
                            <p>Total Deposits</p>
                            <div>
                                <h3>
                                    <BsArrowUpRight /> +2.6%
                                </h3>
                                <p>Than last month</p>
                            </div>
                        </div>
                        <h3>₹15,000.00</h3>
                        <img src={dashboard} alt="dashboard" />
                    </div> */}

                    {/* <div className={DashboardClasses["recentPayments"]}>
                        <Chart />
                    </div> */}
                </div>
                {/* <div className={DashboardClasses["history"]}>
                    <h3>Transaction History</h3>
                    <div
                        className={DashboardClasses["tabel-wrap"]}
                        style={{ overflow: "auto" }}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Description</th>

                                    <th>Amount</th>
                                    <th>Time</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.length ? (
                                    data.map((item, index) => {
                                        return (
                                            <tr>
                                                <td
                                                    className={
                                                        DashboardClasses[
                                                            "profile"
                                                        ]
                                                    }>
                                                    <img
                                                        src={Profile}
                                                        alt="profile"
                                                    />
                                                    <div>
                                                        <h4>Received From</h4>
                                                        <p>{item.name}</p>
                                                    </div>
                                                </td>
                                                <td>₹ {item.Amount}</td>
                                                <td>{item.time}</td>
                                                <td>{item.Date}</td>
                                                <td
                                                    className={
                                                        DashboardClasses[
                                                            "success"
                                                        ]
                                                    }>
                                                    <p>success</p>
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr
                                        className={
                                            DashboardClasses["box-shadow"]
                                        }>
                                        <td>No Transactions found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div> */}
            </div>
        </Layout>
    );
}

export default Dashboard;
