import React, { useState } from "react";
import ReferralClasses from "./referral.module.css";
import Layout from "../../Utils/layout/layout";
import { BsSearch } from "react-icons/bs";
import Commissions from "./Commissions";
import Users from "./users";
function Referral() {
    const [active, setActive] = useState("referralUsers");

    const activeHandler = (e) => {
        let button = e.target.value;
        setActive(button);
    };
    return (
        <Layout Active={"referral"}>
            <div className={ReferralClasses["Container"]}>
                <div className={ReferralClasses["header"]}>
                    <button
                        value={"referralUsers"}
                        onClick={activeHandler}
                        className={
                            active === "referralUsers"
                                ? ReferralClasses["active"]
                                : ReferralClasses["inactive"]
                        }>
                        Referral Users
                    </button>
                    <button
                        className={
                            active === "Commissions"
                                ? ReferralClasses["active"]
                                : ReferralClasses["inactive"]
                        }
                        onClick={activeHandler}
                        value="Commissions">
                        Referrals Commissions
                    </button>
                </div>
                {active === "Commissions" ? <Commissions /> : <Users />}
            </div>
        </Layout>
    );
}

export default Referral;
