import React, { Component } from "react";
import Slider from "react-slick";

import offer1 from "../../images/Offer - 1.png";
import offer2 from "../../images/Offer - 2.png";
import offer3 from "../../images/Offer-3.png";

export default class SimpleSlider extends Component {
    render() {
        const settings = {
            className: "center slider variable-width",
            centerMode: true,
            infinite: true,
            centerPadding: "60px",
            slidesToShow: 1,
            speed: 400,
            variableWidth: true,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            cssEase: "linear",
            responsive: [
                {
                    breakpoint: 572,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: true,
                        variableWidth: false,
                    },
                },
            ],
        };
        return (
            <div>
                <section className="offers">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <h2 className="text-white text-center text-md-start offhead">
                                    No Risk. All Reward.
                                </h2>
                            </div>
                        </div>
                    </div>
                </section>
                <Slider {...settings} className="sbg">
                    <div>
                        <img
                            className="simg"
                            loading="lazy"
                            src={offer1}
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            className="simg"
                            loading="lazy"
                            src={offer2}
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            className="simg"
                            loading="lazy"
                            src={offer3}
                            alt=""
                        />
                    </div>
                </Slider>
            </div>
        );
    }
}
