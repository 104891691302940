import CreditCard from "../../Assets/Bbps/creditcard.png";
import broadband from "../../Assets/Bbps/broadband.png";
import water from "../../Assets/Bbps/water.png";
import electricity from "../../Assets/Bbps/electricity.png";
import cable from "../../Assets/Bbps/cable.png";
import hospital from "../../Assets/Bbps/hospital.png";
import clubs from "../../Assets/Bbps/clubs.png";
import dth from "../../Assets/Bbps/dth.png";
import Education from "../../Assets/Bbps/education.png";
import insurance from "../../Assets/Bbps/insurance.png";
import gas from "../../Assets/Bbps/insurance.png";
import fasttag from "../../Assets/Bbps/fasttag.png";
import Housing from "../../Assets/Bbps/housing.png";
import landline from "../../Assets/Bbps/landline.png";
import repayment from "../../Assets/Bbps/repayment.png";
import lpg from "../../Assets/Bbps/lpg.png";
import municipal from "../../Assets/Bbps/municipal.png";
import life from "../../Assets/Bbps/life.png";
import healthcare from "../../Assets/Bbps/healthcare.png";
import mobilepostpaid from "../../Assets/Bbps/mobilepostpaid.png";
import subscription from "../../Assets/Bbps/subscription.png";
import mobileprepaid from "../../Assets/Bbps/mobileprepaid.png";
import tax from "../../Assets/Bbps/tax.png";
export const payBills = [
    {
        icon: broadband,
        Name: "Broadband PostPaid",
    },
    {
        icon: cable,
        Name: "Cable Tv",
    },
    {
        icon: CreditCard,
        Name: "Credit Card",
    },
    {
        icon: electricity,
        Name: "Electricity",
    },
    {
        icon: hospital,
        Name: "Hospital and Pathology",
    },
    {
        icon: clubs,
        Name: "Clubs and Associations",
    },
    {
        icon: dth,
        Name: "DTH",
    },
    {
        icon: Education,
        Name: "Education Fees",
    },
    {
        icon: insurance,
        Name: "Insurance",
    },
    {
        icon: gas,
        Name: "GAS",
    },
    {
        icon: fasttag,
        Name: "FastTag",
    },
    {
        icon: gas,
        Name: "Gas",
    },
    {
        icon: Housing,
        Name: "Housing Society",
    },
    {
        icon: landline,
        Name: "Landline Postpaid",
    },
    {
        icon: repayment,
        Name: "Loan repayment",
    },
    {
        icon: lpg,
        Name: "LPG Gas",
    },
    {
        icon: tax,
        Name: "Municipal Taxes",
    },
    {
        icon: life,
        Name: "Life Insurance",
    },
    {
        icon: healthcare,
        Name: "Health Insurance",
    },
    {
        icon: mobilepostpaid,
        Name: "Mobile Postpaid",
    },
    {
        icon: subscription,
        Name: "Subscription",
    },
    {
        icon: mobileprepaid,
        Name: "Mobile Prepaid",
    },
    {
        icon: municipal,
        Name: "Municipal Services",
    },
];
