import React, { useState } from "react";
import classes from "./Bbps.module.css";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import useStore from "../../Store";
import { toast } from "react-toastify";
import { Services } from "../../Services";
import QuickPayDetails from "./quickpayDetails";
function QuickPay() {
    const [submitEnabler, setSubmitEnabler] = useState(false);
    const [dropdown, setDropDown] = useState();
    const [form, setform] = useState("form1");
    const Token = useStore((state) => state.Token);
    const token = { Authorization: `token ${Token}` };
    const Navigate = useNavigate();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const biller = [
        "Broadband Postpaid",
        "Cable Tv",
        "Clubs and Associations",
        "Credit Card",
        "DTH",
        "Electricity",
        "Fastag",
        "Gas",
        "Insurance",
        "Hospital and pathology",
        "Landline Postpaid",
        "Loan Repayment",
        "LPG Gas",
        "Subscription",
        "Education Fees",
        "Municipal Services",
        "Municipal Taxes",
        "Mobile Postpaid",
        "Mobile Prepaid",
        "Water",
        "Housing Society",
    ];

    const Submithandler = (data) => {
        localStorage.setItem("BillerCat", data.BillerCat);
        localStorage.setItem("Biller", data.Biller);
        setform("form2");
    };
    const selectBillerHandler = (e) => {
        Services.fetchBillerByCategoryName(
            "GET",
            null,
            token,
            e.target.value,
            1
        )
            .then((res) => {
                console.log(res);
                if (res.Status === 1) {
                    setSubmitEnabler(true);
                    setDropDown(res.BillersList);
                    reset({ Biller: "" });
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <>
            <div className={classes["quick-pay-wrapper"]}>
                {form === "form1" && (
                    <form
                        className="biller-details"
                        id="quickpay"
                        onSubmit={handleSubmit(Submithandler)}>
                        <select
                            {...register("BillerCat", {
                                required: "Please select Biller Category",
                                onChange: (e) => {
                                    selectBillerHandler(e);
                                },
                            })}
                            defaultValue="">
                            <option value="" disabled>
                                select Biller Category
                            </option>
                            {biller.map((item) => {
                                return <option value={item}>{item}</option>;
                            })}
                        </select>
                        {submitEnabler && (
                            <>
                                <select
                                    className={` ${
                                        errors?.Biller && classes["input-error"]
                                    }`}
                                    defaultValue={""}
                                    {...register("Biller", {
                                        required: "Please select Biller",
                                    })}>
                                    <option value="" disabled>
                                        Biller Name
                                    </option>
                                    {dropdown?.map((item) => (
                                        <option value={item?.blr_id}>
                                            {" "}
                                            {item?.blr_name}
                                        </option>
                                    ))}
                                </select>
                                {errors?.Biller && (
                                    <p style={{ color: "red" }}>
                                        {" "}
                                        {errors?.Biller.message}
                                    </p>
                                )}
                                <button type="submit">Submit</button>
                            </>
                        )}
                    </form>
                )}
                {form == "form2" && <QuickPayDetails />}
            </div>
        </>
    );
}

export default QuickPay;
