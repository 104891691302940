import React from "react";
import refundClasses from "./privacy.module.css";

export default function TermsPage() {
    return (
        <div className={refundClasses["Container"]}>
            <h1>Cancellation And Refund Policy</h1>
            <p>
                As a general rule, no refund will be provided until and unless
                such circumstances exist that the Website deems fit to refund
                the transaction money.A transaction may be reversed or charged
                back to the account if it is disputed by the account holder for
                any reason by the Network, deemed to be fraudulent, deemed to be
                in violation of this Agreement, or we have any reason to believe
                that the transaction was not completed in good faith.
            </p>

            <p>
                For any transaction that results in a chargeback, the Website
                may withhold the transaction amount from your account.If your
                account has any pending resolution of any charge backs, the
                Website has the discretion to delay your next bill payments(s).
            </p>

            {/* <h5>SECTION II - GENERAL CONDITIONS</h5> */}
            <p>
                The Website reserves the right to withhold from your account the
                amount of one or more transactions, if it believes that there is
                a significantly increased risk of a chargeback occurring on
                these transactions. The Website may also charge you a fee for
                each refund, or chargeback, or dispute incurred.
            </p>

            <p>
                If your account is incurring a significantly high volume of
                charge backs according to the Website, the Website reserves the
                right to
            </p>
            <ol type="a">
                <li> suspend your account,</li>
                <li> increase processing fees,</li>
                <li>withhold bill payments </li>
                <li>
                    {" "}
                    increase holding period for your bill payments to be paid
                    out,{" "}
                </li>
                <li>implement a rolling reserve.</li>
            </ol>

            <p>
                The Website reserves the right to charge a "Penalty fee" from
                the Users, including users and Service providers, for abusing
                the platform, towards the charges incurred for the
                investigation, operational handling and legal consultation
                charges.
            </p>
        </div>
    );
}
