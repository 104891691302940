import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { CiWarning } from "react-icons/ci";
import logoutClasses from "./navbar.module.css";
import { useNavigate } from "react-router";
function Logout(props) {
    const Navigate = useNavigate();
    return (
        <Modal
            open={props.modal}
            center
            onClose={() => {
                props.setmodal(false);
            }}>
            <div className={logoutClasses["logout_wrapper"]}>
                <CiWarning size={100} color="orange" />
                <h3>Are you sure you want to Logout?</h3>
                <div className={logoutClasses["logout_action_btns"]}>
                    <button
                        onClick={() => {
                            Navigate("/");
                            localStorage.clear();
                        }}>
                        Logout
                    </button>
                    <button onClick={() => props.setmodal(false)}>
                        Cancel
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default Logout;
