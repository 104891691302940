import React from "react";

import ComplaintClasses from "./Complaint.module.css";
function ComplaintFetch({ complaintStatus }) {
    return (
        <div className={ComplaintClasses["fetch-Container"]}>
            <h1>Complaint Status</h1>
            <div className={ComplaintClasses["fetch-detail"]}>
                <h3>Complaint Assigned</h3>
                <p>:</p>
                <h5>{complaintStatus?.complaintAssigned}</h5>
            </div>
            <div className={ComplaintClasses["fetch-detail"]}>
                <h3>Complainted ID</h3>
                <p>:</p>
                <h5>{complaintStatus?.complaintId}</h5>
            </div>
            <div className={ComplaintClasses["fetch-detail"]}>
                <h3>Complaint Status</h3>
                <p>:</p>
                <h5>{complaintStatus?.complaintStatus}</h5>
            </div>
        </div>
    );
}

export default ComplaintFetch;
