import React, { useState } from "react";
import { useForm } from "react-hook-form";
import bankClasses from "./bank.module.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { toast } from "react-toastify";
import { Services } from "../../../../Services";
import useStore from "../../../../Store";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
function BankContact(props) {
    const [verification, setVerification] = useState(false);
    const Token = useStore((state) => state.Token);
    const userData = useStore((state) => state.userData);
    const token = { Authorization: `token ${Token}` };
    const [loading, setLoading] = useState(false);
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const formData = watch();
    const onSubmit = (data) => {
        let formDetails = { ...data, isVerificationRequired: 0 };
        if (verification) {
            formDetails = { ...formDetails, isVerificationRequired: 1 };
        }

        Services.createPayoutContact(
            "POST",
            JSON.stringify({ ...formDetails }),
            token
        )
            .then((Response) => {
                if (Response.Status === 1) {
                    props.getPayoutContacts();
                    props.setmodal(false);
                } else if (Response.Status === 0) {
                    toast.error(`${Response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const verificationchange = (e) => {
        // setLoading(true);
        setVerification(e.target.checked);
        // Services.VerifyBankDetails(
        //     "POST",
        //     JSON.stringify({
        //         accountNumber: formData.accountNumber,
        //         mobileNumber: formData.mobileNumber,
        //         IFSCCode: formData.IFSCCode,
        //     }),
        //     token,
        //     userData.userId
        // )
        //     .then((Response) => {
        //         setLoading(false);
        //         console.log(Response);
        //         if (Response.Status === 1) {
        //             setVerification(true);
        //         } else if (Response.Status === 0) {
        //             toast.error(`${Response.Message}`, {
        //                 position: "top-center",
        //                 autoClose: 1000,
        //                 hideProgressBar: true,
        //                 closeOnClick: true,
        //                 pauseOnHover: true,
        //                 draggable: true,
        //                 progress: undefined,
        //             });
        //         }
        //     })
        //     .catch((err) => {
        //         setLoading(false);
        //         console.log(err);
        //     });
    };
    return (
        <Modal
            open={props.modal}
            center
            onClose={() => {
                props.setmodal(false);
            }}>
            <div className={bankClasses["form"]}>
                <h3>Add Beneficiary</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={bankClasses["form-control"]}>
                        <input
                            placeholder="Enter Full Name"
                            type={"text"}
                            {...register("name", { required: "true" })}
                        />
                        {errors.name && <p>This is required!</p>}
                    </div>
                    <div className={bankClasses["form-control"]}>
                        <input
                            type="number"
                            placeholder="Enter Mobile Number"
                            {...register("mobileNumber", { required: "true" })}
                        />
                        {errors.mobileNumber && <p>This is required!</p>}
                    </div>
                    <div className={bankClasses["form-control"]}>
                        <input
                            placeholder="Enter Bank Account Number"
                            type="number"
                            {...register("accountNumber", { required: "true" })}
                        />
                        {errors.accountNumber && <p>This is required!</p>}
                    </div>
                    <div className={bankClasses["form-control"]}>
                        <input
                            placeholder="Enter IFSC Code"
                            type={"text"}
                            {...register("IFSCCode", { required: "true" })}
                        />
                        {errors.IFSCCode && <p>This is required!</p>}
                    </div>
                    {loading ? (
                        <ClipLoader
                            color={"black"}
                            loading={true}
                            css={override}
                            size={30}
                        />
                    ) : (
                        <div className={bankClasses["checkbox"]}>
                            <input
                                onChange={verificationchange}
                                type="checkbox"
                                id="verification"
                                name="verification"
                                value="verification"
                                checked={verification}
                            />
                            <label for="verification">
                                {" "}
                                is verification required (Rs.4 for processing
                                charges)
                            </label>
                            <br />
                        </div>
                    )}
                    <p className={bankClasses["note"]}>
                        <span>Please Note:</span> Transfer of funds from your
                        credit card to your personal bank a/c is not allowed.
                        The amount will be refunded in 7 to 10 working days.
                        Transfer charges will not be refunded
                    </p>
                    <button type="submit">Add Beneficiary</button>
                </form>
            </div>
        </Modal>
    );
}

export default BankContact;
