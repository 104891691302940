//node packages
import React from "react";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Services } from "../../Services";
import { toast } from "react-toastify";

//css modules
import loginClasses from "./login.module.css";

//Assets
import logo from "../../Assets/Homepage/logo.png";
function Login() {
    const Navigate = useNavigate();
    const formdata = JSON.parse(localStorage.getItem("signupData"));
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const onSubmit = (data) => {
        Services.signupDetails("POST", JSON.stringify({ ...formdata, ...data }))
            .then((Response) => {
                if (Response.Status === 1) {
                    localStorage.setItem("Token", Response.Token);
                    localStorage.setItem(
                        "userDetails",
                        JSON.stringify(Response.userDetails)
                    );
                    Navigate("/dashboard");
                    localStorage.removeItem("signupData");
                } else if (Response.Status === 0) {
                    toast.error(`${Response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div
            className={`${loginClasses["Container"]} ${loginClasses["Container-signup"]}`}>
            <NavLink to={"/"}>
                <img src={logo} alt="logo" />
            </NavLink>
            <div className={`${loginClasses["login-form"]}`}>
                <h3>Signup</h3>
                <p>enter your details to signup to your account</p>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={loginClasses["form-control"]}>
                        <input
                            type={"text"}
                            className={
                                errors.firstName ? loginClasses["error"] : ""
                            }
                            placeholder="Enter First Name"
                            {...register("firstName", {
                                required: false,
                            })}
                        />

                        {errors.firstName && (
                            <span>Your passwords do no match</span>
                        )}
                    </div>

                    <button type="submit">Signup</button>
                </form>
                <p>
                    Already Member? <NavLink to={"/login"}>Signin</NavLink>
                </p>
            </div>
        </div>
    );
}

export default Login;
