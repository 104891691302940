import React, { useState, useEffect } from "react";
import Layout from "../../Utils/layout/layout";
import { BsSearch } from "react-icons/bs";
import walletClasses from "./WalletTransfer.module.css";
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import Modal from "./pay/pay";
import img from "../../Assets/navbar/profile.jpg";
import { Services } from "../../Services";
import useStore from "../../Store";
import Add from "./add/add";
import { toast } from "react-toastify";
function WalletTransfer() {
    // eslint-disable-next-line
    let [loading, setLoading] = useState(false);
    let [modal, setModal] = useState(false);
    const [Contacts, setContacts] = useState([]);
    const [searchTerm, setsearchterm] = useState(null);
    const [selectedContact, setSelectedContact] = useState({});
    const [addModal, setAddModal] = useState(false);
    const [emptyContact, setEmptyContact] = useState(false);
    const Token = useStore((state) => state.Token);
    const token = { Authorization: `token ${Token}` };
    // spinner css
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;

    const getContacts = () => {
        setEmptyContact(false);
        Services.searchusers("POST", JSON.stringify(searchTerm), token)
            .then((response) => {
                console.log(response);
                setLoading(false);
                if (response.Status === 1) {
                    setContacts(response.WalletContacts);
                    if (response.WalletContacts.length === 0) {
                        console.log("asd");
                        setEmptyContact(true);
                    }
                } else {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                alert("something went wrong please try again");
            });
    };

    const contactHandler = (e) => {
        e.stopPropagation();
        let [selected] = Contacts.filter(
            (item) => item.userId === Number(e.currentTarget.getAttribute("id"))
        );
        setSelectedContact(selected);
        setModal(true);
    };
    const searchHandler = () => {
        setLoading(true);
        getContacts();
    };
    const searchStringHandler = (e) => {
        if (!isNaN(+e.target.value)) {
            setsearchterm({ mobileNumber: e.target.value });
        } else {
            setsearchterm({ name: e.target.value });
        }
    };

    return (
        <Layout Active={"walletTransfer"}>
            {addModal && (
                <Add
                    getcontact={getContacts}
                    modal={addModal}
                    setmodal={setAddModal}
                />
            )}
            {modal && (
                <Modal
                    modal={modal}
                    setmodal={setModal}
                    selected={selectedContact}
                />
            )}

            <div className={walletClasses["Container"]}>
                <div className={walletClasses["searchContainer"]}>
                    <div className={walletClasses["search"]}>
                        <input
                            placeholder="Search with Name or Mobile Number"
                            onChange={searchStringHandler}
                        />
                        <BsSearch
                            color="grey"
                            className={walletClasses["icon"]}
                        />
                    </div>
                    <button onClick={searchHandler}>Search</button>
                </div>
                {loading && (
                    <div className={walletClasses["loader"]}>
                        <ScaleLoader
                            color={"black"}
                            loading={true}
                            css={override}
                            size={150}
                        />
                    </div>
                )}
                {Contacts.length > 0 && (
                    <div className={walletClasses["transfer"]}>
                        <div className={walletClasses["Contacts"]}>
                            {Contacts.map((item) => (
                                <div
                                    className={walletClasses["contact"]}
                                    onClick={contactHandler}
                                    id={item.userId}>
                                    <div className={walletClasses["name"]}>
                                        <img
                                            src={item.profilePic}
                                            alt="profile"
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = img;
                                            }}
                                        />
                                        <p>{item.firstName}</p>
                                    </div>

                                    <p>Mobile No :{item.mobileNumber}</p>
                                    {/* <p>city:{item.city}</p> */}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {emptyContact && (
                    <div className={walletClasses["empty-contact"]}>
                        <p>No Contacts Found</p>
                    </div>
                )}
            </div>
        </Layout>
    );
}

export default WalletTransfer;
