import React, { useState } from "react";
import ComplaintClasses from "./Complaint.module.css";
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/react";
import { useForm } from "react-hook-form";
import { Services } from "../../../Services";
import useStore from "../../../Store";
import { toast } from "react-toastify";
import Load from "../../../Utils/loader";
import ComplaintFetch from "./ComplaintFetch";

function ComplaintStatus() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    // eslint-disable-next-line
    const [spinner, setspinner] = useState(false);
    const Token = useStore((state) => state.Token);
    const token = { Authorization: `token ${Token}` };
    const [complaintStatus, setComplaitStatus] = useState();
    const [form, setform] = useState(true);
    const onSubmit = (data) => {
        Services.TrackComplaint("POST", JSON.stringify(data), token).then(
            (res) => {
                if (res?.Status == 1) {
                    setComplaitStatus(res?.TrackComplaintResponse);
                    setform(false);
                } else if (res?.Status == 0) {
                    setspinner(false);
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        );
    };

    return (
        <>
            {spinner && <Load bg={true} />}
            <div className={ComplaintClasses["transactions"]}>
                {form ? (
                    <div
                        className={
                            ComplaintClasses["complaint-status-wrapper"]
                        }>
                        <h3>Check Complaint Status</h3>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div
                                className={
                                    ComplaintClasses["status-form-control"]
                                }>
                                <label htmlFor="id">Complaint ID</label>
                                <div>
                                    <input
                                        className={`input ${
                                            errors?.complaintId &&
                                            ComplaintClasses["inputerror"]
                                        }`}
                                        placeholder="Enter Complaint ID"
                                        type="text"
                                        {...register("complaintId", {
                                            required: "complaintId is required",
                                        })}
                                    />
                                    {errors?.complaintId && (
                                        <p
                                            className={
                                                ComplaintClasses["error-text"]
                                            }>
                                            {errors?.complaintId.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    ComplaintClasses["status-form-control"]
                                }>
                                <label htmlFor="Select Reason">
                                    Type of Complaint
                                </label>
                                <div>
                                    <select
                                        defaultValue=""
                                        className={`select ${
                                            errors?.complaintType &&
                                            ComplaintClasses["inputerror"]
                                        }`}
                                        {...register("complaintType", {
                                            required:
                                                "complaintType is required",
                                        })}>
                                        <option value="" disabled>
                                            TYPE
                                        </option>
                                        <option value="SERVICE">SERVICE</option>
                                        <option value="TRANSACTION">
                                            TRANSACTION
                                        </option>
                                    </select>
                                    {errors?.complaintType && (
                                        <p
                                            className={
                                                ComplaintClasses["error-text"]
                                            }>
                                            {errors?.complaintType.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <button type="submit">Submit</button>
                        </form>
                    </div>
                ) : (
                    <ComplaintFetch complaintStatus={complaintStatus} />
                )}
            </div>
        </>
    );
}

export default ComplaintStatus;
