import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import menuClasses from "./links.module.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { BiLockAlt, BiEditAlt } from "react-icons/bi";
import Profile from "../../../Assets/navbar/profile.jpg";
import useStore from "../../../Store";
function Pay(props) {
    const userData = useStore((state) => state.userData);
    const [edit, setEdit] = useState(true);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    // console.log();
    console.log("111");
    useEffect(() => {
        reset(JSON.parse(localStorage.getItem("userDetails")));
    }, []);
    const onSubmit = (data) => {
        props.setmodal(false);
    };
    return (
        <Modal
            open={props.modal}
            center
            onClose={() => {
                props.setmodal(false);
            }}>
            <div className={menuClasses["form"]}>
                <img
                    src={userData.profilePic}
                    alt="profile"
                    onError={(e) =>
                        (e.target.onerror = null)((e.target.src = Profile))
                    }
                />
                {/* <button onClick={() => setEdit((prev) => !prev)}>
                    <BiEditAlt size={22} />
                    Edit Profile
                </button> */}
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    autoComplete={false}
                    autoCorrect={false}>
                    <div className={menuClasses["form-control"]}>
                        <input
                            placeholder="Name"
                            type={"text"}
                            disabled={edit}
                            {...register("firstName,", { required: "true" })}
                        />

                        {errors.firstName && <p>This is required!</p>}
                    </div>
                    <div className={menuClasses["form-control"]}>
                        <input
                            placeholder="Email"
                            type={"email"}
                            disabled={edit}
                            {...register("emailId", { required: "true" })}
                        />

                        {errors.emailId && <p>This is required!</p>}
                    </div>
                    <div className={menuClasses["form-control"]}>
                        <input
                            placeholder="Mobile"
                            type={"text"}
                            disabled={edit}
                            {...register("mobileNumber", {
                                required: "This is required!",
                            })}
                        />

                        {errors.mobileNumber && (
                            <p>{errors?.mobileNumber?.message}</p>
                        )}
                    </div>
                    <div className={menuClasses["form-control"]}>
                        <textarea
                            placeholder="address"
                            type={"text"}
                            disabled={edit}
                            {...register("address", {
                                required: "This is required!",
                            })}
                            cols="30"
                            rows="5"></textarea>

                        {errors.city && <p>{errors?.city?.message}</p>}
                    </div>
                    <div className={menuClasses["form-control"]}>
                        <input
                            placeholder="aadharNumber"
                            type={"text"}
                            disabled={edit}
                            {...register("aadharNumber", {
                                required: "This is required!",
                            })}
                        />

                        {errors.aadharNumber && (
                            <p>{errors?.Mobile?.message}</p>
                        )}
                    </div>
                    {/* <button type="submit">Save</button> */}
                </form>
            </div>
        </Modal>
    );
}

export default Pay;
