//Components
import Header from "./header/Header";
import About from "./About/about";
import Services from "./Services/services";
// import Testimonials from "./testimonials/Testimonials";
// import Playstore from "./playstore/playstore";
import Screenshots from "./screenshots/screenshots";
import Footer from "./Footer/footer";
import Layout from "./layout";
function Index() {
    return (
        <Layout>
            <Header stickyNav={false} />
            <About />
            <Services />
            {/* <Playstore /> */}
            <Screenshots />
            <Footer />
        </Layout>
    );
}

export default Index;
