import React, { useState, useEffect, useRef } from "react";
import Layout from "../../Utils/layout/layout";
import TransactionClasses from "./transaction.module.css";
import Moment from "react-moment";
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/react";
import useStore from "../../Store";
import { Services } from "../../Services";
import ReactPaginate from "react-paginate";
import TransactionModal from "../Statements/transactionModal/modal";
import { BsDownload } from "react-icons/bs";
import axios from "axios";
function Transactions() {
    // eslint-disable-next-line
    let [loading, setLoading] = useState(true);
    const [transactionId, setTransactionId] = useState("");
    const [trnasactionModal, setTransactionModal] = useState(false);
    const fromRef = useRef();
    const toRef = useRef();
    const mobileRef = useRef();
    const userData = useStore((state) => state.userData);
    const token = useStore((state) => state.Token);
    const [pageCount, setPageCount] = useState(1);
    const [pagsize, setpagesize] = useState(1);
    const [dateFilter, setDateFilter] = useState(false);
    // const [loading,setloading]=useState(false)
    const [walletTransactionFilter, setWalletTransactionsfilter] = useState([]);
    // spinner css
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const walletTransactions = () => {
        const fromDate = fromRef.current.value;
        const toDate = toRef.current.value;

        if (new Date(fromDate) > new Date(toDate)) {
            alert("Start date is not more than end date");
            return;
        }
        let payload = {
            fromDate: fromDate,
            toDate: toDate,
        };

        let config = { Authorization: `token ${token}` };
        Services.walletTransactionsDownload(
            "POST",
            JSON.stringify(payload),
            config,
            userData?.userId
        )
            .then((res) => {
                console.log(res);
                if (res.Status === 1) {
                    fetch(res.FileURL).then((response) => {
                        if (response.Status == 1) {
                            window.open(response.FileURL, "_blank");
                        }
                        // response.blob().then((blob) => {
                        //     // Creating new object of PDF file
                        //     const fileURL = window.URL.createObjectURL(blob);
                        //     // Setting various property values
                        //     let alink = document.createElement("a");
                        //     alink.href = fileURL;
                        //     alink.download = `$transactions.csv`;
                        //     alink.click();
                        // });
                    });
                } else {
                    alert(res.Message);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };
    const getTransactions = (offset) => {
        setLoading(true);
        let config = { Authorization: `token ${token}` };
        Services.getWalletTransactions(
            "GET",
            null,
            config,
            userData?.userId,
            offset
        )
            .then((ApiData) => {
                console.log(ApiData);
                if (ApiData?.Status === 1) {
                    setLoading(false);
                    setWalletTransactionsfilter(ApiData.WalletTransactions);
                    setpagesize(ApiData?.PageSize);
                    setPageCount(
                        Math.ceil(
                            Number(ApiData?.TotalRows) /
                                Number(ApiData?.PageSize)
                        )
                    );
                } else if (ApiData?.Status === 0) {
                    alert(ApiData?.Message);
                }
            })
            .catch((err) => {
                console.log(err);
                alert("something went wrong ");
            });
    };
    useEffect(() => {
        getTransactions(0);
        // eslint-disable-next-line
    }, []);

    const fetchStatementsByDate = (offset) => {
        setLoading(true);
        const fromDate = fromRef.current.value;
        const toDate = toRef.current.value;
        if (new Date(fromDate) > new Date(toDate)) {
            alert("Start date is not more than end date");
            return;
        }
        let payload = {
            fromDate: fromDate,
            toDate: toDate,
        };

        let config = { Authorization: `token ${token}` };
        Services.getWalletTransactions(
            "POST",
            JSON.stringify(payload),
            config,
            userData?.userId,
            offset
        )
            .then((response) => {
                if (response.Status === 1) {
                    setWalletTransactionsfilter(response.WalletTransactions);
                    setDateFilter(true);
                    setpagesize(response?.PageSize);
                    setPageCount(
                        Math.ceil(
                            Number(response?.TotalRows) /
                                Number(response?.PageSize)
                        )
                    );
                    setLoading(false);
                } else {
                    alert("something went wrong please try again");
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };
    const submitHandler = (e) => {
        e.preventDefault();
        if (
            fromRef.current.value?.length > 0 &&
            toRef.current.value?.length > 0
        ) {
            fetchStatementsByDate(0);
        } else if (fromRef.current.value?.length === 0) {
            alert("From Date is required");
        } else if (toRef.current.value?.length === 0) {
            alert("To Date is required");
        }
    };
    const handlePageChange = (selectedObject) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        if (dateFilter) {
            fetchStatementsByDate(selectedObject.selected * pagsize);
            return;
        }
        getTransactions(selectedObject.selected * pagsize);
    };
    const transactionHandler = (e) => {
        setTransactionModal(true);
        setTransactionId(e.currentTarget.getAttribute("id"));
    };

    return (
        <Layout Active={"transactions"}>
            {trnasactionModal && (
                <TransactionModal
                    transactionId={transactionId}
                    modal={trnasactionModal}
                    setmodal={setTransactionModal}
                />
            )}
            <div className={TransactionClasses["Container"]}>
                <div className={TransactionClasses["filters-wrapper"]}>
                    <div className={TransactionClasses["Date"]}>
                        <form onSubmit={submitHandler}>
                            <input type={"date"} ref={fromRef} />
                            <input
                                type={"date"}
                                placeholder="MM/DD/YYYY"
                                ref={toRef}
                            />

                            <button type="submit">Show Results</button>
                        </form>
                        <button onClick={walletTransactions}>
                            <BsDownload /> Download
                        </button>
                    </div>
                </div>
                <div className={TransactionClasses["transactions"]}>
                    <div
                        className={TransactionClasses["tabel-wrap"]}
                        style={{ overflow: "auto" }}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Date & Time</th>
                                    <th>Order ID</th>
                                    <th>Particulars</th>
                                    <th>Debited</th>
                                    <th>Credited</th>
                                    <th>Balance</th>
                                </tr>
                            </thead>
                            {loading ? (
                                <tbody>
                                    <td colSpan={6} style={{ border: "none" }}>
                                        <div
                                            className={
                                                TransactionClasses["loader"]
                                            }>
                                            <ScaleLoader
                                                color={"black"}
                                                loading={true}
                                                css={override}
                                                size={150}
                                            />
                                        </div>
                                    </td>
                                </tbody>
                            ) : (
                                <tbody>
                                    {walletTransactionFilter.length ? (
                                        walletTransactionFilter.map(
                                            (item, index) => {
                                                return (
                                                    <tr
                                                        onClick={
                                                            transactionHandler
                                                        }
                                                        id={
                                                            item.wallet_transactionId
                                                        }>
                                                        <td>
                                                            <Moment
                                                                format="DD MMM YYYY hh:mm A"
                                                                subtract={{
                                                                    hours: 5,
                                                                    minutes: 30,
                                                                }}>
                                                                {
                                                                    item.transactionDateTime
                                                                }
                                                            </Moment>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                {
                                                                    item.wallet_transactionId
                                                                }
                                                            </p>
                                                        </td>
                                                        <td
                                                            style={{
                                                                maxWidth:
                                                                    "250px",
                                                                fontWeight:
                                                                    "550",
                                                                lineHeight:
                                                                    "1.4rem",
                                                                textTransform:
                                                                    "lowercase",
                                                            }}>
                                                            {item?.description}
                                                        </td>

                                                        <td>
                                                            {(item?.transactionType ===
                                                                2 ||
                                                                item?.transactionType ===
                                                                    3 ||
                                                                item?.transactionType ===
                                                                    6 ||
                                                                item?.transactionType ===
                                                                    7) && (
                                                                <span
                                                                    style={{
                                                                        color: "#F0532D",
                                                                        fontWeight: 500,
                                                                    }}>
                                                                    ₹{" "}
                                                                    {
                                                                        item?.amount
                                                                    }
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {(item?.transactionType ===
                                                                4 ||
                                                                item?.transactionType ===
                                                                    1 ||
                                                                item?.transactionType ===
                                                                    5) && (
                                                                <span
                                                                    style={{
                                                                        color: "#0DB14B",
                                                                        fontWeight: 500,
                                                                    }}>
                                                                    ₹{" "}
                                                                    {
                                                                        item?.amount
                                                                    }
                                                                </span>
                                                            )}
                                                        </td>

                                                        <td>
                                                            <span
                                                                style={{
                                                                    color: "#0DB14B",
                                                                    fontWeight: 500,
                                                                }}>
                                                                ₹{" "}
                                                                {
                                                                    item?.after_balance
                                                                }
                                                            </span>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )
                                    ) : (
                                        <tr
                                            className={
                                                TransactionClasses["box-shadow"]
                                            }>
                                            <td colSpan={8}>
                                                No Transactions found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            )}
                        </table>
                        {walletTransactionFilter?.length > 0 && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "1rem",
                                }}>
                                <ReactPaginate
                                    // pageCount={pageCount}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    onPageChange={handlePageChange}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    containerClassName={"pagination"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Transactions;
