const httpcall = (url, method, body, token) => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
        method: method,
        body: body,
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            ...token,
        },
    }).then((response) => response.json()).then((res) => {
        if (res.Status === 0) {
            if (
                res.Message == "Invalid Token" ||
                String(res.Message).toLowerCase().trim() == String("Token Expired").toLowerCase().trim()
            ) {
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = "/";
            }
        }
        return res;
    })
};

const login = (method, body) => {
    return httpcall("users/login", method, body);
};

const verifyloginOtp = (method, body) => {
    return httpcall("users/login/verifyotp", method, body);
};

const signup = (method, body) => {
    return httpcall("users/signup/sendotp", method, body);
};
const signupVerifyOtp = (method, body) => {
    return httpcall("users/signup/verifyotp", method, body);
};
const signupDetails = (method, body) => {
    return httpcall("users/signup", method, body);
};
const getPayoutContacts = (method, body, token, userID) => {
    return httpcall(`wallet/${userID}/contacts`, method, body, token);
};
const VerifyBankDetails = (method, body, token, userID) => {
    return httpcall(`wallet/${userID}/verify-bankdetails`, method, body, token);
};
const createPayoutContact = (method, body, token) => {
    return httpcall(
        `wallet/contacts
    `,
        method,
        body,
        token
    );
};
const payToContact = (method, body, token) => {
    return httpcall(`wallet/payout`, method, body, token);
};
const DeletePayoutContact = (method, body, token, userId, contactID) => {
    return httpcall(
        `wallet/${userId}/contacts/${contactID}`,
        method,
        body,
        token
    );
};
const searchContact = (method, body, token, userId) => {
    return httpcall(`wallet/${userId}/search-contacts`, method, body, token);
};
const searchusers = (method, body, token) => {
    return httpcall(`users/search-users`, method, body, token);
};
const transferToContactByMobileNumber = (method, body, token, userId) => {
    return httpcall(`wallet/${userId}/transfer`, method, body, token);
};
const getWalletTransactions = (method, body, token, userId, offset) => {
    return httpcall(
        `wallet/${userId}/wallet-transactions?Offset=${offset}`,
        method,
        body,
        token
    );
};
const getreferralUsers = (method, body, token, userId, offset) => {
    return httpcall(
        `users/${userId}/referred-users?offset=${offset}`,
        method,
        body,
        token
    );
};
const getreferralCommissions = (method, body, token, userId, offset) => {
    return httpcall(
        `wallet/${userId}/referral-commissions?offset=0`,
        method,
        body,
        token
    );
};
const getPayoutstatementByuserId = (method, body, token, userId, offset) => {
    return httpcall(
        `wallet/${userId}/payout-transactions?Offset=0`,
        method,
        body,
        token
    );
};
const getTransactionByID = (method, body, token, userId, id) => {
    return httpcall(
        `wallet/${userId}/wallet-transactions/${id}`,
        method,
        body,
        token
    );
};
const paymentLink = (method, body, token, userId, id) => {
    return httpcall(
        `wallet/${userId}/paymentpageid/sendsms`,
        method,
        body,
        token
    );
};
const kycSendOTP = (method, body, token) => {
    return httpcall("aadhar/getotp", method, body, token);
};
const kycverifyOTP = (method, body, token) => {
    return httpcall("aadhar/verifyotp", method, body, token);
};
const RazorPayOrder = (method, body, token) => {
    return httpcall("wallet/razorpay/orders", method, body, token);
};

const createRazorPayment = (method, body, token) => {
    return httpcall("wallet/razorpay/payments", method, body, token);
};
const fetchBillerByCategoryName = (method, body, token, name, support) => {
    return httpcall(
        `billpayment/billerlist?categoryName=${name}&QuickPaySupported=${support}`,
        method,
        body,
        token
    );
};
const fetchBillerinfoPost = (method, body, token) => {
    return httpcall(`billpayment/billerInfo`, method, body, token);
};
const RaiseComplaint = (method, body, token) => {
    return httpcall(`billpayment/register-complaint`, method, body, token);
};
const fetchBill = (method, body, token) => {
    return httpcall(`billpayment/billfetch`, method, body, token);
};
const getUserDetails = (method, body, token, userId) => {
    return httpcall(`users/${userId}`, method, body, token);
};
const walletTransactionsDownload = (method, body, token, userid) => {
    return httpcall(
        `wallet/${userid}/wallet-transactions/csv`,
        method,
        body,
        token
    );
};
const walletcommissionPercentage = (method, body, token, userid) => {
    return httpcall(
        `users/${userid}/walletcommission-percentage`,
        method,
        body,
        token
    );
};
const quickPayBills = (method, body, token) => {
    return httpcall(`billpayment/quick-billpay`, method, body, token);
};
const TrackComplaint = (method, body, token) => {
    return httpcall(`/billpayment/track-complaint`, method, body, token);
};
const searchTransaction = (method, body, token) => {
    return httpcall(`/billpayment/transaction-status`, method, body, token);
};
const contactus = (method, body, token) => {
    return httpcall(`admin/contact-us`, method, body, token);
};
const getBPBSTransactions = (method, body, token, userId) => {
    return httpcall(
        `billpayment/${userId}/BBPS-transactions`,
        method,
        body,
        token
    );
};
const sendPaymentPageID = (method, data, token) => {
    return httpcall("wallet/paymentpageid/orders", method, data, token);
};

const createRazorpayPaymentGlobal = (method, body, token) => {
    return httpcall("wallet/paymentpageid/payments", method, body, token);
};
const GetPayoutCards = (method, body, token, id) => {
    return httpcall(`wallet/${id}/saved-cards`, method, body, token);
};
const SaveCard = (method, body, token, id) => {
    return httpcall(`wallet/${id}/save-card`, method, body, token);
};
export const Services = {
    sendPaymentPageID,
    createRazorpayPaymentGlobal,
    login,
    verifyloginOtp,
    getPayoutContacts,
    createPayoutContact,
    VerifyBankDetails,
    GetPayoutCards,
    payToContact,
    SaveCard,
    DeletePayoutContact,
    searchContact,
    searchusers,
    transferToContactByMobileNumber,
    getWalletTransactions,
    getreferralUsers,
    getreferralCommissions,
    getPayoutstatementByuserId,
    getTransactionByID,
    paymentLink,
    kycSendOTP,
    kycverifyOTP,
    RazorPayOrder,
    signup,
    signupVerifyOtp,
    signupDetails,
    createRazorPayment,
    fetchBillerByCategoryName,
    fetchBillerinfoPost,
    RaiseComplaint,
    fetchBill,
    walletTransactionsDownload,
    getUserDetails,
    quickPayBills,
    walletcommissionPercentage,
    TrackComplaint,
    searchTransaction,
    contactus,
    getBPBSTransactions,
};
