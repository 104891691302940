import React, { useState } from "react";
import { useForm } from "react-hook-form";
import menuClasses from "./links.module.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { RiErrorWarningLine } from "react-icons/ri";

function Pay(props) {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        props.setmodal(false);
    };
    return (
        <Modal
            open={props.modal}
            center
            onClose={() => {
                props.setmodal(false);
            }}>
            <div className={menuClasses["form"]}>
                <div className={menuClasses["paymentlinks-header"]}>
                    <RiErrorWarningLine size={22} />
                    <h3>Business Info</h3>
                </div>
                <div>
                    <button className={menuClasses["business-aprroved"]}>
                        Approved
                    </button>
                </div>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    autoComplete={false}
                    autoCorrect={false}>
                    <div className={menuClasses["form-control"]}>
                        <input
                            placeholder="Enter Address"
                            type={"text"}
                            {...register("Address,", { required: "true" })}
                        />

                        {errors.Address && <p>This is required!</p>}
                    </div>
                    <div className={menuClasses["form-control"]}>
                        <input
                            placeholder="Enter company name"
                            type={"text"}
                            {...register("Company,", { required: "true" })}
                        />

                        {errors.Company && <p>This is required!</p>}
                    </div>
                </form>
            </div>
        </Modal>
    );
}

export default Pay;
