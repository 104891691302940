import React, { useState } from "react";
import { useForm } from "react-hook-form";
import menuClasses from "./links.module.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { IoLinkSharp } from "react-icons/io5";
import { RxCopy } from "react-icons/rx";
function Pay(props) {
    return (
        <Modal
            open={props.modal}
            center
            onClose={() => {
                props.setmodal(false);
            }}>
            <div className={menuClasses["form"]}>
                <h3 className={menuClasses["paymentlinks-header"]}>
                    <IoLinkSharp /> Payment Links
                </h3>

                <div className={menuClasses["paymentLinks"]}>
                    <div className={menuClasses["payment-link"]}>
                        <div>
                            <IoLinkSharp color="grey" />
                            <p>https://www.payville.samplelink/</p>
                        </div>
                        <button>
                            <RxCopy />
                        </button>
                    </div>
                    <div className={menuClasses["payment-link"]}>
                        <div>
                            <IoLinkSharp color="grey" />
                            <p>https://www.payville.samplelink/</p>
                        </div>
                        <button>
                            <RxCopy />
                        </button>
                    </div>
                    <div className={menuClasses["payment-link"]}>
                        <div>
                            <IoLinkSharp color="grey" />
                            <p>https://www.payville.samplelink/</p>
                        </div>
                        <button>
                            <RxCopy />
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default Pay;
