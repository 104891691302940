import React from "react";
import TermsClasses from "./privacy.module.css";

export default function TermsPage() {
    return (
        <div className={TermsClasses["Container"]}>
            <h1>Terms and Conditions</h1>
            <p>
                All the services are provided by Ezycredits Technologies Private
                Limited under its brand name “EaseMyBill”. Hence all the rights,
                benefits, liabilities & obligations under the following terms &
                conditions shall accrue to the benefit of Ezycredits
                Technologies Private Limited regarding your use of online rent
                payment, Utility Payments, GST Payment, Vendor Payment, Payroll
                Payments or such other services which may be added from time to
                time (all such services are individually or collectively are
                referred as Service or Services as they case may be). The below
                mentioned terms and conditions read along with Privacy Policy,
                Cancellation and Refund policy (as mentioned below) or another
                policy that comes in force on the website governs the
                relationship between you and Ezycredits Technologies Private
                Limited (“EaseMyBill”). You are hence requested to read and
                understand the following terms and conditions, carefully, before
                registering on, accessing, browsing, downloading or using our
                website guidelines before you contribute in any way to the site.
                All users of the site must accept and agree to abide by them.
                EaseMyBill reserves all the rights to amend these terms and
                conditions at any time without giving prior notice. It is the
                responsibility of the user to have read the terms and conditions
                before using the service. The user is solely and exclusively
                responsible for understanding and complying with all applicable
                laws of your specific jurisdiction, including but not limited to
                the provisions of the RBI Guidelines on Regulation of Payment
                Aggregators and Payment Gateways, Payment and Settlement Systems
                Act, 2007, Prevention of Money Laundering Act, 2002, Know Your
                Customer (KYC) / Anti-Money Laundering (AML) / Combating
                Financing of Terrorism (CFT) guidelines issued by the Department
                of Regulation, RBI (the "KYC GUIDELINES") etc., that may be
                applicable to the user in connection with the business and use
                of EaseMyBill
            </p>

            <h4>General</h4>
            <p>
                This website www.easemybill.com (hereinafter referred to as
                “Website”) is owned and operated by Ezycredits Technologies
                Private Limited, having its office at Bellandur, Bangalore,
                Karnataka. Towards the provision of the Services, EaseMyBill
                also operates a mobile application titled “EaseMyBill”
                (“Application”). For the purpose of these terms and conditions
                (mentioned below), wherever the context so requires "You" or
                "User" shall mean any natural or legal person who has agreed to
                become a user on the Website by providing Registration Data
                while registering on the Website or the Application. Please go
                through the following terms and conditions (“Terms of Service”,
                “Terms of Use” or “Agreement”) carefully before registering,
                accessing, browsing or using the website as they contain legal
                terms and conditions that you agree to when using the services
                by accessing the Website or the Application, including without
                limitation the Product (“Services”). Please note that
                additionally, usage of the Application will be subject to the
                terms of the Play Store / App Store, as specified by the
                specific provider. By using the Website, Application and/or the
                Services, you agree to be bound by these terms and conditions
                set forth including any additional guidelines and future
                modifications. If at any point of time, you do not wish to be
                bound by these terms and conditions or do not agree to these
                terms and conditions, you may not use the website and terminate
                your use of the Services or Application immediately. The
                headings used in this agreement are included for convenience
                only and will not limit or otherwise affect these Terms. You
                shall re-visit the "Terms & Conditions" link from time to time
                to stay informed of any changes that the Website or Application
                may introduce
            </p>

            <h4>Changes to the terms and conditions:</h4>
            <p>
                EaseMyBill reserves the right to change or modify the content,
                or information appearing on or in connection with the Services,
                including these Terms at any time without notice to the User.
                EaseMyBill may at any time revise these Terms of Use by updating
                this posting on the Website or the Application. The User is
                bound by such revisions and should therefore do visit these
                pages to review the current Terms of Use from time to time. All
                future Changes shall be notified once you log in into your
                account and please ensure to read and keep updated of the terms
                and conditions from time to time. By continuing to access the
                EaseMyBill Platform or use the Services on or after the
                effective date of the revised Terms of Use, the User agrees to
                have read, understood and also be bound by the revised Terms of
                Use.
            </p>

            <h4>Definitions</h4>
            <p>
                For the purpose of these Terms of Use, the following words and
                phrases shall have the meaning assigned to them under this
                Article.
            </p>
            <ol type="1">
                <li>
                    "Acquiring bank" :-
                    <br />
                    means any bank or financial institution that processes debit
                    card or credit card payments of the userof the user
                </li>
                <li>
                    "Services" :- <br />
                    means the service provided by EaseMyBill and Service
                    Providers, if any, to Users, whereby a User can (make credit
                    card payment to suppliers, beneficiaries or other payees, or
                    any other mode of payment or settlement through banks, that
                    may be accepted by EaseMyBill from time to time; or (ii)
                    make tax payments including but not limited to goods and
                    service tax payments, payment against challan no. 280, 281,
                    282, and 283 towards direct taxes as per the Income Tax Act,
                    1961 or any other taxes that may be offered for payment by
                    EaseMyBill on the EaseMyBill Platform; or (iii) make Rental
                    payments or (iv) make utility bill payments or such other
                    services as may be offered by EaseMyBill from time to time
                    and availed and used by the User on the EaseMyBill platform,
                    (v) other services as specified by EaseMyBill, as made
                    available by EaseMyBill from time to time.
                </li>
                <li>
                    "User" :- <br />
                    shall mean any person [ individual resident in India and/or
                    a company/partnership firm/sole proprietor/ or entity
                    registered in India] who/which is registered on the
                    EaseMyBill platform for using this EaseMyBill platform
                    and/or who/ which subscribes for the Services through this
                    EaseMyBill platform, to make or to receive payments to or
                    from third parties or to make any settlement, as permitted
                    under applicable laws. The term "User" shall also cover such
                    personnel of any User who are duly authorized to avail of
                    the Services on behalf of the User.
                </li>
                <li>
                    "Settlement Account" :-,
                    <br />
                    means such a bank account maintained by EaseMyBill or the
                    account of any payment services provider on-boarded by
                    EaseMyBill for the provision of the Services
                </li>
                <li>
                    "Payee":-
                    <br />
                    means any person to whom a payment is made, using the
                    Services, (and the term includes a User who uses the
                    Services to receive payment).
                </li>
                <li>
                    "Payer":- <br />
                    means any person who makes a payment, using the Services
                    (and the term includes a User who uses the Services to make
                    payment) and for tax payments or utility bill payments it
                    means a person on whose behalf the User has paid taxes or
                    utility bills using EaseMyBill Services.
                </li>
                <li>
                    "Products" :-
                    <br />
                    mean any services or products which are purchased/ offered
                    for sale by a User to third parties from time to time for
                    which the User is using the Services to make any payments
                    pursuant to a Transaction, but excluding the banned products
                    and services that are listed in Annexure A.
                </li>
                <li>
                    "Service Provider" :- <br />
                    means a bank, association, facility provider, card issuing
                    institution, acquiring bank, payments gateway, other
                    financial institution, card processor, clearing house
                    networks or any such vendors or service providers, that
                    EaseMyBill may engage with from time to time to provide the
                    Services.
                </li>
                <li>
                    "Transaction" :-
                    <br />
                    means a payment instruction that results in the successful
                    transfer of monies or settlement (a) from a User to a Payee;
                    or (b) from a Payer to a User.
                </li>
                <li>
                    "Transaction Amount" :- <br />
                    means the total amount payable/ receivable by a User/Payee.
                    This amount shall include all applicable taxes, charges,
                    interest, delivery costs, commissions and other such
                    incidental charges etc.
                </li>
                <li>
                    "API" :-
                    <br />
                    Application programming interface (API) is a set of
                    subroutine definitions, communication protocols and tools
                    for building software and implies a software code that
                    allows two software programs to communicate with each other.
                </li>
                <li>
                    "Affiliate" :-
                    <br />
                    i) any natural person, partnership, corporation,
                    association, or other legal entity directly or indirectly
                    owning, controlling, or holding with power to vote 10% or
                    more of the outstanding voting securities of the User; (ii)
                    any partnership, corporation, association, or other legal
                    entity 10% or more of whose outstanding voting securities
                    are directly or indirectly owned, controlled, or held with
                    power to vote by the User; (iii) any natural person,
                    partnership, corporation, association, or other legal entity
                    directly or indirectly controlling, controlled by, or under
                    common control with, User; or (iv) any officer or director
                    of the User. Notwithstanding the foregoing, “affiliates"
                    shall include only those persons acting on behalf of the
                    User and performing services for the User within the scope
                    of the authority of the User, as set forth in this
                    Agreement.
                </li>
            </ol>
            <h4>Payment Types</h4>
            <p>
                EaseMyBill can be used for payment of Rent, Utility, Maintenance
                or Bill, Vendor, GST or other Products, as specified explicitly
                from time to time or notified on website. Users agree to take
                full responsibility for the transactions made on the platform
                with respect to nature of transaction, beneficiary and the tax
                implications pertaining to the same. EaseMyBill and its payment
                partners act as mere facilitators of authorized payments and
                shall not assume responsibility nor liability for any
                unauthorized transactions. EaseMyBill further reserves the right
                to hold/suspend/refund and report those bill payments or
                transactions that are suspicious in nature as determined by
                EaseMyBill in its sole discretion.
            </p>

            <h4>Eligibility</h4>
            <p>
                To be eligible to use the Services, you must be (a) an entity
                eligible and registered to carry out business or operate in
                India; or (b) an individual who is at least 18 years old and a
                resident of India. The Services are intended solely for persons
                who can form legally binding contracts under Indian Contract
                Act, 1872 and who are 18 years of age or older and anyone who is
                not previously suspended or removed by EaseMyBill from availing
                the Services or accessing the Application or Website. Any access
                to or use of the Services by anyone under 18 is expressly
                prohibited. User also confirms and declares that his Payer/
                Payee is similarly competent to contract. A User represents and
                warrants that he has full power and authority for using the
                Services and is in strict compliance of all laws as applicable
                from time to time. Finally, the User also represents not to
                impersonate any person or entity, or falsely state or otherwise
                misrepresent his identity, age or affiliation with any person or
                entity.
            </p>

            <h4>Registration</h4>
            <p>
                In order to avail the Services, User must be registered with
                EaseMyBill and the Service Providers, if required. The User
                shall provide EaseMyBill with all such identification and proof
                documents as may be required by EaseMyBill. By registering with
                EaseMyBill, User agrees to provide true, accurate, current and
                complete information, failing which EaseMyBill may at any time
                reject User's registration and terminate his right to use or
                access the Application, Website or the Services. When User
                registers on the EaseMyBill, a User Account will be created,
                that is associated with User's user name, password and other
                identifying information. User shall be responsible for anything
                that happens through his User Account. User also expressly
                covenants that the User Account shall be used by him only for
                his valid and genuine transactions and not otherwise or for any
                activity prohibited in Annexure A or for scrupulous/fake
                transactions. User/Payer/Payee hereby expressly acknowledges,
                agrees and undertakes not to enter into/ facilitate /carry on or
                attempt to enter into/ facilitate/ carry on any self-financing
                transaction [“self – financing” means where the registered User
                avails or uses the Platform to fund his own business account or
                that of his spouse or relatives or affiliates] on or through
                EaseMyBill platform. In the event, the User/Payer or Payee is
                found to be doing so or attempting to do such self-financing
                transaction, EaseMyBill shall at its discretion have recourse to
                take such actions as it deems fit (including suspension of
                account, investigation etc, levy of fines or penalty,
                appropriation of funds lying in the user account) as the case
                may be, including initiating appropriate legal action as may be
                By registering with EaseMyBill, If the User do not feel
                comfortable with any part of our Terms of Service, he must not
                use or access the Service. The User expressly undertakes to keep
                his account information, which includes but not limited to his
                primary email address, contact number, credit card number, bank
                account number, communication address, ownership or legal status
                current and valid and in case of any change in information
                provided, update his account immediately. In the event of any
                action/notice by any law enforcement agency or conviction by a
                court of law, the User shall undertake to forthwith without any
                delay inform EaseMyBill about the same and keep EaseMyBill
                updated. User shall not impersonate any person or entity, or
                falsely state or otherwise misrepresent identity, age or
                affiliation with any person or entity. The User is solely
                responsible for protecting the confidentiality of its own
                username and password and any activity under the account will be
                deemed to have been done by you.
            </p>

            <h4>Information</h4>
            <p>
                The user will be required to submit the following information
                while registering:
            </p>
            <ul>
                <li>Valid Identification and address proof [AADHAR NUMBER]</li>
                <li>
                    Permanent Account Number, Goods And Services Tax Number [PAN
                    NUMBER/GST NUMBER]
                </li>
                <li>Other information as specified by EaseMyBill.</li>
            </ul>
            <p>
                The User authorises EaseMyBill directly or through third
                parties, to make any inquiries as EaseMyBill consider necessary
                to validate the identity, including as necessary to comply with
                the obligations under applicable laws and regulations. This may
                include asking the user for further information or
                documentation, requiring him to provide personal identification
                documents, valid identity proof and address proof.
            </p>
            <h3>Communications</h3>
            <p>
                By using the Website and the Application, it is agreed that you
                have given your consent to receive calls, auto-dialed or
                pre-recorded messages/calls from EaseMyBill and the Service
                Provider at any time with the use of the telephone number
                provided by the User, for the use of the Website and the
                Application, subject to the privacy policy. This also includes
                your consent to receive SMSs from EaseMyBill and the Service
                Providers at any time and contacting you through the information
                received through third parties as well as contacting other
                parties through the information provided by you. This consent to
                be contacted is for purposes that include and are not limited to
                clarification calls and marketing and promotional calls. You may
                send EaseMyBill an email at admin@ezycredits.com in case you
                wish to stop contacting you for the same.
            </p>
            <h3>Use of Services</h3>
            <p>
                The Services may be used exclusively by the User for making and
                receiving payments from and/or to Payer/Payee or making
                settlement of a valid and legal transaction and/or to make
                payments by payment the Transaction Amount to effect a
                Transaction. The User hereby understands, accepts and
                acknowledges that he is solely responsible for any loss or
                damage caused to the User or User's vendors/customers arising as
                result of this configuration whether directly or indirectly and
                shall release EaseMyBill and the Service Providers from any such
                liability arising therefrom. EaseMyBill has no obligation to
                verify the authenticity of the Transaction once the transaction
                is generated through a valid login and authenticated through a
                password. The User shall at all times take all appropriate
                steps, including those as mentioned herein, to maintain the
                security and confidentiality of the information. EaseMyBill
                shall not be liable for any mistake or misuse of the Services by
                either the User or by any person authorized by the User, or by
                any person gaining access to the Services through the User. The
                User agrees that EaseMyBill accepts all instructions originating
                from his Account in good faith and that they are genuine and are
                normal business transactions and, in a manner, consistent with
                commercially reasonable security standards. The User shall
                indemnify and hold EaseMyBill, the Acquiring Banks and the
                Service Provider safe and harmless for direct or indirect losses
                resulted as a result of the disclosure of sensitive information
                by or through User or for any loss or damage caused to
                EaseMyBill, its officers and employees or the Acquiring Bank or
                any Service Provider for any breach of the terms of use/privacy
                policy/payment terms by the user or his customer or vendor, or
                for any Intellectual Property infringement claim brought by
                third party or for any other loss or damage due to security
                breach
            </p>
            <ol type="1">
                <li>
                    EaseMyBill shall initiate the required payment to
                    Payee/User/ as instructed by User/ Payer. On receiving the
                    necessary details from the Service Provider,
                </li>
                <li>
                    {" "}
                    Confirmation of the Transaction performed using valid login
                    credentials shall be conclusive evidence of a Transaction
                    being effected.
                </li>
                <li>
                    User is responsible to furnish EaseMyBill with correct and
                    current Payee or Payer information. In the event that the
                    payment is in respect of a purchase or sale of product or
                    services by the User/Payee, EaseMyBill shall not be required
                    to ensure that the purchased Products and/or services have
                    been duly delivered. In the event a User chooses to complain
                    about a Transaction, the same should be communicated to
                    EaseMyBill within 3 (three) days of the Transaction. The
                    User expressly agrees and warrants that EaseMyBill is only
                    an online intermediary / platform for facilitating the
                    payment or settlement of transaction and hence is not
                    responsible to the User or to any other third party for any
                    wrong payment or defect in products delivered or any other
                    dispute between the user and the payee or with banks.
                </li>
                <li>All transactions shall be done in Indian Rupees.</li>
                <li>
                    {" "}
                    In case of tax payment, once the GST and/or direct tax
                    payment is processed successfully, the status of the payment
                    will be updated on the GST and/or Direct Tax Portal,
                    however, EaseMyBill or the Service Providers are not liable
                    or responsible which prevents the processing of any payments
                </li>
                <li>
                    The User shall get a challan confirmation on receipt of the
                    same from the respective tax authorities. However,
                    EaseMyBill or the Service Providers are not responsible for
                    ensuring the generation of the challan by the tax
                    authorities.
                </li>
            </ol>
            <h3>User Obligations</h3>
            <ol type="a">
                <li>
                    Every User is responsible to (i) furnish correct and
                    accurate information of the Payer/ Payee as may be required,
                    on an independent basis; (ii) furnish to EaseMyBill
                    forthwith on demand, the original copy/copies of proof of
                    delivery of Products, invoices or other records pertaining
                    to any Transaction; (iii) ensure that all licenses and
                    registrations required by him are in full force and effect
                    to enable them to carry on the business of sale/ purchase of
                    Products and rendering of Services and agrees to furnish
                    immediately copies of such licenses on request by EaseMyBill
                </li>
                <li>
                    User shall not (i) carry out any activity, which is banned,
                    illegal or immoral, (ii) use the Services in any manner or
                    in furtherance of any activity, which constitutes a
                    violation of any law or regulation or which may cause
                    EaseMyBill to be subject to investigation, prosecution or
                    legal action.
                </li>
                <li>
                    User undertakes and assures to EaseMyBill that payments
                    shall not be made/ received in respect of any Products
                    mentioned in the banned items list set out in Annexure A
                    hereto and that payments are been made in under valid
                    business contract with the customer or vendor and the
                    payments are in respect of genuine business transaction.
                </li>
                <li>
                    User undertakes to inform EaseMyBill if he has any dealing
                    or engages as a vendor/supplier/customer/dealer in any of
                    such prohibited goods at any point of time.
                </li>
                <li>
                    User shall act in compliance with all laws, rules and
                    regulations and shall at all times comply with the
                    guidelines set by Visa/MasterCard/ Acquiring Banks/ Service
                    Providers, as may be applicable from time to time
                </li>
                <li>
                    User shall not sell, provide, exchange, or otherwise
                    disclose to third parties or use themselves (other than for
                    the purpose of completing a Transaction, or as specifically
                    required by law) any personal information about any third
                    party, including the account details and mobile number,
                    without obtaining the prior written consent of such third
                    party.
                </li>
                <li>
                    User shall take all precautions as may be feasible or as may
                    be directed by EaseMyBill to ensure that there is no breach
                    of security and that the integrity of the link between their
                    systems/ site, the EaseMyBill platform and the payment
                    mechanism is maintained at all times. In the event of any
                    loss being caused as a result of the link being breached or
                    as a consequence of the link being improper or being in
                    violation of the provisions of this clause, the loss shall
                    be to the account of the User and the User shall indemnify
                    and keep indemnified EaseMyBill and the Service Providers
                    from any loss as may be caused in this regard.
                </li>
                <li>
                    User shall bear and be solely and exclusively responsible
                    for the payment of all relevant & applicable taxes
                    (including any applicable withholding taxes) as may be due
                    from time to time
                </li>
                <li>
                    A User shall not at any time require any other user or any
                    other person to provide him with any details of the accounts
                    held by the other users with any Banks including, the
                    passwords, account number, card numbers, mobile phone
                    numbers and PIN which may be assigned to them by the Banks
                    from time to time.
                </li>
                <li>
                    A User shall use the information regarding a Payee/ Payer
                    (including name, address, e-mail address, telephone numbers
                    and other data) conveyed to him whilst using the Services,
                    only for the purpose of completing the Transaction for which
                    it was furnished, and not to sell or otherwise furnish such
                    information to others unless he has an independent source of
                    such information or obtains the express written consent of
                    such Payee/ Payer.
                </li>
                <li>
                    A User shall inform EaseMyBill of any change in his email
                    address, mobile number, address, ownership or legal status
                    or his cessation of business in writing forthwith on such
                    change.
                </li>
                <li>
                    User shall not interfere with or damage the EaseMyBill
                    platform, including, without limitation, through the use of
                    viruses, cancel bots, Trojan horses, harmful code, flood
                    pings, denial-of-service attacks, backdoors, packet or IP
                    spoofing, forged routing or electronic mail address
                    information or similar methods or technology.
                </li>
                <li>
                    {" "}
                    As a condition of use of the Services, the User will not use
                    the Services for any purpose that is unlawful or prohibited
                    by these terms, conditions, or violate the T&Cs. EaseMyBill
                    reserves the right to suspend the user's EaseMyBill account
                    or restrict use of services or any such other action as
                    EaseMyBill may deem fit [ including but not restricted to
                    such legal resources as may be available, without any prior
                    notice to the User.
                </li>
                <li>
                    User shall not impersonate any person or entity, falsely
                    claim or otherwise misrepresent his affiliation with any
                    person or entity, or access the accounts of others without
                    permission, forge another person's digital signature,
                    misrepresent the source, identity, or content of information
                    transmitted, perform any other similar fraudulent;
                </li>
                <li>
                    {" "}
                    User shall not infringe EaseMyBill's or any third party's
                    intellectual property rights, rights of publicity or
                    privacy;
                </li>
                <li>
                    {" "}
                    User shall not use the EaseMyBill Services or the EaseMyBill
                    Platform in any manner that could damage, disable,
                    overburden, or impair it, including, without limitation,
                    using the EaseMyBill Services or the EaseMyBill Platform in
                    an automated manner or otherwise;
                </li>
                <li>
                    {" "}
                    EaseMyBill has the right to investigate and prosecute
                    violation of any of the above to the fullest extent of the
                    law or seek such legal recourse as available.[including but
                    not restricted to indemnify and keep EaseMyBill and its
                    officers, affiliates from any such losses or damages as
                    EaseMyBill may incur on account of such violations and also
                    right to adjust any money lying in the settlement account
                    towards losses or damages that EaseMyBill may suffer on
                    account of such violation, without prior notice to the User.
                </li>
                <li>
                    User shall not use EaseMyBill Services or EaseMyBill
                    Platform for any self-financing activities which includes
                    but not limited to transfer of funds to the Payee against
                    any fake or fraudulent transactions, transfer of funds to
                    self-owned account, use of credit card registered in User's
                    name by any other User/Payer on EaseMyBill Platform,
                    transfer of funds to any subsidiary, holding, associate
                    company of the User or any related party, unless such
                    transfer of funds is towards a genuine transaction between
                    the User and the other Party.
                </li>
                <li>
                    EaseMyBill has the right to temporarily terminate such
                    User's access to the EaseMyBill platform. User shall be
                    given a reasonable opportunity of being heard and provide
                    evidence/proof ensuring the validity of the transaction, any
                    failure to prove the validity of the transaction will lead
                    to permanent embargo on the User to access the EaseMyBill
                    Platform. In addition to this, EaseMyBill also has the right
                    to impose penalty or appropriate funds in the user account
                    as it deems fit
                </li>
                <li>
                    The User hereby expressly agrees and undertakes not to use
                    this EaseMyBill platform to facilitate any payment or
                    settlement or collect monies from Vendor/Customer who /which
                    is an affiliate.
                </li>
                <li>
                    Every User availing GST payment service on EaseMyBill
                    Platform shall ensure that: (i) valid login credentials are
                    entered by the User while accessing the GST portal; (ii)
                    correct and accurate amounts are mentioned against
                    respective type of tax heads; (iii) CPIN amount mentioned on
                    the EaseMyBill Platform should be verified; (iv) any losses
                    that may be incurred due to providing of incorrect PIN or
                    incorrect amounts in the tax heads while making GST payments
                    shall be at the cost and expense of the User.
                </li>
                <li>
                    {" "}
                    Every User availing utility bill payment service on
                    EaseMyBill Platform is responsible to (i) furnish correct
                    and accurate information of himself or of the Payer if the
                    User is making payment of utility bill on behalf of the
                    Payer (ii) verify name, address, e-mail address, telephone
                    numbers, utility/consumer account number and other data of
                    the Payer if the User is making utility payment on behalf of
                    the Payer.
                </li>
                <li>
                    Every User availing direct tax payment service on the
                    EaseMyBill Platform is responsible to (i) furnish correct
                    and accurate information of the Payee and/or of the Payer if
                    the User is making payment of tax of deducted at source on
                    behalf of the Payer (ii) verify name, address, e-mail
                    address, telephone numbers, PAN/TAN and other data of the
                    Payer if the User is making tax payment on behalf of the
                    Payer; (ii) verify the challan type selected before
                    initiating payment; (iii) any losses that may incurred due
                    to providing of incorrect PAN/TAN or other information of
                    the Payer on EaseMyBill Platform while making tax payments
                    shall be at the cost and expense of the User;
                </li>
                <li>
                    In the event of any dispute between the User and the Payer
                    with respect to the tax payments or any other payments in
                    connection with the Services, EaseMyBill shall not be made a
                    party to any litigation, arbitration or other proceeding
                    instituted in respect of such disputes.
                </li>
                <li>
                    User shall ensure that he is selecting the appropriate
                    service on the app and is utilizing the app only for those
                    purposes as selected by the User on the app.
                </li>
            </ol>
            <h3>User Account History</h3>
            <p>
                When a payment is made to a Payee's designated bank account,
                EaseMyBill will update User on the EaseMyBill platform and
                provide User with a transaction confirmation. The confirmation
                will serve as the User's receipt. Upon request of User,
                EaseMyBill may provide a summary of his User Account activity,
                for up to one year of account activity. Except as required by
                law, User is solely responsible for (a) compiling and retaining
                permanent records of all Transactions and other data associated
                with his User Account and his use of the Services, and (b)
                reconciling all transactional information that is associated
                with User's Account. If User believes that there is an error or
                unauthorized transaction activity associated with his User
                Account, he must contact EaseMyBill immediately.
            </p>
            <h3>Specific Terms</h3>
            <ol type="a">
                <li>
                    EaseMyBill and or the Acquiring Bank will not be responsible
                    for any disputes between the Users and the Payers/ Payees,
                    including issues regarding prices, delivery, non-delivery,
                    deficiency of services or delay in delivery of the Products,
                    quality of Products or services or otherwise. All such
                    disputes will be dealt with by and between the User and the
                    Payer/ Payee directly, and EaseMyBill shall not be a party
                    to such disputes.
                </li>
                <li>
                    {" "}
                    EaseMyBill cannot and does not guarantee the timings of any
                    payments made or received as such timing is dependent upon
                    User's or the Payer's bank, card issuing bank/ card
                    processor (where a credit/ debit card is used), acquiring
                    financial institution and other Service Providers.
                    EaseMyBill assumes no liability and shall not be held
                    responsible for any delays in delivery of payment or
                    availability of funds when using the Services.
                </li>
                <li>
                    {" "}
                    EaseMyBill and the Acquiring Bank shall not be a party to
                    the contract between the User and any Payer/ Payee in any
                    manner whatsoever. All contracts are directly between Users
                    and their Payers/ Payees.
                </li>
                <li>
                    {" "}
                    User shall be responsible for any miscommunication or
                    incorrect User/ third party/ other information that may be
                    provided to EaseMyBill at the time of enabling the Services
                    for the User.
                </li>
                <li>
                    EaseMyBill/ Service Provider reserves the right to impose
                    limits on the number of Transactions which may be charged on
                    an individual credit card or other account during any time
                    period and reserve the right to refuse to make payments in
                    respect of Transactions exceeding such limit. EaseMyBill
                    also reserves the right to impose limits on each and every
                    amount which may be charged on an individual credit card or
                    other account at any point of time. EaseMyBill/ Service
                    Provider also reserve the right to refuse to make payments
                    in respect of Transactions by Users with a prior history of
                    questionable charges or for any other reason as the case may
                    be
                </li>
            </ol>
            <h3>Security</h3>
            <ol type="a">
                <li>
                    The User will not hold EaseMyBill liable in case of any
                    improper/ fraudulent/unauthorized/ duplicate/erroneous use
                    of his mobile and/or the web-based access or through any
                    other platform. EaseMyBill will also not be liable for any
                    direct or indirect consequences connected with the use/
                    misuse of User's web/ mobile/ e-mail account by any third
                    party. If any third parties gain access to the Services, the
                    User will be responsible and shall indemnify EaseMyBill
                    against any liability, costs or damages arising out of such
                    misuse / use by third parties based upon or relating to such
                    access and use, or otherwise.
                </li>
                <li>
                    The User shall be fully liable for: (a) any unauthorized use
                    of his mobile/ email account; and/or (b) all authorized
                    Transactions on his web/mobile/email account.
                </li>
                <li>
                    Without prejudice to the remedies available to EaseMyBill
                    and these Terms of Use, EaseMyBill shall be under no
                    liability whatsoever to the User in respect of any loss or
                    damage arising directly or indirectly out of:
                </li>
            </ol>
            <ul style={{ marginLeft: "4rem" }}>
                <li>
                    any defect in any Products supplied to him or services
                    rendered to him;
                </li>
                <li>
                    any inability of a third party to supply or deliver the
                    required Products or services in the necessary numbers or
                    types or manner;
                </li>
                <li>
                    the refusal of any person (including a Service Provider) to
                    honor or accept a payment;
                </li>
                <li>the malfunction of any computer terminal or equipment;</li>
                <li>
                    the utilization of the Services by any person other than by
                    the User;
                </li>
                <li>
                    any transaction which is unsuccessful or fails for any
                    reason whatsoever, whether EaseMyBill has a prior notice of
                    it or not;
                </li>
                <li>
                    any mis-statement, error or omission in any details
                    disclosed to EaseMyBill.
                </li>
            </ul>
            <h3>Intellectual Property Rights</h3>
            <p>
                The EaseMyBill platform content and the trademarks, service
                marks and logos contained therein ("Marks") are owned by or
                licensed to EaseMyBill, subject to copyright and other
                intellectual property rights under the law and international
                conventions. Such EaseMyBill platform content may not be copied,
                reproduced, distributed, transmitted, broadcast, displayed,
                sold, licensed, uploaded, or otherwise exploited without the
                prior written consent of EaseMyBill.
            </p>
            <p>
                The User also expressly agrees and undertakes and consents to
                use of its trade mark, service mark and logo on EaseMyBill
                website/platform/app
            </p>
            <h3>Disclaimer of Warranties</h3>
            <p>
                THE CONTENT AND FUNCTIONALITY ON THE EASEMYBILL PLATFORM IS
                PROVIDED WITH THE UNDERSTANDING THAT EASEMYBILL IS NOT HEREIN
                ENGAGED IN ITSELF FOR MAKING PAYMENTS. EASEMYBILL IS AN
                INTERMEDIARY BETWEEN BANKS, SERVICE PROVIDERS AND USERS.
            </p>
            <p>
                ALL CONTENT AND FUNCTIONALITY ON THE EASEMYBILL PLATFORM IS
                PROVIDED "AS IS," WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS
                OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
                NON-INFRINGEMENT. EASEMYBILL MAKES NO WARRANTIES, EXPRESS OR
                IMPLIED, AS TO THE ACCURACY, OR ADEQUACY OF THE EASEMYBILL
                PLATFORM OR THAT THE SERVICES PROVIDED OR THE FUNCTIONALITY ON
                THIS EASEMYBILL PLATFORM WILL BE UNINTERRUPTED OR ERROR-FREE.
            </p>
            <p>EASEMYBILL DONOT WARRANT TO THE USER THAT: -</p>
            <ul type="A">
                <li>
                    THE INFORMATION OR CONTENTS PROVIDED ON THE EASEMYBILL
                    PLATFORM WILL BE ACCURATE, COMPLETE AND UPDATED;
                </li>
                <li>
                    THE USE OF THE SERVICES WILL MEET THE USER'S REQUIREMENTS;
                </li>
                <li>
                    THE USE OF THE SERVICES WILL BE UNINTERRUPTED, TIMELY,
                    SECURE OR FREE FROM ERROR;
                </li>
                <li>
                    ANY INFORMATION OBTAINED BY THE USER AS A RESULT OF USE OF
                    THE SERVICES WILL BE ACCURATE OR RELIABLE, AND
                </li>
                <li>
                    ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE
                    USE OF THE SERVICES IS DONE AT THE USER'S OWN DISCRETION AND
                    RISK AND THAT THE USER WILL BE SOLELY RESPONSIBLE FOR ANY
                    DAMAGE TO HIS COMPUTER SYSTEM OR OTHER DEVICE OR LOSS OF
                    DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.
                </li>
            </ul>
            <p>
                NO WARRANTY OF ANY KIND, IMPLIED, EXPRESS OR STATUTORY,
                INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF NON-INFRINGEMENT
                OF THIRD-PARTY RIGHTS, TITLE, MERCHANTABILITY, SATISFACTORY
                QUALITY OR FITNESS FOR A PARTICULAR PURPOSE, IS GIVEN IN
                CONJUNCTION WITH EASEMYBILL'S SERVICES OR ANY DATA/CONTENT.
            </p>
            <p>
                EASEMYBILL DOES NOT WARRANT THAT PRODUCTS/SERVICES OR ANY
                CONTENT/DATA WILL BE PROVIDED UNINTERRUPTED OR FREE FROM ERRORS
                OR THAT ANY IDENTIFIED DEFECT WILL BE IMMEDIATELY CORRECTED.
                FURTHER, NO WARRANTY IS GIVEN THAT PRODUCTS/SERVICES OR ANY
                DATA/CONTENT ARE FREE FROM ANY COMPUTER VIRUS OR OTHER
                MALICIOUS, DESTRUCTIVE OR CORRUPTING CODE, AGENT, PROGRAM OR
                MACROS. BANK PROVIDE THE PRODUCTS/SERVICES ON AN "AS IS", "WHERE
                IS", "WITH ALL FAULTS" BASIS.
            </p>
            <p>
                USER HEREBY ACKNOWLEDGES THAT HIS USE OF THIS EASEMYBILL
                PLATFORM AND THE SERVICES IS AT HIS SOLE RISK.
            </p>
            <p>
                UNDER NO CIRCUMSTANCES SHALL EASEMYBILL OR ANY OF ITS
                PREDECESSORS, SUCCESSORS, PARENTS, SUBSIDIARIES, AFFILIATES,
                OFFICERS, DIRECTORS, SHAREHOLDERS, INVESTORS, EMPLOYEES, AGENTS,
                REPRESENTATIVES, ATTORNEYS AND THEIR RESPECTIVE HEIRS,
                SUCCESSORS OR ASSIGNS BE LIABLE FOR ANY DAMAGES, INCLUDING
                DIRECT, INCIDENTAL, PUNITIVE, SPECIAL, CONSEQUENTIAL OR
                EXEMPLARY DAMAGES THAT DIRECTLY OR INDIRECTLY RESULT FROM THE
                USE OF, OR THE INABILITY TO USE, THIS EASEMYBILL PLATFORM OR
                THESERVICES, INCLUDING FOR VIRUSES ALLEGED TO HAVE BEEN OBTAINED
                FROM THE EASEMYBILL PLATFORM, EVEN IF EASEMYBILL HAS BEEN
                ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES AND
                REGARDLESS OF THE THEORY OF LIABILITY.
            </p>
            <h3>Limitation of Liability</h3>
            <p>
                Unless otherwise set out, all payments may only be made in
                Indian Rupees.
            </p>
            <p>
                EaseMyBill may change any fee or charge or institute new fees or
                charges. The User agrees to pay all fees and charges so levied.
            </p>
            <p>
                EaseMyBill shall not make any refunds of any amounts other than
                as agreed by these Terms.
            </p>
            <p>
                The User hereby accepts that he may, from time to time, receive
                from EaseMyBill or its associates, communications such as
                service announcements, advertisements or administrative
                communications
            </p>
            <p>
                EaseMyBill's sole obligation and User's sole and exclusive
                remedy in the event of interruption to the Payment Services or
                loss of use and/or access to the Payment Services, shall be to
                use all reasonable endeavors to restore the Payment Services as
                soon as reasonably possible.
            </p>
            <p>
                In no event shall EaseMyBill or a Service Provider be liable to
                a User or any other third party for any applicable taxes and
                government levies.
            </p>
            <p>
                Any link on the EaseMyBill platform to a third-party site is not
                an endorsement of that third-party site or platform. The use or
                browsing by a User of any other EaseMyBill platform shall be
                subject to the terms and conditions in each such EaseMyBill
                platform.
            </p>
            <p>
                EaseMyBill does not endorse any advertiser or merchant linked to
                its EaseMyBill platform. In case of any such endorsement, the
                User must verify all information provided by the merchants/
                advertisers before arriving at his decision to purchase any
                Product.
            </p>
            <p>
                The User's participation in any event/ contest/ promotion shall
                be governed by the terms and conditions mentioned in the
                respective event/ contest/ promotion along with these Terms
            </p>
            <p>
                EaseMyBill shall not be liable for any unauthorized access to
                the User's data or any unauthorized transmissions sent through
                the EaseMyBill Services.
            </p>
            <p>EaseMyBill shall have the right, at its sole discretion, to:</p>
            <p>restrict or terminate a User's access to its Services;</p>
            <p>modify or discontinue its Services or any part thereof;</p>
            <p>
                require a User to provide details in respect of any transactions
                and any other details as required by EaseMyBill from time to
                time; without incurring any liability therefor.
            </p>
            <p>
                EaseMyBill shall have the right, in its sole discretion, for any
                or no reason, and without penalty, to suspend or terminate a
                User's use of the EaseMyBill platform and its Services or any
                part thereof, with or without notice.
            </p>
            <p>
                The User shall not (whether on-line or otherwise) describe
                itself as agent or representative of EaseMyBill or make any
                representations to any customer or any third party or give any
                warranties which may require EaseMyBill or Service Provider to
                undertake to or be liable for, whether directly or indirectly,
                any obligation and/or responsibility to customer or any third
                party
            </p>
            <p>
                EaseMyBill shall not be liable for any breach of these Terms due
                to any force-majeure event such as act of god, fire, lightning,
                explosion, flood, inclement weather conditions, power failures,
                failure in any communication systems, equipment breakdown,
                strikes, lock-out or any other cause beyond the control of
                EaseMyBill. Additionally, EaseMyBill will not be responsible or
                liable for any disruption to the ability of the User to utilize
                the Services on account of payment failures of the failure of
                any third party.
            </p>
            <p>
                If any part of these Terms is determined to be invalid or
                unenforceable pursuant to applicable law including, but not
                limited to, the warranty disclaimers and liability limitations
                set forth herein, then the invalid or unenforceable provision
                will be deemed superseded by a valid, enforceable provision that
                most closely matches the intent of the original provision and
                the remainder of the Terms shall continue in effect.
            </p>
            <p>
                EaseMyBill may assign or transfer its rights and obligations to
                any other party, with or without a notice to the User.
            </p>
            <p>
                In case of any dispute between the parties on the interpretation
                or implementation of these Terms, the appropriate court in
                Mumbai shall have exclusive jurisdiction.
            </p>
            <p>
                No failure or delay by EaseMyBill in exercising any right, power
                or privilege shall operate as a waiver thereof.
            </p>
            <p>
                All claims, matters and disputes are subject to the sole and
                exclusive jurisdiction of the competent courts in Mumbai, State
                of Maharashtra
            </p>
            <h3>Payment Terms</h3>
            <p>
                By using these payment processing services of EaseMyBill (as
                more particularly described in the General Terms and
                Conditions), User agrees to be bound by the General Terms and
                Conditions (” Terms”), these additional terms and conditions
                (“Payment Terms”), the Privacy Policy and all other term
                policies and guidelines applicable to the Services (as defined
                in the Terms). The Services may be availed only in India.
                Defined terms will have the same meaning as those found in the
                Terms, unless otherwise re-defined herein.
            </p>
            <h3>
                Fees <br />
                Convenience Fees:
            </h3>
            <p>
                The Acquiring Bank will authenticate, authorize, and process the
                payment instructions given by the Payer in respect of a
                Transaction upon fulfillment of valid criteria as set forth by
                the Acquiring Bank from time to time and accordingly transfer
                such approved Transaction Amount from the Payer's Credit Card to
                the Settlement Account maintained by EaseMyBill. In
                consideration of the Services rendered by EaseMyBill to the
                User, the User shall pay to EaseMyBill a Convenience Fee. The
                User can view the Convenience Fee details on the EaseMyBill App.
                The User will be made aware of the Convenience Fee on the mobile
                Application prior to making the Transaction. The User can
                proceed to make the Transaction only upon his agreement to the
                Convenience Fee charged for the use of the particular service.
                All the Convenience Fees paid to EaseMyBill are non-refundable.
                Convenience Fee can be revised from time to time at the
                discretion of EaseMyBill and the User is required to read the
                FAQs to know about the updated Convenience Fee. EaseMyBill shall
                deduct its Convenience Fees plus service tax per successful
                Transaction and make payment of the balance of the Transaction
                Amount to User/ Payee's designated bank account. All other
                taxes, duties or charges shall be borne and paid by User, unless
                otherwise agreed between the Parties. EaseMyBill reserves the
                right to alter / modify / change the Convenience Fee payable at
                its discretion at any time without notice to the User. It is
                hereby agreed and acknowledged by the User that the Convenience
                Fees charged by EaseMyBill in respect of a Transaction that has
                been settled or completed shall not be returned or repaid by
                EaseMyBill to the User or any other person irrespective of the
                Transaction being rejected, charged back, refunded or disputed.
            </p>
            <h3>Taxes</h3>
            <p>
                EaseMyBill shall deduct its Fees as well as GST from the
                Transaction Amounts received by them. User is responsible for
                determining any other taxes assessed, incurred, or required to
                be collected, paid, or withheld for any reason for his use of
                the Services (“Taxes”). Users are also solely and exclusively
                responsible for collecting, withholding, reporting, and
                remitting correct Taxes to the appropriate tax authority at such
                amounts as may be levied by law from time being in force.
                EaseMyBill is not obligated to, nor will they determine whether
                Taxes apply, or calculate, collect, report, or remit any Taxes
                to any tax authority arising from any transaction. EaseMyBill
                specifically disclaims any liability for Taxes.
            </p>
            <h3>Payout Schedule</h3>
            <p>
                EaseMyBill will automatically initiate a pay out of the Payment
                Amount to a Payee's designated bank account as set out herein
                below: All Transactions through credit card shall be credited to
                Payee within 48 working hours of completion of successful
                Transaction (subject to normal banking hours and holidays)
            </p>
            <p>
                All payments shall be made by EaseMyBill subject to receipt of
                the same in the EaseMyBill Bank Accounts.
            </p>
            <p>
                All payments settled by EaseMyBill to any Payee shall be through
                EaseMyBill Bank Account or such other banking channels as may be
                available from time to time.
            </p>
            <p>
                EaseMyBill will initiate a payout of the Payment Amount received
                on non-business days, on the next business day.
            </p>
            <p>
                EaseMyBill may defer payout or restrict a Payee's access to the
                Payment Amount if EaseMyBill needs to investigate or resolve any
                pending dispute related to User's use of the Services.
                EaseMyBill may also defer payout or restrict access to the
                Payment Amount as necessary to comply with applicable law or
                court order, or if requested by any governmental entity or the
                Acquiring Bank, Service Providers or the payment system
                provider.
            </p>
            <h3>Processing Errors</h3>
            <p>
                EaseMyBill will attempt to fix processing errors it discovers.
                If the error resulted in Payee's receipt of fewer funds than he
                was entitled, EaseMyBill will credit his Account for the
                difference, subject to EaseMyBill receiving the necessary funds
                and verifying such shortfall. EaseMyBill will only be able to
                attempt to correct Transactions that User processes incorrectly
                if he notifies EaseMyBill of such an error before transaction
                getting processed from EaseMyBill's end. User's failure to
                notify EaseMyBill of a processing error forthwith will be deemed
                a waiver of any right to amounts owed to him.
            </p>
            <p>
                EaseMyBill will initiate a payout of the Payment Amount received
                on non-business days, on the next business day.
            </p>
            <h3>Chargeback Policy</h3>
            <p>
                A Chargeback shall mean a Transaction that is uncollectible and
                returned to Service Provider/ EaseMyBill by User/ Payee or
                acquiring bank for a refund to the concerned Payer / User due to
                any of the following reasons:
            </p>
            <ol type="a">
                <li>
                    Any payment which the User/ Payer refuses to honor or
                    demands a refund of because the Products or services,
                    purchased or sold or the delivery thereof, was not as
                    promised or was defective, deficiency of service, incomplete
                    and /or unsatisfactory for any reason whatsoever, or that
                    payment for the same has already been made
                </li>
                <li>
                    Any payments by a User/ Payer using the Services, which is
                    returned by the acquiring bank for any reason whatsoever.
                </li>
                <li>
                    Any charge/debit arising out of any alleged hacking breach
                    of security or encryption that may be utilized by Service
                    Provider/ EaseMyBill/ Payee from time to time or
                </li>
                <li>
                    Any Transaction that is unsuccessful.
                    <br />
                    As a result of the rapid nature of movements of funds, once
                    a payment has been initiated through the app, it may not be
                    possible to retract the same. Hence neither the Acquiring
                    Bank nor EaseMyBill shall be responsible to the User or
                    anyone else for chargeback or refund. The user hereby
                    expressly acknowledges and confirms that EaseMyBill shall
                    not be liable for the Chargeback Any such retraction of
                    payment shall be between User and Payee directly and a
                    chargeback can be considered only if the Payee refunds the
                    amount back. In the event of a Chargeback situation arising
                    in case of any Products or services purchased by a User or
                    Payer, for which payment has not yet been made to the Payee
                    or User, the User or Payer shall be advised by EaseMyBill to
                    resolve the issue with the Payee or User at the earliest and
                    EaseMyBill shall then settle the payment in accordance with
                    the solution agreed upon by the Parties once the same is
                    communicated to EaseMyBill. In any event, Chargeback
                    requests may be entertained only if the Payee refunds the
                    amount and hence EaseMyBill is under no obligation
                    otherwise. EaseMyBill shall not be responsible to make
                    payments in respect of any Chargeback unless it has received
                    the requisite amounts from the concerned acquiring bank/
                    Service Provider/ Payee/ User. In the event of the receipt
                    by EaseMyBill of an amount of a Chargeback from the
                    acquiring bank / Service Provider, EaseMyBill shall pass on
                    to User/ Payer such amount received by them within 7 (seven)
                    working days
                </li>
            </ol>
            <h3>Cancellation and Refund Policy</h3>
            <p>
                If a User has initiated a Transaction, the user cannot cancel
                the same once the transaction has been initiated/ processed
            </p>
            <p>
                User is solely responsible to add the payee bank account details
                on the EaseMyBill Application or Website and the User must
                ensure that the details entered in respect of the account name,
                account number, IFSC code, etc are correct.EaseMyBill shall not
                be liable to check or validate the account details on behalf of
                the User. The User acknowledges that in case the User enters
                incorrect bank details, EaseMyBill will not be
                responsible/liable to refund any amount to the User whatsoever.
                EaseMyBill, the Service Providers and the Acquiring Banks will
                not be responsible in case the User initiates a wrong/incorrect
                transaction. Users shall have very well understood and
                acknowledge that payments when made into a bank account of the
                Payee are irreversible as the same falls under the personal
                jurisdiction of the said person
            </p>
            <p>
                The user can raise a dispute/query in respect of a particular
                Transaction within a period of 3 (three) days after initiation
                of such Transaction. Any dispute/query raised after 3 days will
                not be considered valid by EaseMyBill and EaseMyBill will not be
                liable to refund any monies in such scenario
            </p>
            <p>
                In case the User claims a refund after the Transaction is
                settled, the User expressly agrees that such transactions cannot
                be rolled back and hence covenants to resolve this transaction
                with the Payee offline and inform EaseMyBill on such resolution.
            </p>
            <p>
                There shall be NO REFUNDS or REVERSAL of any kind for
                successfully initiated/processed Transactions. However, the User
                shall be fully refunded for any kind of debits on account of
                technical failure on the website or mobile application.
                Technical failure in this context refers to a deviation from the
                established payment process on our website or mobile application
                without an acknowledgement to the User on the successful
                remittance of the Transaction. In such a case, the User shall be
                refunded the entire amount, including the Convenience Fee (if
                any), without any demur into his account within 7 to 10 working
                days.
            </p>
            <p>
                All refunds to customers shall not entail any charge-back of
                Convenience Fee and related taxes.
            </p>
            <p>
                In case of user violating any T&C of EaseMyBill, EaseMyBill
                reserves the right to hold back any payment which it feels as
                suspicious or invalid unless completely validated.
            </p>
            <h3>Fraudulent Transactions</h3>
            <p>
                In case EaseMyBill is intimated, by the Acquiring Bank or a Card
                Payment Network, that a customer has reported an unauthorized
                debit of the customer's payment instrument ("FRAUDULENT
                TRANSACTION"), then EaseMyBill shall be entitled to suspend the
                settlement of the amount associated with the Fraudulent
                Transaction during the pendency of inquiries, investigations and
                resolution thereof by the Acquiring Bank or the Card Payment
                Network.
            </p>
            <p>
                In the event the Fraudulent Transaction results in a Chargeback,
                then the Chargeback shall be resolved in accordance with the
                provisions relating to Chargeback as set out under these Terms
                of Use.
            </p>
            <p>
                If the amount in respect of a Fraudulent Transaction has already
                been settled to the User pursuant to these Terms of Use, any
                dispute arising in relation to the said Fraudulent Transaction,
                following settlement, shall be resolved by the payment providers
                and the acquiring banks in accordance with applicable laws.
            </p>
            <h3>Termination</h3>
            <p>
                If either EaseMyBill or Service Provider suspects, on reasonable
                grounds, that a User has committed a breach of these Terms or
                any other agreement with EaseMyBill or any act of dishonesty or
                fraud against EaseMyBill/ any Service Provider, EaseMyBill shall
                be entitled to (a) suspend all payment under these Terms; (b)
                deactivate/ suspend the User Account and disable your password;
                and (c) terminate User's access to the EaseMyBill platform or
                the Services; pending enquiries by EaseMyBill. User may cancel
                his User Account at any time by sending EaseMyBill an email to
                that effect.
            </p>
            <h3>Indemnity</h3>
            <p>
                User shall keep EaseMyBill and the Acquiring Bank and Service
                Providers safe and harmless and indemnified from and against any
                and all liability (including but not limited to liabilities,
                judgments, damages, losses, claims, costs and expenses,) or any
                other loss that may occur, arising from or relating to any
                claim, suit or proceeding brought against EaseMyBill by another
                User/ Service Provider/ third party for reasons including, but
                not limited to (i) delivery, non-delivery or delay, deficiency
                or mistake in respect of the Products purchased sold or services
                rendered; (ii) a breach, non-performance, non-compliance or
                inadequate performance by the User of any of the terms,
                conditions, representations, obligations or warranties made by
                him; (iii) any acts, errors, misrepresentations, wilful
                misconduct or negligence of the User, or his employees,
                subcontractors and agents in performance of their obligations
                under these Terms of Use; (iv) infringement of Intellectual
                property rights, privacy rights of third parties; (v) third
                party claims or suits.
            </p>
            <h3>Annexure A: Banned items</h3>
            <ol type="1">
                <li>
                    Adult goods and services which include pornography and other
                    sexually suggestive materials (including literature, imagery
                    and other media); escort or prostitution services.
                </li>
                <li>
                    Alcohol which includes Alcohol or alcoholic beverages such
                    as beer, liquor, wine, or champagne
                </li>
                <li>Body parts which include organs or other body parts.</li>
                <li>
                    Bulk marketing tools which include email lists, software, or
                    other products enabling unsolicited email messages (spam).
                </li>
                <li>
                    Cable descramblers and black boxes which includes devices
                    intended to obtain cable and satellite signals for free.
                </li>
                <li>
                    {" "}
                    Child pornography which includes pornographic materials
                    involving minors.
                </li>
                <li>
                    Scrupulous Copyright unlocking devices which include Mod
                    chips or other devices designed to circumvent copyright
                    protection.
                </li>
                <li>
                    {" "}
                    Scrupulous Copyrighted media which includes unauthorized
                    copies of books, music, movies, and other licensed or
                    protected materials
                </li>
                <li>
                    Scrupulous Copyrighted software which includes unauthorized
                    copies of software, video games and other licensed or
                    protected materials, including OEM or bundled software.
                </li>
                <li>
                    Counterfeit and unauthorized goods which includes replicas
                    or imitations of designer goods; items without a celebrity
                    endorsement that would normally require such an association,
                    fake autographs, counterfeit stamps, and other potentially
                    unauthorized goods.
                </li>
                <li>
                    Drug test circumvention aids which include drug cleansing
                    shakes, urine test additives, and related items.
                </li>
                <li>
                    . Endangered species which includes plants, animals or other
                    organisms (including product derivatives) in danger of
                    extinction
                </li>
                <li>
                    Gaming/gambling which includes lottery tickets, sports bets,
                    memberships/ enrolment in online gambling sites, and related
                    content.
                </li>
                <li>
                    Government IDs or documents which include fake IDs,
                    passports, diplomas, and noble titles.
                </li>
                <li>
                    . Hacking and cracking materials which includes manuals,
                    how-to guides, information, or equipment enabling illegal
                    access to software, servers, watomites, or other protected
                    property.
                </li>
                <li>
                    {" "}
                    Illegal goods which include materials, products, or
                    information promoting illegal goods or enabling illegal
                    acts.
                </li>
                <li>
                    Miracle cures which include unsubstantiated cures, remedies
                    or other items marketed as quick health fixes.
                </li>
                <li>
                    Offensive goods which include literature, products or other
                    materials that: a) Defame or slander any person or groups of
                    people based on race, ethnicity, national origin, religion,
                    sex, or other factors b) crime which includes crime scene
                    photos or items, such as personal belongings, associated
                    with criminals
                </li>
                <li>
                    {" "}
                    Prescription drugs or herbal drugs like salvia and magic
                    mushrooms or any kind of online pharmacies which includes
                    drugs or other products requiring a prescription by a
                    licensed medical practitioner.
                </li>
                <li>
                    Pyrotechnic devices and hazardous materials which includes
                    fireworks and related goods; toxic, flammable, and
                    radioactive materials and substances.
                </li>
                <li>
                    Regulated goods which include air bags; batteries containing
                    mercury; Freon or similar substances/refrigerants,
                    chemical/industrial solvents, government uniforms, car
                    titles or logos, police badges and law enforcement
                    equipment, lock-picking devices, pesticides; postage meters,
                    recalled items, slot machines, surveillance equipment; goods
                    regulated by government or other agency specifications.
                </li>
                <li>
                    Securities, which includes stocks, bonds, or related
                    financial products.
                </li>
                <li>
                    Tobacco and cigarettes which includes cigarettes, cigars,
                    chewing tobacco, and related products.
                </li>
                <li>
                    Traffic devices which include radar detectors/ jammers,
                    license plate covers, traffic signal changers, and related
                    products.
                </li>
                <li>
                    Weapons which include firearms, ammunition, knives, brass
                    knuckles, gun parts, and other armaments.
                </li>
                <li>
                    Wholesale currency which includes discounted currencies or
                    currency exchanges.
                </li>
                <li>
                    Live animals or hides/skins/teeth, nails and other parts etc
                    of animals.
                </li>
                <li>Multi-Level Marketing collection fees.</li>
                <li>
                    The User shall not sell, purchase, provide or exchange a
                    cardholder's name or MasterCard / Visa account number
                    information in any form obtained by reason of a MasterCard/
                    Visa Card transaction to any third party other than its
                    MasterCard/ Visa acquiring member-Citrus Pay, or pursuant to
                    a government /statutory or competent body's request.
                </li>
                <li>
                    Relate to a) promotion of hate, violence, racial intolerance
                    or the financial exploitation of a crime, (b) Any product or
                    service that infringe or violate any copyright, trademark,
                    right of publicity or privacy or any other proprietary right
                    under the laws of any jurisdiction, (c) to store, track,
                    transfer, and manage balances of digital currencies, like
                    Bitcoin or Ethereum or cryptocurrencies.
                </li>
                <li>
                    . Relate to transactions that (a) show the personal
                    information of third parties in violation of applicable law,
                    (b) support pyramid or Ponzi schemes, matrix programs, other
                    "get rich quick" schemes or certain multi-level marketing
                    programs, (c) are associated with purchases of annuities or
                    lottery contracts, lay-away systems, off-shore banking or
                    transactions to finance or refinance debts funded by a
                    credit card, (d) involve certain credit repair, debt
                    settlement services, credit transactions or insurance
                    activities, or (e) involve offering or receiving payments
                    for the purpose of bribery or corruption or money laundering
                    in any manner whatsoever.
                </li>
            </ol>
        </div>
    );
}
