import React, { useState } from "react";
import ComplaintClasses from "./Complaint.module.css";
import RaiseComplaint from "./RaiseComplaint";
import Status from "./ComplaintStatus";
import Search from "./search";
function Complaint() {
    const [active, setActive] = useState("Raise");

    const activeHandler = (e) => {
        let button = e.target.value;
        setActive(button);
    };

    return (
        <div className={ComplaintClasses["Container"]}>
            <div className={ComplaintClasses["header"]}>
                <button
                    onClick={activeHandler}
                    value="Raise"
                    className={
                        active === "Raise"
                            ? ComplaintClasses["active"]
                            : ComplaintClasses["inactive"]
                    }>
                    Raise Complaint
                </button>
                <button
                    onClick={activeHandler}
                    value="Status"
                    className={
                        active === "Status"
                            ? ComplaintClasses["active"]
                            : ComplaintClasses["inactive"]
                    }>
                    Complaint Status
                </button>
                <button
                    onClick={activeHandler}
                    value="search"
                    className={
                        active === "search"
                            ? ComplaintClasses["active"]
                            : ComplaintClasses["inactive"]
                    }>
                    search Transactions
                </button>
            </div>
            {active == "Raise" && (
                <div className={ComplaintClasses["raise"]}>
                    <RaiseComplaint />
                </div>
            )}
            {active == "Status" && <Status />}
            {active == "search" && <Search />}
        </div>
    );
}

export default Complaint;
