import React from "react";
import screenshotClasses from "./screenshots.module.css";
import Img1 from "../../../Assets/Homepage/img1.png";
import Img2 from "../../../Assets/Homepage/img2.png";
import Img3 from "../../../Assets/Homepage/img3.png";
function Screenshots() {
    return (
        <div className={screenshotClasses["Container"]} id="screenshots">
            <h3>App Screen shots</h3>
            <p>
                EasemyBill is a digital payment service provide platforms where
                you can get instant funds for your utilities bill payments
            </p>
            <div className={screenshotClasses["screenshots"]}>
                <img src={Img1} alt="phone" />
                <img src={Img2} alt="phone" />
                <img src={Img3} alt="phone" />
            </div>
        </div>
    );
}

export default Screenshots;
