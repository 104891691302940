import React, { useState, useEffect } from "react";
import paymentClasses from "./QuickPayment.module.css";
import Layout from "../../Utils/layout/layout";
import { Services } from "../../Services";
import useStore from "../../Store";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Load from "../../Utils/loader";
import { useNavigate } from "react-router";
import BillPayment from "../../Assets/dashboard/billpayment.png";
function Quickpayment() {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    let category = localStorage.getItem("category");
    const userData = useStore((state) => state.userData);
    const Token = useStore((state) => state.Token);
    const token = { Authorization: `token ${Token}` };
    const [dropDown, setDropDown] = useState([]);
    const [billervalues, setbillervalues] = useState();
    const [submitEnabler, setSubmitEnabler] = useState(false);
    const [spinner, setspinner] = useState(false);
    useEffect(() => {
        Services.fetchBillerByCategoryName("GET", null, token, category, 0)
            .then((res) => {
                console.log(res);
                if (res.Status === 1) {
                    setDropDown(res.BillersList);
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    const selectBillerHandler = (e) => {
        let body = { billerId: e.target.value };
        Services.fetchBillerinfoPost("POST", JSON.stringify(body), token)
            .then((response) => {
                console.log(response);
                if (response?.Status == 1) {
                    setbillervalues(response?.BillerInfo);
                    localStorage.setItem(
                        "billerAdhoc",
                        response?.BillerInfo?.billerAdhoc
                    );
                    localStorage.setItem(
                        "billerName",
                        response?.BillerInfo?.billerName
                    );
                } else if (response.Status == 0) {
                    alert("something went wrong try again later");
                }
            })
            .catch((err) => {
                console.log(err);
                alert("something went wrong try again later");
            });
        setSubmitEnabler(true);
    };
    const onSubmit = (data) => {
        setspinner(true);
        localStorage.setItem("usernumber", data.MobileNumber);
        // setspinner(true);
        const [first, second, ...result] = Object.entries(data).map((e) => ({
            [e[0]]: e[1],
        }));
        const inputresult = result?.map((item) => {
            return {
                paramName: Object?.keys(item)[0],
                paramValue: Object?.values(item)[0],
            };
        });
        const body = {
            billerId: data?.Biller,
            mobileNumber: data?.MobileNumber,
            Params: { inputParams: { input: [...inputresult] } },
        };
        Services.fetchBill("POST", JSON.stringify(body), token)
            .then((response) => {
                console.log("111", response);
                setspinner(false);
                if (response?.Status == 1) {
                    console.log("detail", response);
                    localStorage.setItem(
                        "billdetails",
                        JSON.stringify(response)
                    );
                    localStorage.setItem(
                        "amount",
                        response.BillDetails?.billerResponse?.billAmount
                    );
                    localStorage.setItem("billerid", response.Biller);
                    navigate(
                        `/dashboard/bill-payments/paybill-amount/${category}`
                    );
                } else if (response?.data.Status == 0) {
                    alert(response?.data?.Message);
                }
            })
            .catch((err) => {
                setspinner(false);
                alert("some thing went wrong please try later ");
            });
    };
    return (
        <Layout Active={"Bbps"}>
            {spinner && <Load bg={true} />}
            <div className={paymentClasses["Container"]}>
                <div className={paymentClasses["main-header"]}>
                    <h3>Bill payments</h3>
                    <img src={BillPayment} alt="billpayment" />
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <select
                        className="select"
                        defaultValue={"Select-Biller"}
                        {...register("Biller", {
                            required: "Please select Biller",
                            onChange: (e) => {
                                selectBillerHandler(e);
                            },
                        })}>
                        <option value="Select-Biller" disabled>
                            Select Biller
                        </option>
                        {dropDown?.map((item) => (
                            <option value={item?.blr_id}>
                                {" "}
                                {item?.blr_name}
                            </option>
                        ))}
                    </select>
                    {submitEnabler && (
                        <>
                            <div className="biller-detail">
                                <input
                                    className={
                                        errors?.MobileNumber && "input-error"
                                    }
                                    id="billerinput"
                                    placeholder="Enter Mobile Number"
                                    type={"number"}
                                    {...register("MobileNumber", {
                                        required: "Please Enter Mobile Number",
                                    })}
                                />
                                {errors?.MobileNumber && (
                                    <p style={{ color: "red" }}>
                                        {" "}
                                        {errors?.MobileNumber.message}
                                    </p>
                                )}
                            </div>
                            {billervalues?.billerInputParams?.paramInfo?.map(
                                (data) => {
                                    const name = data?.paramName;
                                    const boolValue =
                                        data?.isOptional == "true";
                                    console.log(name);
                                    return (
                                        <div className="biller-detail">
                                            <input
                                                className={
                                                    errors[`${name}`] &&
                                                    "input-error"
                                                }
                                                data-regex={data?.regEx}
                                                minLength={data?.minLength}
                                                maxLength={data?.maxLength}
                                                data-name={data?.paramName}
                                                id="billerinput"
                                                placeholder={name}
                                                type={"text"}
                                                pattern={data?.regEx}
                                                {...register(`${name}`, {
                                                    required: !boolValue,
                                                    pattern: {
                                                        value: data?.regEx,
                                                        message: `invalid input`,
                                                    },
                                                })}
                                            />
                                            {errors[`${name}`] && (
                                                <p
                                                    style={{
                                                        color: "red",
                                                    }}>
                                                    Enter a Valid{" "}
                                                    {data?.paramName}
                                                </p>
                                            )}
                                        </div>
                                    );
                                }
                            )}
                            <button type="submit">Fetch Bill</button>
                        </>
                    )}
                </form>
            </div>
        </Layout>
    );
}

export default Quickpayment;
