import React, { useState } from "react";
import { useForm } from "react-hook-form";
import complaintClasses from "./Complaint.module.css";
import { Services } from "../../../Services";
import useStore from "../../../Store";
import Spinner from "../../../Utils/loader";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
function RaiseComplaint() {
    const Navigate = useNavigate;
    const userData = useStore((state) => state.userData);
    const Token = useStore((state) => state.Token);
    const [load, setLoad] = useState(false);
    const token = { Authorization: `token ${Token}` };
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const onSubmit = (data) => {
        setLoad(true);
        Services.RaiseComplaint("POST", JSON.stringify({ ...data }), token)
            .then((res) => {
                if (res.Status === 1) {
                    localStorage.setItem(
                        "registersuccess",
                        JSON.stringify(res?.RegisterComplaintResponse)
                    );
                    Navigate("/ComplaintRegistration");
                } else {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className={complaintClasses["raise-Complaint-form"]}>
                <div className={complaintClasses["complaint-form"]}>
                    <label htmlFor="">type of Complaint-</label>
                    <select
                        {...register("complaintType")}
                        defaultValue="Transaction">
                        <option value="Transaction">Transactions</option>
                    </select>
                </div>
                <div className={complaintClasses["form-control"]}>
                    <input
                        className={
                            errors.transactionReferenceId
                                ? complaintClasses["error"]
                                : ""
                        }
                        placeholder="Transaction Reference ID"
                        {...register("transactionReferenceId", {
                            required: true,
                        })}
                    />
                    {errors.transactionReferenceId && (
                        <span>This field is required</span>
                    )}
                </div>
                <div className={complaintClasses["form-control"]}>
                    <select
                        className={
                            errors.complaintDisposition
                                ? complaintClasses["error"]
                                : ""
                        }
                        placeholder="Complaint Disposition"
                        {...register("complaintDisposition", {
                            required: true,
                        })}
                        defaultValue="">
                        <option value="">Complaint Disposition</option>
                        <option value={1}>
                            Transaction Successful,account not updated
                        </option>
                        <option
                            value={
                                "Amount deducted, biller account credited but transaction ID not received"
                            }>
                            Amount deducted, biller account credited but
                            transaction ID not received
                        </option>
                        <option value="Amount deducted, biller account not credited & transaction ID not received">
                            Amount deducted, biller account not credited &
                            transaction ID not received
                        </option>
                        <option value="Amount deducted multiple times">
                            Amount deducted multiple times
                        </option>
                        <option value={"Double payment updated"}>
                            Double payment updated
                        </option>
                        <option value={"Erroneously paid in wrong account"}>
                            Erroneously paid in wrong account
                        </option>
                    </select>
                    {errors.complaintDisposition && (
                        <span>This field is required</span>
                    )}
                </div>
                <div className={complaintClasses["form-control"]}>
                    <textarea
                        className={
                            errors.Description ? complaintClasses["error"] : ""
                        }
                        cols="30"
                        rows="7"
                        placeholder="Description"
                        {...register("complaintDescription", {
                            required: "complaintDescription is required",
                        })}></textarea>
                    {errors.complaintDescription && (
                        <span>This field is required</span>
                    )}
                </div>

                {load && (
                    <div className={complaintClasses["spinner"]}>
                        <Spinner bg={true} />
                    </div>
                )}
                <button type="submit">Submit</button>
            </form>
        </>
    );
}

export default RaiseComplaint;
