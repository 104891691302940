import React, { useState, useEffect } from "react";
import classes from "./Bbps.module.css";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import useStore from "../../Store";
import { toast } from "react-toastify";
import { Services } from "../../Services";
import Load from "../../Utils/loader";
function QuickPay() {
    const [billervalues, setbillervalues] = useState();
    const Token = useStore((state) => state.Token);
    const token = { Authorization: `token ${Token}` };
    const [spinner, setspinner] = useState(false);
    const Navigate = useNavigate();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        let body = {
            billerId: localStorage.getItem("Biller"),
        };
        Services.fetchBillerinfoPost("POST", JSON.stringify(body), token)
            .then((response) => {
                console.log(response);
                if (response?.Status == 1) {
                    setbillervalues(response?.BillerInfo);
                    localStorage.setItem(
                        "billerAdhoc",
                        response?.BillerInfo?.billerAdhoc
                    );
                    localStorage.setItem(
                        "billerName",
                        response?.BillerInfo?.billerName
                    );
                } else if (response.Status == 0) {
                    alert("something went wrong try again later");
                }
            })
            .catch((err) => {
                console.log(err);
                alert("something went wrong try again later");
            });
    }, []);
    const fetchbillHandler = (data) => {
        localStorage.setItem("usernumber", data?.MobileNumber);
        const input = Object.entries(data).map((item) => {
            return { paramName: item[0], paramValue: item[1] };
        });
        // input.splice(0, -2);
        const bodydata = input.filter((item) => item.paramName !== "Amount");
        const bodydatafilter = bodydata.filter(
            (item) => item.paramName !== "MobileNumber"
        );
        const body = {
            billerAdhoc: "true",
            Amount: Number(data?.Amount) * 100,
            billerId: localStorage.getItem("Biller"),
            mobileNumber: data?.MobileNumber,
            Params: {
                inputParams: {
                    input: [...bodydatafilter],
                },
                paymentMethod: {
                    paymentMode: "Wallet",
                    quickPay: "Y",
                    splitPay: "N",
                },
                amountInfo: {
                    amount: Number(data?.Amount) * 100,
                    currency: "356",
                    custConvFee: "0",
                },
                paymentInfo: {
                    info: [
                        {
                            infoName: "WalletName",
                            infoValue: "EasemyBill",
                        },
                        {
                            infoName: "MobileNo",
                            infoValue: localStorage.getItem("mobilenumber"),
                        },
                    ],
                },
            },
        };
        setspinner(true);
        Services.quickPayBills("POST", JSON.stringify(body), token)
            .then((response) => {
                setspinner(false);
                if (response.data?.Status == 1) {
                    localStorage.setItem(
                        "success",
                        JSON.stringify(response?.QuickBillPayResponse)
                    );
                    // Navigate(
                    //     `/dashboard/bill-payments/quick-pay/QuickPaySuccess`
                    // );
                } else if (response?.status == 0) {
                    alert(response?.Message);
                }
                console.log(response, "1111");
            })
            .catch((err) => {
                setspinner(false);
                alert("some thing went wrong try after sometime");
            });
    };

    return (
        <>
            {spinner && <Load bg={true} />}
            <div className={classes["quick-pay-wrapper"]}>
                <form
                    className="biller-details"
                    id="quickpay"
                    onSubmit={handleSubmit(fetchbillHandler)}>
                    <h1 className="biller-name">{billervalues?.billerName}</h1>
                    {billervalues?.billerInputParams?.paramInfo?.map((data) => {
                        const name = data?.paramName;
                        const boolValue = data?.isOptional == "true";
                        // console.log(name);
                        return (
                            <div className={classes["biller-detail"]}>
                                <input
                                    className={
                                        errors[`${name}`] && "input-error"
                                    }
                                    data-regex={data?.regEx}
                                    minLength={data?.minLength}
                                    maxLength={data?.maxLength}
                                    data-name={data?.paramName}
                                    id="billerinput"
                                    placeholder={name}
                                    type={"text"}
                                    pattern={data?.regEx}
                                    {...register(`${name}`, {
                                        required: !boolValue,
                                        pattern: {
                                            value: data?.regEx,
                                            message: `invalid input`,
                                        },
                                    })}
                                />
                                {errors[`${name}`] && (
                                    <p
                                        style={{
                                            color: "red",
                                        }}>
                                        Enter a Valid {data?.paramName}
                                    </p>
                                )}
                            </div>
                        );
                    })}
                    <div className={classes["biller-detail"]}>
                        <input
                            className={errors?.Amount && "input-error"}
                            id="billerinput"
                            placeholder="Enter Amount"
                            type={"number"}
                            {...register("Amount", {
                                required: "Please Enter Amnout",
                            })}
                        />
                        {errors?.Amount && (
                            <p style={{ color: "red" }}>
                                {" "}
                                {errors?.Amount.message}
                            </p>
                        )}
                    </div>
                    <div className={classes["biller-detail"]}>
                        <input
                            className={errors?.MobileNumber && "input-error"}
                            id="billerinput"
                            placeholder="Enter Mobile Number"
                            type={"number"}
                            {...register("MobileNumber", {
                                required: "Please Enter Mobile Number",
                            })}
                        />
                        {errors?.MobileNumber && (
                            <p style={{ color: "red" }}>
                                {" "}
                                {errors?.MobileNumber.message}
                            </p>
                        )}
                    </div>

                    <button type="submit">Pay</button>
                </form>
            </div>
        </>
    );
}

export default QuickPay;
