import React from "react";
import privacyClasses from "./privacy.module.css";
export default function PrivacyPolicyPage() {
    return (
        <div className={privacyClasses["Container"]}>
            <h1>Privacy Policy</h1>

            <p>
                Ezycredits Technologies Private Limited [ “EaseMyBill” ‚ “we” or
                “us” ] is committed to protecting your information and privacy.
                This Privacy Policy is designed to help you understand how we
                may collect, process, store and use the information you provide
                to us and to assist you in making informed decisions while using
                our Service.
            </p>
            <p>
                This policy is pursuant to the provisions of Rule 4(1) of the
                Information Technology (Reasonable Security Practices and
                Procedures and Sensitive Personal Data or Information) Rules,
                2011 as may be amended from time to time
            </p>
            <h3>1. Acceptance of this Policy</h3>
            <p>
                You are advised to read this Privacy Policy carefully. By
                accepting this Privacy Policy and the Terms and Conditions of
                Use, a User expressly consents to EaseMyBill's collection,
                storage, use and disclosure of his information as described
                herein. If you do not feel comfortable with any part of this
                Privacy Policy or our Terms of Service, you must not use or
                access our Service or download, install and/or use the mobile
                application
            </p>
            <h3>2. Applicability of this Policy</h3>
            <p>
                This Privacy Policy applies to any information of the user when
                he access/visits our Sites or uses our Services and does not
                apply to online platforms or services that EaseMyBill do not own
                or control, including platforms or services of third parties.
                EaseMyBill's privacy policy applies to use of the Platform
                application, desktop, EaseMyBill platform and mobile WAP site,
                API, and all devices, functionalities, features, technologies
                and other services uniformly and its terms are made a part of
                these T&Cs by this reference. Additionally, by using our
                services, the user hereby expressly acknowledges and agrees that
                Internet transmissions are never completely private or secure.
                The user understands that any message or information he sends to
                the Platform may be read or intercepted by others, even if there
                is a special notice that a particular transmission (for example,
                credit card information) is encrypted.
            </p>
            <h3>3. Changes to this Policy</h3>
            <p>
                We may revise this Privacy Policy from time to time to reflect
                changes to our business, the Sites or Services, or applicable
                laws. The revised Privacy Policy will be effective as of the
                published effective date. The revised terms will be notified
                once the user logs in and hence you should review and read the
                terms and conditions and the privacy policy regularly
            </p>
            <h3>4. Definitions</h3>
            <p>
                EaseMyBill shall mean Ezycredits Technologies Private Limited
                and includes its affiliates and subsidiaries
            </p>
            <p>
                EaseMyBill Associates shall mean such entities that provide and
                facilitate services withEaseMyBill, including banks, acquirers
                and providers of payment gateways or such other vendors engaged
                by EaseMyBill to provide its services
            </p>
            <p>
                EaseMyBill Account/Services means any services and any and all
                related EaseMyBill platforms, applications and services offered
                to you by EaseMyBill in connection with your EaseMyBill Account
                and include such services which may be offered by EaseMyBill
                including but not restricted to digital payment solutions,
                invoice discounting, facilitating business credit and such other
                services as may be offered by EaseMyBill from time to time
                Personal Data/Information means personal information that can be
                associated with an identified or identifiable person. “Personal
                Data” can include name, postal address (including billing and
                shipping addresses), telephone number, email address, credit
                card number, bank account number, other financial account
                information, financial statements, date of birth, and
                government-issued credentials (e.g., driver's license number,
                permanent account number Aadhar Number, passport etc.). Personal
                Data does not include information that does not identify a
                specific User.
            </p>
            <p>
                Process means any method or manner in which we handle Personal
                Data whether or not by automated means, such as collection,
                recording, organization, structuring, storage, adaptation or
                alteration, retrieval, and consultation, disclosure by
                transmission, disseminating or erasure or destruction of
                Personal Data.
            </p>
            <p>
                Sites means the EaseMyBill website, mobile applications,
                official social media platforms, or other online properties
                through which EaseMyBill offers the Services and which has
                posted or linked to this Privacy Policy.
            </p>
            <p>
                "User": shall mean and include a person who registers himself on
                the EaseMyBill platform to transact or avails all or any of
                EaseMyBill services this can include an individual resident in
                India or entity or company registered in India
            </p>
            <h3>5.Information we collect</h3>
            <p>
                The user will be required to submit the following information
                while registering:
            </p>
            <p>
                When the users opens a EaseMyBill account or avails of a
                EaseMyBill service or downloads a EaseMyBill application through
                a platform, EaseMyBill shall collect following information as
                necessary to render our services: - Name, postal address, date
                of birth, mobile/telephone phone number, email address, PAN,
                GST, financial information like bank account details,
                identification information of the user to set up the Account
            </p>
            <p>
                EaseMyBill after obtaining the consent of the user at the time
                of registration also access the following information ; device
                ID for mapping the application to the device on which it is
                registered, SMS for entering the activation code directly in the
                application during the activation, details of the browser,
                operating system, domain name, IP address, mobile application
                version.
            </p>
            <p>
                When the user accessEaseMyBill Sites, to process payments, or to
                send money, EaseMyBill may collect information about the
                transaction, as well as other information associated with the
                transaction such as amount sent or requested, amount paid for
                products or services, merchant information, including
                information about any funding instruments used to complete the
                transaction, Device Information, Technical Usage Data, and
                Geolocation Information.
            </p>
            <p>
                When the user uses the Services or access the Sites, EaseMyBill
                shall collect Personal Data the user provides EaseMyBill about
                the other participants associated with the transaction such as
                name, postal address, telephone number, and financial account
                information about the payee/payer who is receiving money from
                the user or sending money to user. This information related to
                the payee/payer and relating to transactions carried out on the
                Sites is also collected and stored byEaseMyBill. Hence the user
                should ensure that the user has obtained explicit consent of the
                payer/payee to share, store, process and use its information as
                per this Privacy Policy
            </p>
            <p>
                EaseMyBill may collect additional information from or about the
                user when the user communicates with EaseMyBill, contacts our
                customer support teams or responds to a survey. By choosing to
                provide this personal information, a User expressly consents to
                the transfer and storage of that information on EaseMyBill/
                EaseMyBill Associate servers.
            </p>
            <h3>6.Storage of Personal Information</h3>
            <p>
                EaseMyBill shall retain Personal information to fulfill our
                legal or regulatory obligations and to enable us to provide you
                seamless services. EaseMyBill may retain Data for longer periods
                than required by law if it is in our legitimate business
                interests and not prohibited by law. If your Account is closed,
                we may take steps to mask Data and other information, but
                EaseMyBill reserves our ability to retain and access the data
                for so long as required to comply with applicable laws. We will
                continue to use and disclose such Data in accordance with this
                Privacy Policy, if so required
            </p>
            <h3>7. Use of Personal information</h3>
            <p>We use personal information for varied reasons</p>
            <ol type="1">
                <li>to provide and improve the services you request;</li>
                <li>
                    to initiate and complete the transaction and/or settlement
                    as requested;
                </li>
                <li>to authenticate your KYC documents</li>
                <li>
                    to perform creditworthiness and other financial standing
                    checks, evaluate applications, and compare information for
                    accuracy and verification purposes;
                </li>
                <li>
                    to tailor and customize your experience at our EaseMyBill
                    platform
                </li>
                <li>
                    to provide you with content that we think you might be
                    interested in and to display content according to your
                    preferences
                </li>
                <li>to resolve disputes; troubleshoot problems;</li>
                <li>to promote a safe service;</li>
                <li>
                    to measure user interest in our services through monitoring,
                    research and also analysis of the user behavior on the
                    Sites;
                </li>
                <li>
                    to inform you on online and offline offers, products,
                    services, and updates;
                </li>
                <li>
                    11.to detect and protect us against error, fraud and other
                    criminal activity and
                </li>
                <li>
                    12.to comply with the relevant laws and regulations and
                    enforce the terms and Services
                </li>
            </ol>
            <p>
                When a User utilizes the EaseMyBill platform or sends emails or
                other data, information or communication to EaseMyBill or any of
                its accredited service providers or banks, the User agrees and
                understands that such information and transaction related data
                may be communicated through a third-party payment gateway
                controlled by a EaseMyBill Associate through electronic records
                and to the acquirer or the banks. The User expressly consents
                that such personal information and transaction related data
                and/or data related to the payer/payee shall be shared to such
                parties in course of the services provided by us. The User
                consents to receive communications via electronic records from
                such EaseMyBill Associate periodically. Upon registration
                withEaseMyBill, a User agrees that he shall be contacted on his
                mobile or e-mail address for authentication of a transaction or
                from time to time about updating his content to provide features
                which EaseMyBill believes may benefit him or for the purpose of
                receiving promotional messages. A User's information shall
                remain with EaseMyBill and EaseMyBill Associates. EaseMyBill
                does not sell or rent your personal information to anyone. The
                user hereby confirms that he has no objection to aforesaid use
                of his information.
            </p>
            <h3>8. Disclosure of Information</h3>
            <p>
                EaseMyBill may in course of rendering its services share
                Personal Data of the User with third-party service providers,
                banks, acquirers and payment gateways that perform services and
                functions at our direction and on our behalf viz including but
                not limited to processing of the transactions, providing
                customer support or user behavior analysis, KYC check etc.
                EaseMyBill also uses such information to perform statistical
                analyses of User behavior and characteristics in order to
                measure interest in and use of the user.
            </p>
            <p>
                Your consent to this Privacy Policy followed by your submission
                of such information represents your agreement to that transfer.
            </p>
            <p>
                EaseMyBill may also share a User's personal information with any
                statutory or legal authority as may be required or mandated by
                that authority. EaseMyBill may disclose personal information
                when required, such as to respond to legal requirements, enforce
                EaseMyBill policies or protect anyone's rights, property, or
                safety. Such information will be disclosed in accordance with
                applicable laws and regulations in good faith. Please note that
                we are not required to question or contest the validity of any
                search warrant, subpoena or other similar governmental request
                that we receive. If required by law to do so or if considered
                necessary to do so for proper management of credit or business
                risk, EaseMyBill reserves the right to disclose any and all such
                User information, including default in any payments or misuse of
                the service, to any court of competent jurisdiction,
                quasi-judicial authority, law enforcement agency, relevant wing
                of the Central Government or State Government, User's employer,
                credit reference agency, financial institution or any company
                which is an affiliate or associate or parent or subsidiary of
                EaseMyBill. EaseMyBill shall not sell or rent a User's personal
                information to third parties for their marketing purposes
                without his explicit consent. EaseMyBill shall comply with the
                DND regulations as framed and applicable from time to time.
            </p>
            <p>
                EaseMyBill will only disclose your information in accordance
                with this Privacy Policy. If there is a requirement to use it
                for any other purpose, the consent of the user will be obtained
            </p>
            <h3>9. Information security</h3>
            <p>
                Our EaseMyBill platform has stringent security measures in place
                to protect the loss, misuse, and alteration of the information
                under our control. Whenever you change or access your account
                information, we offer the use of a secure server. Once your
                information is in our possession we adhere to strict security
                guidelines, protecting it against unauthorized access.
            </p>
            <p>
                Once your information is in our possession we adhere to strict
                security guidelines, protecting it against unauthorized access.
                While we are dedicated to securing our systems and Services, you
                are responsible for securing and maintaining the privacy of your
                password(s) and Account/profile registration information and
                verifying that the Personal Data we maintain about you is
                accurate and current. We are not responsible for protecting any
                Personal Data that we share with a third-party based on an
                account connection that you have authorized.
            </p>
            <p>
                The EaseMyBill platform includes links to other platforms. Such
                platforms are governed by their respective privacy policies,
                which are beyond EaseMyBill's control. Once a User leaves the
                EaseMyBill/ EaseMyBill Associate servers, use of any information
                provided by the User is governed by the privacy policy of the
                operator of the site which the User is visiting. That policy may
                differ from this EaseMyBill policy. All information gathered on
                the EaseMyBill platform is securely stored and access to such
                information is strictly limited based on need-to-know basis. In
                addition, details of a User's credit/ debit card number, PIN
                details for payment or for verification purposes, password and
                account information are encrypted by EaseMyBill in accordance
                with the guidelines laid down by the RBI Standards. Further,
                EaseMyBill does not store the CVV number of a User.
            </p>
            <h3>10. Disclaimer</h3>
            <p>
                You are aware that however as robust and effective as the
                security measures are, no security system is impenetrable. You
                hereby acknowledge that the User is aware of all security risks,
                including possible third-party interception of any of his
                transactions and statements of account on the internet and the
                content of his transactions or statements of account becoming
                known to third parties. The User agrees and undertakes that he
                shall not hold EaseMyBill/ EaseMyBill Associates liable
                therefore in any way. The use and storage of any information,
                including without limitation, the credit card number, PIN,
                password, account information, transaction activity, account
                balances and any other information available on the User's
                internet access device or computer or mobile phone is at his own
                risk and responsibility. The User furthermore acknowledges and
                accepts that EaseMyBill may not encrypt all content or
                communications from and to their EaseMyBill platform.
            </p>
            <h3>11. Choice of Opt in/Opt out</h3>
            <p>
                We provide all users with the opportunity to opt-out of
                receiving non-essential (promotional, marketing-related)
                communications from us on behalf of our partners, and from us in
                general, after setting up an account. If you want to remove your
                contact information from all our lists and newsletters, please
                write to us on admin@ezycredits.com
            </p>
            <h3>12.Confidentiality</h3>
            <p>
                User agrees not to disclose or attempt to use or personally
                benefit from any non-public information that he may learn on the
                EaseMyBill platform or through the Services.
            </p>
            <p>
                This obligation shall continue until such time as the non-public
                information has become publicly known through no action of your
                own. If the User is compelled by order of a court or other
                governmental or legal body (or have notice that such an order is
                being sought) to divulge any such non-public information, he
                agrees to forthwith and diligently notify EaseMyBill and
                cooperate fully with EaseMyBill in protecting such information
                to the extent possible under applicable law.
            </p>
            <p>
                EaseMyBill may access, preserve and disclose any of the user's
                information if required to do so by law, or EaseMyBill believes
                in good faith that it is reasonably necessary to (i) respond to
                claims asserted against EaseMyBill or to comply with legal
                process, (ii) for fraud prevention, risk assessment,
                investigation, customer support, product development and
                debugging purposes, or (iii) protect the rights, property or
                safety of EaseMyBill, its Users or members of the public. Please
                refer to our Privacy Policy for further details
            </p>
            <h3>13. General</h3>
            <p>
                The User hereby agrees that the account information and copies
                of all documents submitted by a User are and shall remain the
                sole and exclusive property of EaseMyBill, and EaseMyBill shall
                not be obliged to return the same to him upon his request.
            </p>
            <p>
                Any unauthorized access to the EaseMyBill web-site is a breach
                of these terms and a violation of the law.
            </p>
            <p>
                A User agrees not to access EaseMyBill web-sites by any means
                other than through the interface that is provided by EaseMyBill
                for use in accessing the same. Any content that may be
                restricted or banned by a government order, will be withdrawn
                during the pendency of that order.
            </p>
            <p>
                EaseMyBill is the sole and absolute owner of all and whatsoever
                intellectual property rights in the trademark "EaseMyBill" and
                the design in respect thereof.
            </p>
            <p>
                EaseMyBill will be entitled to take any legal action against any
                person invading and/or encroaching upon the said rights.
            </p>
            <p>
                This Policy does not apply to the practices of companies that
                EaseMyBill does not own or control, or to people that EaseMyBill
                does not employ or manage.
            </p>
            <p>
                If a User has any questions he may write to EaseMyBill Customer
                Support at admin@ezycredits.com
            </p>
        </div>
    );
}
