import React, { useEffect, useState } from "react";
import modalClasses from "./modal.module.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Moment from "react-moment";
import useStore from "../../../Store";
import { loginServices } from "../../../Services/loginServices";
import { Services } from "../../../Services";
function Pay(props) {
    const [transactionDetails, setTransactionDetails] = useState({});
    const userData = useStore((state) => state.userData);
    const token = useStore((state) => state.Token);
    useEffect(() => {
        const auth = { Authorization: `token ${token}` };
        Services.getTransactionByID(
            "GET",
            null,
            auth,
            userData?.userId,
            props.transactionId
        )
            .then((ApiData) => {
                console.log(ApiData);
                if (ApiData?.Status === 1) {
                    setTransactionDetails(ApiData.walletTransaction);
                } else if (ApiData?.Status === 0) {
                    alert(ApiData?.Message);
                }
            })
            .catch((err) => {
                console.log(err);
                alert("something went wrong ");
            });
    }, [props.transactionId, token, userData]);
    return (
        <Modal
            open={props.modal}
            showCloseIcon={false}
            center
            onClose={() => {
                props.setmodal(false);
            }}>
            <div className={modalClasses["transactions-details"]}>
                <div className={modalClasses["transaction"]}>
                    <h3>Name</h3>
                    <p>:</p>
                    <p>{transactionDetails?.firstName}</p>
                </div>
                <div className={modalClasses["transaction"]}>
                    <h3>Mobile Number</h3>
                    <p>:</p>
                    <p>{transactionDetails?.receiver_mobileNumber}</p>
                </div>
                <div className={modalClasses["transaction"]}>
                    <h3>Razorpay Transaction ID</h3>
                    <p>:</p>
                    <p> {transactionDetails?.razorpay_TransactionId}</p>
                </div>
                <div className={modalClasses["transaction"]}>
                    <h3>Sub total</h3>
                    <p>:</p>
                    <p> ₹ {transactionDetails?.subtotalAmount}</p>
                </div>
                <div className={modalClasses["transaction"]}>
                    <h3>GST Amount </h3>
                    <p>:</p>
                    <p> ₹ {transactionDetails?.GSTAmount}</p>
                </div>
                <div className={modalClasses["transaction"]}>
                    <h3>Amount</h3>
                    <p>:</p>
                    <p>{transactionDetails?.amount}</p>
                </div>
                <div className={modalClasses["transaction"]}>
                    <h3>Transaction Type </h3>
                    <p>:</p>
                    <p>
                        {" "}
                        {transactionDetails?.transactionType === 1 && (
                            <span>Recharge</span>
                        )}
                        {transactionDetails?.transactionType === 2 && (
                            <span>Payout</span>
                        )}
                        {transactionDetails?.transactionType === 3 && (
                            <span>Wallet Debit</span>
                        )}
                        {transactionDetails?.transactionType === 4 && (
                            <span>Wallet Credit</span>
                        )}
                        {transactionDetails?.transactionType === 5 && (
                            <span> payout refund</span>
                        )}
                        {transactionDetails?.transactionType === 6 && (
                            <span> payout charges debit transaction</span>
                        )}
                        {transactionDetails?.transactionType === 7 && (
                            <span>wallet commission</span>
                        )}
                    </p>
                </div>
                <div className={modalClasses["transaction"]}>
                    <h3>Date</h3>
                    <p>:</p>
                    <p>
                        {" "}
                        <Moment format="DD MMM YYYY hh:mm A">
                            {transactionDetails?.transactionDateTime}
                        </Moment>
                    </p>
                </div>
                <div className={modalClasses["transaction"]}>
                    <h3>Transactions Status </h3>
                    <p>:</p>
                    <p>
                        {transactionDetails?.transactionStatus === 2 && (
                            <span className="pending-span"> Pending</span>
                        )}
                        {transactionDetails?.transactionStatus === 1 && (
                            <span className="completed-span"> Processed</span>
                        )}
                        {transactionDetails?.transactionStatus === 3 && (
                            <span className="failed-span"> Failed</span>
                        )}
                        {transactionDetails?.transactionStatus === 4 && (
                            <span className="reversed-span"> Reversed</span>
                        )}
                    </p>
                </div>
                <div className={modalClasses["transaction"]}>
                    <h3>Description</h3>
                    <p>:</p>
                    <p>{transactionDetails?.description}</p>
                </div>
                <div className={modalClasses["transaction"]}>
                    <h3>Notes</h3>
                    <p>:</p>
                    <p>{transactionDetails?.notes}</p>
                </div>
                <div className={modalClasses["transaction"]}>
                    <h3>Payout ContactId </h3>
                    <p>:</p>
                    <p>{transactionDetails?.payout_contactId}</p>
                </div>
                <div className={modalClasses["transaction"]}>
                    <h3>Order ID </h3>
                    <p>:</p>
                    <p>{transactionDetails?.wallet_transactionId}</p>
                </div>
            </div>
        </Modal>
    );
}

export default Pay;
