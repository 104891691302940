import React from "react";
import { useForm } from "react-hook-form";
import menuClasses from "./links.module.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { CgPassword } from "react-icons/cg";
function Pay(props) {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        props.setmodal(false);
    };
    return (
        <Modal
            open={props.modal}
            center
            onClose={() => {
                props.setmodal(false);
            }}>
            <div className={menuClasses["form"]}>
                <h3>
                    <CgPassword /> Change PIN
                </h3>
                <p style={{ textAlign: "center" }}>
                    Please Enter Below Details To <br /> Chnage Pin
                </p>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    autoComplete={false}
                    autoCorrect={false}>
                    <div className={menuClasses["form-control"]}>
                        <input
                            placeholder="Enter Old Pin"
                            type={"password"}
                            {...register("OldPassword", { required: "true" })}
                        />
                        <CgPassword
                            className={menuClasses["lock-icon"]}
                            size={23}
                        />
                        {errors.Pin && <p>This is required!</p>}
                    </div>
                    <div className={menuClasses["form-control"]}>
                        <input
                            placeholder="Enter new Pin"
                            type={"password"}
                            {...register("new", { required: "true" })}
                        />
                        <CgPassword
                            className={menuClasses["lock-icon"]}
                            size={23}
                        />
                        {errors.new && <p>This is required!</p>}
                    </div>
                    <div className={menuClasses["form-control"]}>
                        <input
                            placeholder="confirm new Pin"
                            type={"password"}
                            {...register("confirm", {
                                required: "This is required!",
                                validate: (val) => {
                                    if (watch("password") != val) {
                                        return "Your passwords do no match";
                                    }
                                },
                            })}
                        />
                        <CgPassword
                            className={menuClasses["lock-icon"]}
                            size={23}
                        />
                        {errors.confirm && <p>{errors?.confirm?.message}</p>}
                    </div>
                    <button type="submit">Save</button>
                </form>
            </div>
        </Modal>
    );
}

export default Pay;
