import React from "react";
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/MoonLoader";
import utilitiesClasses from "./utilities.module.css";
function circleLoad(props) {
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
        z-index: 1000;
    `;
    return (
        <div className={`${props.bg ? utilitiesClasses["circleloader"] : ""}`}>
            <MoonLoader
                color={"black"}
                loading={true}
                css={override}
                size={20}
            />
        </div>
    );
}

export default circleLoad;
