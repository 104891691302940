//css Modules
import "./App.css";

// node packages
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Components
import HomePage from "./Components/HomePage/Index";
import ThanksContact from "./Components/thankyou/contactUs";
import Subscribe from "./Components/thankyou/Subscription";
import Login from "./Components/Login/login";
import Signup from "./Components/Login/signup";
import WalletTransfer from "./Components/walletTransfer/walletTransfer";
import LoadWallet from "./Components/loadWallet/loadWallet";
import Paybills from "./Components/Paybills/paybills";
import Transactions from "./Components/walletSummary/transactions";
import Referral from "./Components/Referral/Referral";
import Statement from "./Components/Statements/statement";
import Bbps from "./Components/Bbps/bbps";
import Dashboard from "./Components/Dashboard/dashboard";
import Privacy from "./Components/HomePage/terms/privacy";
import ProtectedRoutes from "./protected";
import Terms from "./Components/HomePage/terms/terms";
import Refundpolicy from "./Components/HomePage/terms/refundpolicy";
import PaymentLinks from "./Components/paymentLinks/payment";
import Kyc from "./Components/Kyc/kyc";
import SignupDetails from "./Components/Login/signupDetails";
import Quickpayment from "./Components/Bbps/quickpayment";
import ComplaintRegistration from "./Components/Bbps/Complaint/ComplaintSuccess";
import QuickPayDetails from "./Components/Bbps/quickpayDetails";
import Thankyou from "./Components/HomePage/thankyou/contactUs";
import Pay from "./Paywithoutsignup/pay";
import Gst from "./Components/Gst/gst";
import Hompage2 from './Components/Homepage2'
// import PayoutCards from './Components/PayoutCards/payout'
function App() {
    return (
        <>
            <ToastContainer />
            <Routes>
                <Route exact path="/" element={<Hompage2 />} />
                <Route
                    exact
                    path="/contact-submit"
                    element={<ThanksContact />}
                />
                <Route exact path="/Subscribe" element={<Subscribe />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/signup" element={<Signup />} />
                <Route exact path="/Thankyou" element={<Thankyou />} />

                <Route exact path="/Privacy" element={<Privacy />} />
                <Route exact path="/terms" element={<Terms />} />
                <Route exact path="/refund-policy" element={<Refundpolicy />} />
                <Route
                    exact
                    path="/signupDetails"
                    element={<SignupDetails />}
                />
                {/* protected Routes */}
                <Route element={<ProtectedRoutes />}>
                    {/* <Route
                        exact
                        path="/walletTransfer"
                        element={<WalletTransfer />}
                    /> */}
                    {/*

                      <Route
                      exact
                      path="/PayoutCard"
                      element={<PayoutCards />}
                      />
                    */}
                    <Route exact path="/loadWallet" element={<LoadWallet />} />
                    <Route exact path="/vendor-payments" element={<Paybills />} />
                    <Route
                        exact
                        path="/transactions"
                        element={<Transactions />}
                    />
                    <Route exact path="/referralUsers" element={<Referral />} />
                    <Route exact path="/statement" element={<Statement />} />
                    <Route exact path="/Bbps" element={<Bbps />} />
                    <Route exact path="/dashboard" element={<Dashboard />} />
                    <Route
                        exact
                        path="/paymentLinks"
                        element={<PaymentLinks />}
                    />
                    <Route exact path="/kyc" element={<Kyc />} />
                    <Route
                        exact
                        path="/Quickpayment"
                        element={<Quickpayment />}
                    />
                    <Route
                        exact
                        path="/ComplaintRegistration"
                        element={<ComplaintRegistration />}
                    />
                    <Route
                        exact
                        path="/bill-payments/details"
                        element={<QuickPayDetails />}
                    />
                    <Route
                        exact
                        path="/gst-payment"
                        element={<Gst />}
                    />

                </Route>
                <Route exact path="/pay/:id" element={<Pay />} />
            </Routes>
        </>
    );
}

export default App;
