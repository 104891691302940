import create from "zustand";

const initialState = {
    showNavbar: true,
    userData: {},
    Token: "",
    RazorPayOrder: "",
};
const useStore = create((set) => ({
    ...initialState,
    setshowNavbar: (auth) =>
        set((state) => ({ ...state, showNavbar: !state.showNavbar })),

    setUserData: (data) => set((state) => ({ ...state, userData: data })),
    setToken: (data) => set((state) => ({ ...state, Token: data })),
    setRazroPay: (data) => set((state) => ({ ...state, RazorPayOrder: data })),
}));
export default useStore;
