import React from "react";
import Layout from "../../Utils/layout/layout";
import CommingSoon from "../../Assets/coming_soon.png";
import GstClasses from "./gst.module.css";
import Soon from "../../Assets/soon.jpeg";
function Gst() {
    return (
        <Layout Active={"gst"}>
            <div className={GstClasses["Container"]}>
                <img src={CommingSoon} alt="coming soon" />
            </div>
        </Layout>
    );
}

export default Gst;
