import React, { useState, useEffect } from "react";
import Layout from "../../Utils/layout/layout";
import { useForm } from "react-hook-form";
import walletclasses from "./loadWallet.module.css";
import Wallet from "../../Assets/dashboard/wallet.png";
import { Services } from "../../Services";
import useStore from "../../Store";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import CircleLoad from "../../Utils/loader";
function Deposit() {
    // eslint-disable-next-line
    const Navigate = useNavigate();
    const setRazorPay = useStore((state) => state.setRazroPay);
    const userData = useStore((state) => state.userData);
    const Token = useStore((state) => state.Token);
    const token = { Authorization: `token ${Token}` };
    const [load, setload] = useState(false);
    const [ipaddress, setIpAddress] = useState();
    const [userPercentage, setUserPercentage] = useState(0);
    const [checkBox, setCheckBox] = useState(false);
    useEffect(() => {
        fetch("https://api.ipify.org/?format=json")
            .then((res) => res.json())
            .then((res) => {
                setIpAddress(res.ip);
            })
            .catch((err) => {
                console.log(err);
            });
        Services.walletcommissionPercentage("GET", null, token, userData.userId)
            .then((res) => {
                console.log(res);
                if (res.Status === 1) {
                    setUserPercentage(res.WalletCommissionPercentage);
                } else {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try again");
            });

        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const checkboxchange = (e) => {
        setCheckBox(e.target.checked);
    };

    const onSubmit = (data) => {
        let formData = {
            amount: Number(data.Amount),
            ipAddress: ipaddress,
            paymentgateway: "razorpay",
        };
        setload(true);
        Services.RazorPayOrder(
            "POST",
            JSON.stringify(formData),
            token,
            userData.userId
        )
            .then((res) => {
                console.log(res);
                if (res.Status === 1) {
                    setload(false);
                    const options = {
                        key: process.env.REACT_APP_RAZOR_PAY_KEY,
                        client_id: process.env.REACT_APP_RAZOR_PAY_CLIENT_ID,
                        client_secret:
                            process.env.REACT_APP_RAZOR_PAY_CLIENT_SECRET,
                        grant_type: process.env.REACT_APP_RAZOR_PAY_GRANT_TYPE,
                        mode: "test",
                        amount: data.Amount * 100, //  = INR 1
                        name: "EaseMyBill",
                        description: "Load Wallet",
                        order_id: res.orderId,
                        // image: 'https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png',
                        handler: function (response) {
                            console.log(response);
                            const payload = {
                                receiptId: res.receiptId,
                                description: "",
                                razorpay_payment_id:
                                    response.razorpay_payment_id,
                                razorpay_signature: response.razorpay_signature,
                                amount: Number(data.Amount),
                            };

                            Services.createRazorPayment(
                                "POST",
                                JSON.stringify(payload),
                                token
                            )
                                .then((item) => {
                                    console.log(item);
                                    if (item.Status == 1) {
                                        Navigate("/dashboard");
                                        alert(item.Message);
                                    } else {
                                        alert(item?.Message);
                                    }
                                })
                                .catch((e) => {
                                    console.log(e);
                                });
                            // alert(response.razorpay_payment_id);
                        },
                        prefill: {
                            name: `${userData?.firstName}`,
                            contact: userData?.mobileNumber,
                            email: userData?.emailId,
                        },
                        notes: {
                            address: "some address",
                        },
                        theme: {
                            color: "#6865F0",
                            hide_topbar: false,
                        },
                    };

                    var rzp1 = new window.Razorpay(options);
                    rzp1.open();
                } else {
                    setload(false);
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                setload(false);
                console.log(err);
            });
    };

    return (
        <Layout Active={"loadWallet"}>
            {load && <CircleLoad bg={true} />}
            <div className={walletclasses["Container"]}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <h3>Top up Your wallet</h3>
                    <div className={walletclasses["form-control"]}>
                        <input
                            className={
                                errors.Amount ? walletclasses["error"] : ""
                            }
                            type="number"
                            placeholder="Enter Topup Amount"
                            {...register("Amount", { required: true })}
                        />
                        {errors.Amount && <span>This field is required</span>}
                    </div>
                    <div className={walletclasses["form-control-radio"]}>
                        <input
                            type="radio"
                            id="html"
                            name="razorPay"
                            value="RazorPay"
                            defaultChecked
                        />
                         {" "}
                        <label for="html">
                            Normal /Business /Corporate({userPercentage}%)
                        </label>
                    </div>
                    <div className={walletclasses["form-control-checkbox"]}>
                        <input
                            type="checkbox"
                            id="fee"
                            name="fee"
                            value="fee"
                            onChange={checkboxchange}
                        />
                        <label for="fee">
                            {" "}
                            {userPercentage}% will be deducted as a processing
                            Fee.
                        </label>
                    </div>

                    <button type="submit" disabled={!checkBox}>
                        Add Top up
                    </button>
                </form>
            </div>
        </Layout>
    );
}

export default Deposit;
