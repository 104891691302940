import { Navigate, Outlet, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import useStore from "./Store";
const Useauth = () => {
    const token = localStorage.getItem("Token");
    console.log(token !== null);
    return token !== null;
};

const ProtectedRoutes = () => {
    const setToken = useStore((state) => state.setToken);
    const setUserData = useStore((state) => state.setUserData);
    const isAuth = Useauth();
    if (isAuth == true) {
        setToken(localStorage.getItem("Token"));
        setUserData(JSON.parse(localStorage.getItem("userDetails")));
    }
    return isAuth ? <Outlet /> : <Navigate to="/" />;
    // return true ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoutes;
