""; //node packages
import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import useStore from "../../Store";
//css modules
import loginClasses from "./login.module.css";

//Assets
import logo from "../../Assets/Homepage/logo.png";
function Login() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const Navigate = useNavigate();
    const setToken = useStore((state) => state.setToken);
    const setUserData = useStore((state) => state.setUserData);
    const [showOtp, setShowOtp] = useState(false);
    const [otp, setOtp] = useState();
    const [error, setError] = useState(null);
    const [firstTime, setFirstTime] = useState(true);
    const [counter, setcounter] = useState(60);
    const [mobileNumber, setMobileNumber] = useState("");
    const [mobileError, setmobileError] = useState(false);
    const [resendenabler, setResendEnabler] = useState(true);
    const mobileChange = (e) => {
        setError(null);
        setmobileError(false);
        if (e.target.value.length <= 10) {
            setMobileNumber(e.target.value);
        }
    };
    const Useauth = () => {
        const token = localStorage.getItem("Token");

        return token !== null;
    };
    useEffect(() => {
        const isAuth = Useauth();
        if (isAuth == true) {
            setToken(localStorage.getItem("Token"));
            setUserData(JSON.parse(localStorage.getItem("userDetails")));
            Navigate("/dashboard", { replace: true });
        }
    }, []);
    const sendOtp = () => {
        setResendEnabler(true);
        Services.login("POST", JSON.stringify({ mobileNumber: mobileNumber }))
            .then((Response) => {
                if (Response.Status === 1) {
                    const mytimer = setInterval(() => {
                        setcounter((prev) => prev - 1);
                    }, 1000);
                    setTimeout(() => {
                        setcounter(60);
                        setResendEnabler(() => {
                            clearInterval(mytimer);
                            return false;
                        });
                    }, 60000);
                    setShowOtp(true);
                    setFirstTime(false);
                } else if (Response.Status === 0) {
                    toast.error(`${Response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const onSubmit = (data) => {
        if (mobileNumber.length != 10) {
            setmobileError(true);
            setError("please enter a valid Number");
            return;
        }
        if (firstTime) {
            sendOtp();
        } else {
            if (otp.length < 4) {
                setError("invalid OTP");
                return;
            }
            Services.verifyloginOtp(
                "POST",
                JSON.stringify({ mobileNumber: mobileNumber, OTP: otp })
            )
                .then((Response) => {
                    if (Response.Status === 1) {
                        localStorage.setItem("Token", Response.Token);
                        localStorage.setItem(
                            "userDetails",
                            JSON.stringify(Response.userDetails)
                        );
                        Navigate("/dashboard", { replace: true });
                    } else if (Response.Status === 0) {
                        toast.error(`${Response.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    const otpChange = (otp) => {
        setError(null);
        setOtp(otp);
    };

    return (
        <div className={loginClasses["Container"]}>
            <NavLink to={"/"}>
                <img src={logo} alt="logo" />
            </NavLink>

            <div className={loginClasses["login-form"]}>
                <h3>Login</h3>
                <p>enter your details to login to your account</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={loginClasses["form-control"]}>
                        <input
                            disabled={showOtp}
                            type="number"
                            className={mobileError ? loginClasses["error"] : ""}
                            onChange={mobileChange}
                            value={mobileNumber}
                            placeholder="Enter Mobile  number"
                        />
                    </div>

                    {showOtp && (
                        <div className={loginClasses["otp-wrapper"]}>
                            <label htmlFor="Otp">Enter OTP</label>
                            <OtpInput
                                containerStyle={loginClasses["container-otp"]}
                                inputStyle={loginClasses["otp"]}
                                value={otp}
                                onChange={otpChange}
                                numInputs={4}
                                isInputSecure={true}
                                shouldAutoFocus={true}
                                separator={<span> </span>}
                            />
                        </div>
                    )}
                    {showOtp && (
                        <button
                            className={loginClasses["sendotp"]}
                            disabled={resendenabler}
                            onClick={sendOtp}
                            type="button">
                            Resend OTP{" "}
                            {resendenabler == true ? `(${counter})` : ""}
                        </button>
                    )}
                    {error && (
                        <span className={loginClasses["error-text"]}>
                            {error}
                        </span>
                    )}
                    <button type="submit">Sign in</button>
                </form>
                <p>
                    don’t have an account?{" "}
                    <NavLink to={"/signup"}>Signup</NavLink>
                </p>
            </div>
        </div>
    );
}

export default Login;
