import React, { useState } from "react";
import classes from "./Bbps.module.css";
import Layout from "../../Utils/layout/layout";
import { payBills } from "./Assets";
import QuickPay from "./QuickPay";
import Complaint from "./Complaint/Complaint";
import Transactions from "./transactions";
import BillPayment from "../../Assets/dashboard/billpayment.png";
import PayBills from "./PayBills";

function Bbps() {
    const [active, setActive] = useState("paybills");

    const activeHandler = (e) => {
        let button = e.target.value;
        setActive(button);
    };
    return (
        <Layout Active={"Bbps"}>
            <div className={classes.Container}>
                <div className={classes["main-header"]}>
                    <h3>Bill payments</h3>
                    <img src={BillPayment} alt="billpayment" />
                </div>
                <div className={classes["header"]}>
                    <button
                        value={"paybills"}
                        onClick={activeHandler}
                        className={
                            active === "paybills"
                                ? classes["active"]
                                : classes["inactive"]
                        }>
                        Pay Bills
                    </button>
                    <button
                        value={"quickPay"}
                        onClick={activeHandler}
                        className={
                            active === "quickPay"
                                ? classes["active"]
                                : classes["inactive"]
                        }>
                        Quick Pays
                    </button>

                    <button
                        className={
                            active === "complaint"
                                ? classes["active"]
                                : classes["inactive"]
                        }
                        onClick={activeHandler}
                        value="complaint">
                        Complaints
                    </button>

                    <button
                        value={"transactions"}
                        onClick={activeHandler}
                        className={
                            active === "transactions"
                                ? classes["active"]
                                : classes["inactive"]
                        }>
                        Bill Payment Transactions
                    </button>
                </div>
                <div className={classes["wrap"]}>
                    {active === "paybills" && <PayBills />}
                    {active === "quickPay" && <QuickPay />}
                    {active === "complaint" && <Complaint />}
                    {active === "transactions" && <Transactions />}
                </div>
            </div>
        </Layout>
    );
}

export default Bbps;
