import React, { useLayoutEffect } from "react";
import Navbar from "../Navbar/navbar";
import layoutclasses from "./layout.module.css";
import Topnav from "../topnav/topnav";
import useStore from "../../Store";
import { useNavigate } from "react-router";
function Layout({ children, Active }) {
    const showNav = useStore((state) => state.showNavbar);
    const setShowNav = useStore((State) => State.setshowNavbar);

    return (
        <div className={layoutclasses["Container"]}>
            <Navbar Active={Active} setShow={setShowNav} show={showNav} />
            <div
                className={`${layoutclasses["right-side"]} ${
                    !showNav ? layoutclasses["nav-hide"] : ""
                }`}>
                <Topnav />
                <div style={{ zIndex: "2" }}>{children}</div>
            </div>
        </div>
    );
}

export default Layout;
