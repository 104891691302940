import React, { useState, useEffect, useRef } from "react";
import statementClasses from "./statement.module.css";
import Layout from "../../Utils/layout/layout";
import { BsSearch } from "react-icons/bs";
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import useStore from "../../Store";
import { loginServices } from "../../Services/loginServices";
import ReactPaginate from "react-paginate";
import Moment from "react-moment";
import Modal from "./transactionModal/modal";
import { Services } from "../../Services";
function Statements() {
    // eslint-disable-next-line
    const fromRef = useRef();
    const toRef = useRef();
    let [loading, setLoading] = useState(true);
    const userData = useStore((state) => state.userData);
    const Token = useStore((state) => state.Token);
    const token = { Authorization: `token ${Token}` };
    const [statements, setStatements] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [pagsize, setpagesize] = useState(1);
    const [dateFilter, setDateFilter] = useState(false);
    const [transactionId, setTransactionId] = useState("");
    const [trnasactionModal, setTransactionModal] = useState(false);
    // spinner css
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;

    const getStatements = (offset) => {
        Services.getPayoutstatementByuserId(
            "POST",
            null,
            token,
            userData?.userId,
            offset
        )
            .then((ApiData) => {
                console.log(ApiData);
                if (ApiData?.Status === 1) {
                    setLoading(false);
                    setStatements(ApiData.PayoutTransactions);
                    setpagesize(ApiData?.PageSize);
                    setPageCount(
                        Math.ceil(
                            Number(ApiData?.TotalRows) /
                                Number(ApiData?.PageSize)
                        )
                    );
                } else if (ApiData?.Status === 0) {
                    alert(ApiData?.Message);
                }
            })
            .catch((err) => {
                console.log(err);
                alert("something went wrong ");
            });
    };
    useEffect(() => {
        getStatements(0);
        // eslint-disable-next-line
    }, []);

    const fetchStatementsByDate = (offset) => {
        setLoading(true);
        const fromDate = fromRef.current.value;
        const toDate = toRef.current.value;
        if (new Date(fromDate) > new Date(toDate)) {
            alert("Start date is not more than end date");
            return;
        }
        const payload = {
            fromDate: fromDate,
            toDate: toDate,
        };

        Services.getPayoutstatementByuserId(
            "POST",
            JSON.stringify(payload),
            token,
            userData?.userId,
            offset
        )
            .then((response) => {
                if (response.data.Status === 1) {
                    setStatements(response?.WalletTransactions);
                    setDateFilter(true);
                    setpagesize(response?.PageSize);
                    setPageCount(
                        Math.ceil(
                            Number(response?.TotalRows) /
                                Number(response?.PageSize)
                        )
                    );
                    setLoading(false);
                } else {
                    alert("something went wrong please try again");
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };
    const submitHandler = (e) => {
        e.preventDefault();
        if (
            fromRef.current.value?.length > 0 &&
            toRef.current.value?.length > 0
        ) {
            fetchStatementsByDate(0);
        } else if (fromRef.current.value?.length === 0) {
            alert("From Date is required");
        } else if (toRef.current.value?.length === 0) {
            alert("To Date is required");
        }
    };
    const handlePageChange = (selectedObject) => {
        if (dateFilter) {
            fetchStatementsByDate(selectedObject.selected * pagsize);
            return;
        }
        getStatements(selectedObject.selected * pagsize);
    };
    const transactionHandler = (e) => {
        setTransactionModal(true);
        setTransactionId(e.currentTarget.getAttribute("id"));
    };
    return (
        <Layout Active={"statement"}>
            {trnasactionModal && (
                <Modal
                    transactionId={transactionId}
                    modal={trnasactionModal}
                    setmodal={setTransactionModal}
                />
            )}
            <div className={statementClasses["Container"]}>
                {/* <div className={statementClasses["search"]}>
                    <input
                        placeholder="Search with Mobile number,Bank Account Number"
                        // ref={mobileRef}
                        type="number"
                    />
                    <BsSearch
                        color="grey"
                        className={statementClasses["icon"]}
                    />
                </div> */}
                <div className={statementClasses["Date"]}>
                    <form onSubmit={submitHandler}>
                        <input type={"date"} ref={fromRef} />
                        <input
                            type={"date"}
                            placeholder="MM/DD/YYYY"
                            ref={toRef}
                        />
                        <button type="submit">Show Results</button>
                    </form>
                </div>
                <div className={statementClasses["referral"]}>
                    {loading ? (
                        <div className={statementClasses["loader"]}>
                            <ScaleLoader
                                color={"black"}
                                loading={true}
                                css={override}
                                size={150}
                            />
                        </div>
                    ) : (
                        <div
                            className={statementClasses["tabel-wrap"]}
                            style={{ overflow: "auto" }}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Date & Time</th>
                                        <th>Order ID</th>
                                        <th>Payout ID</th>
                                        <th>Contact No</th>
                                        <th>Payee Name</th>
                                        <th>Acc No</th>
                                        <th>IFSC</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {statements.length ? (
                                        statements.map((item, index) => {
                                            return (
                                                <tr
                                                    onClick={transactionHandler}
                                                    id={
                                                        item.wallet_transactionId
                                                    }>
                                                    <td>
                                                        <Moment
                                                            format="DD MMM YYYY hh:mm A"
                                                            subtract={{
                                                                hours: 5,
                                                                minutes: 30,
                                                            }}>
                                                            {
                                                                item.transactionDateTime
                                                            }
                                                        </Moment>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            {
                                                                item.wallet_transactionId
                                                            }
                                                        </p>
                                                    </td>
                                                    <td>
                                                        {
                                                            item.razorpay_TransactionId
                                                        }
                                                    </td>
                                                    <td>
                                                        {item?.mobileNumber}
                                                    </td>
                                                    <td>
                                                        {item?.bankAccountName}
                                                    </td>
                                                    <td>
                                                        {item.bankAccountNumber}
                                                    </td>
                                                    <td>
                                                        {item?.bankIFSCCode}
                                                    </td>
                                                    <td>₹ {item?.amount}</td>
                                                    <td>
                                                        {item?.transactionStatus ===
                                                            2 && (
                                                            <span>
                                                                {" "}
                                                                Pending
                                                            </span>
                                                        )}
                                                        {item?.transactionStatus ===
                                                            1 && (
                                                            <span>
                                                                {" "}
                                                                Processed
                                                            </span>
                                                        )}
                                                        {item?.transactionStatus ===
                                                            3 && (
                                                            <span> Failed</span>
                                                        )}
                                                        {item?.transactionStatus ===
                                                            4 && (
                                                            <span>
                                                                {" "}
                                                                Reversed
                                                            </span>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr
                                            className={
                                                statementClasses["box-shadow"]
                                            }>
                                            <td colSpan={8}>
                                                No Transactions found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            {statements?.length > 0 && (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginTop: "1rem",
                                    }}>
                                    <ReactPaginate
                                        // pageCount={pageCount}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        onPageChange={handlePageChange}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        containerClassName={"pagination"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Statements;
