import TransactionClasses from "./Bbps.module.css";
import React, { useState, useRef, useEffect } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/react";
import { RiDownloadLine } from "react-icons/ri";
import useStore from "../../Store";
import { Services } from "../../Services";
import ReactPaginate from "react-paginate";
import Moment from "react-moment";
function Transactions() {
    // eslint-disable-next-line
    let [loading, setLoading] = useState(false);

    const fromRef = useRef();
    const toRef = useRef();
    const userData = useStore((state) => state.userData);
    const token = useStore((state) => state.Token);
    const [pageCount, setPageCount] = useState(1);
    const [pagsize, setpagesize] = useState(1);
    const [dateFilter, setDateFilter] = useState(false);
    const [walletTransactionFilter, setWalletTransactionsfilter] = useState([]);
    // spinner css
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    // Date Filter Form Submit

    // const walletTransactions = () => {
    //     const fromDate = fromRef.current.value;
    //     const toDate = toRef.current.value;

    //     if (new Date(fromDate) > new Date(toDate)) {
    //         alert("Start date is not more than end date");
    //         return;
    //     }
    //     let payload = {
    //         fromDate: fromDate,
    //         toDate: toDate,
    //     };

    //     let config = { Authorization: `token ${token}` };
    //     Services.walletTransactionsDownload(
    //         "POST",
    //         JSON.stringify(payload),
    //         config,
    //         userData?.userId
    //     )
    //         .then((res) => {
    //             console.log(res);
    //             if (res.Status === 1) {
    //                 fetch(res.FileURL).then((response) => {
    //                     response.blob().then((blob) => {
    //                         // Creating new object of PDF file
    //                         const fileURL = window.URL.createObjectURL(blob);
    //                         // Setting various property values
    //                         let alink = document.createElement("a");
    //                         alink.href = fileURL;
    //                         alink.download = `$transactions.csv`;
    //                         alink.click();
    //                     });
    //                 });
    //             } else {
    //                 alert(res.Message);
    //             }
    //         })
    //         .catch((e) => {
    //             console.log(e);
    //         });
    // };
    const getTransactions = (offset) => {
        setLoading(true);
        let config = { Authorization: `token ${token}` };
        Services.getBPBSTransactions(
            "POST",
            JSON.stringify({ Offset: offset }),
            config,
            userData?.userId
        )
            .then((ApiData) => {
                console.log(ApiData);
                if (ApiData?.Status === 1) {
                    setLoading(false);
                    setWalletTransactionsfilter(ApiData.BBPSTransactions);
                    setpagesize(ApiData?.PageSize);
                    setPageCount(
                        Math.ceil(
                            Number(ApiData?.TotalRows) /
                                Number(ApiData?.PageSize)
                        )
                    );
                } else if (ApiData?.Status === 0) {
                    alert(ApiData?.Message);
                }
            })
            .catch((err) => {
                console.log(err);
                alert("something went wrong ");
            });
    };
    useEffect(() => {
        getTransactions(0);
        // eslint-disable-next-line
    }, []);

    const fetchStatementsByDate = (offset) => {
        setLoading(true);
        const fromDate = fromRef.current.value;
        const toDate = toRef.current.value;
        if (new Date(fromDate) > new Date(toDate)) {
            alert("Start date is not more than end date");
            return;
        }
        let payload = {
            fromDate: fromDate,
            toDate: toDate,
            Offset: offset,
        };

        let config = { Authorization: `token ${token}` };
        Services.getBPBSTransactions(
            "POST",
            JSON.stringify(payload),
            config,
            userData?.userId
        )
            .then((response) => {
                if (response.Status === 1) {
                    setWalletTransactionsfilter(response.BBPSTransactions);
                    setDateFilter(true);
                    setpagesize(response?.PageSize);
                    setPageCount(
                        Math.ceil(
                            Number(response?.TotalRows) /
                                Number(response?.PageSize)
                        )
                    );
                    setLoading(false);
                } else {
                    alert("something went wrong please try again");
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };
    const submitHandler = (e) => {
        e.preventDefault();
        if (
            fromRef.current.value?.length > 0 &&
            toRef.current.value?.length > 0
        ) {
            fetchStatementsByDate(0);
        } else if (fromRef.current.value?.length === 0) {
            alert("From Date is required");
        } else if (toRef.current.value?.length === 0) {
            alert("To Date is required");
        }
    };
    const handlePageChange = (selectedObject) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        if (dateFilter) {
            fetchStatementsByDate(selectedObject.selected * pagsize);
            return;
        }
        getTransactions(selectedObject.selected * pagsize);
    };

    return (
        <div className={TransactionClasses["Container"]}>
            <div className={TransactionClasses["filters-wrapper"]}>
                <div className={TransactionClasses["Date"]}>
                    <form onSubmit={submitHandler}>
                        <input type={"date"} ref={fromRef} />
                        <input
                            type={"date"}
                            placeholder="MM/DD/YYYY"
                            ref={toRef}
                        />
                        <button>Show Results</button>
                    </form>
                </div>
            </div>
            <div className={TransactionClasses["transactions"]}>
                <h3>Bill Payment Transactions</h3>

                <div
                    className={TransactionClasses["tabel-wrap"]}
                    style={{ overflow: "auto" }}>
                    <table>
                        <thead>
                            <tr>
                                <th>Biller Name</th>
                                <th>Amount</th>
                                <th>Transaction Id</th>
                                <th>Transaction Date</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        {loading ? (
                            <tbody>
                                <td colSpan={6} style={{ border: "none" }}>
                                    <div
                                        className={
                                            TransactionClasses["loader"]
                                        }>
                                        <ScaleLoader
                                            color={"black"}
                                            loading={true}
                                            css={override}
                                            size={150}
                                        />
                                    </div>
                                </td>
                            </tbody>
                        ) : (
                            <tbody>
                                {walletTransactionFilter.length ? (
                                    walletTransactionFilter.map(
                                        (item, index) => {
                                            return (
                                                <tr>
                                                    <td>{item.billerName}</td>
                                                    <td>{item.amount}</td>
                                                    <td>
                                                        {item.transactionId}
                                                    </td>
                                                    <td>
                                                        <Moment
                                                            format="DD MMM YYYY hh:mm A"
                                                            subtract={{
                                                                hours: 5,
                                                                minutes: 30,
                                                            }}>
                                                            ₹{" "}
                                                            {
                                                                item.transactionDateTime
                                                            }
                                                        </Moment>
                                                    </td>
                                                    <td>
                                                        {item.status === 1 ? (
                                                            <p
                                                                className={
                                                                    TransactionClasses[
                                                                        "success"
                                                                    ]
                                                                }>
                                                                Success
                                                            </p>
                                                        ) : (
                                                            <p
                                                                className={
                                                                    TransactionClasses[
                                                                        "Cancel"
                                                                    ]
                                                                }>
                                                                Cancelled
                                                            </p>
                                                        )}
                                                    </td>
                                                    {/* <td>
                                                        <button
                                                            className={
                                                                TransactionClasses[
                                                                    "download"
                                                                ]
                                                            }>
                                                            <RiDownloadLine />
                                                        </button>
                                                    </td> */}
                                                </tr>
                                            );
                                        }
                                    )
                                ) : (
                                    <tr
                                        className={
                                            TransactionClasses["box-shadow"]
                                        }>
                                        <td
                                            colSpan={5}
                                            style={{ textAlign: "center" }}>
                                            No Transactions found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        )}
                    </table>
                    {walletTransactionFilter?.length > 0 && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "1rem",
                            }}>
                            <ReactPaginate
                                // pageCount={pageCount}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                onPageChange={handlePageChange}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Transactions;
