import React, { useEffect, useState, useLayoutEffect } from "react";
import navbarClasses from "./navbar.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import swal from "sweetalert";
//Assets
import bbps from "../../Assets/navbar/bbps.png";
import deposit from "../../Assets/navbar/deposit.png";
import logout from "../../Assets/navbar/logout.png";
import paybills from "../../Assets/navbar/paybills.png";
import referral from "../../Assets/navbar/referral.png";
import statements from "../../Assets/navbar/statements.png";
import wallettransfer from "../../Assets/navbar/wallettransfer.png";
import dashboard from "../../Assets/navbar/dashboard.png";
import CreditCard from "../../Assets/navbar/creditCard.png";
import Logo from "../../Assets/Homepage/logo.png";
import { GiHamburgerMenu } from "react-icons/gi";
import "../../App.css";
import { IoMdWallet } from "react-icons/io";
import Gst from "../../Components/Gst/gst";
import Logout from "./Logout";
function Navbar({ Active, show, setShow }) {
    const [logoutModal, setlogoutModal] = useState(false);
    const Navigate = useNavigate();
    const clickhandler = () => {
        setShow((prev) => !prev);
    };
    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight,
    });
    const closeHnadler = () => {
        if (window.innerWidth <= 900 && show) {
            setShow(false);
        }
    };
    const setDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight,
        });
        closeHnadler();
    };

    useEffect(() => {
        window.addEventListener("resize", setDimension);

        return () => {
            window.removeEventListener("resize", setDimension);
        };
        // eslint-disable-next-line
    }, [screenSize]);
    const logoutHandler = () => {
        // swal({
        //     // title: "Are you Leaving?",
        //     text: "Are you sure you want to Log out?",
        //     className: navbarClasses["logout-popup"],
        //     icon: "warning",
        //     buttons: true,
        //     dangerMode: true,
        // }).then((willDelete) => {
        //     if (willDelete) {
        //         Navigate("/");
        //     } else {
        //         // swal("Your imaginary file is safe!");
        //     }
        // });
        swal("Are You sure you want to Sign out?", {
            icon: "warning",
            className: navbarClasses["logout-popup"],
            dangerMode: true,
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Logout",
                    value: "Logout",
                    className: navbarClasses["cancel"],
                },
                defeat: false,
            },
        }).then((value) => {
            switch (value) {
                case "Logout":
                    Navigate("/");
                    localStorage.clear();
                    break;

                // default:
                //     swal("Got away safely!");
            }
        });
    };
    return (
        <div>
            {logoutModal && (
                <Logout modal={logoutModal} setmodal={setlogoutModal} />
            )}
            <div
                className={`${show ? navbarClasses["backdrop"] : ""}`}
                onClick={() => {
                    setShow(false);
                }}></div>
            <div
                className={`${navbarClasses["Container"]} ${
                    !show ? navbarClasses["hide"] : ""
                }`}>
                <div className={navbarClasses["hamburger"]}>
                    <button
                        className={navbarClasses["hamburger-icon"]}
                        onClick={clickhandler}>
                        <GiHamburgerMenu size={22} />
                    </button>
                </div>
                {show && (
                    <img
                        src={Logo}
                        alt="logo"
                        onClick={() => {
                            window.open("/", "_blank", "noopener,noreferrer");
                        }}
                    />
                )}

                <div className={navbarClasses["navlinks"]}>
                    <NavLink
                        onClick={closeHnadler}
                        className={
                            Active === "dashboard"
                                ? navbarClasses["navActive"]
                                : navbarClasses["navInactive"]
                        }
                        to={"/dashboard"}>
                        <img src={dashboard} alt="icon" title="Dashboard" />
                        {show && <p>Dashboard</p>}
                    </NavLink>
                    {/* <NavLink
                        onClick={closeHnadler}
                        className={
                            Active === "loadWallet"
                                ? navbarClasses["navActive"]
                                : navbarClasses["navInactive"]
                        }
                        to={"/loadWallet"}>
                        <img src={deposit} alt="icon" title="loadWallet" />
                        {show && <p>Load Wallet</p>}
                    </NavLink> */}
                    {/* <NavLink
                        onClick={closeHnadler}
                        className={
                            Active === "PayoutCard"
                                ? navbarClasses["navActive"]
                                : navbarClasses["navInactive"]
                        }
                        to={"/PayoutCard"}>
                        <img src={CreditCard} alt="icon" title="Payout Cards" />
                        {show && <p>Payout Cards</p>}
                    </NavLink> */}
                    <NavLink
                        onClick={closeHnadler}
                        className={
                            Active === "paybills"
                                ? navbarClasses["navActive"]
                                : navbarClasses["navInactive"]
                        }
                        to={"/vendor-payments"}>
                        <img src={paybills} alt="icon" title="Pay Bills" />
                        {show && <p>Vendor Payments</p>}
                    </NavLink>
                    {/* <NavLink
                        onClick={closeHnadler}
                        className={
                            Active === "walletTransfer"
                                ? navbarClasses["navActive"]
                                : navbarClasses["navInactive"]
                        }
                        to={"/walletTransfer"}>
                        <img
                            src={wallettransfer}
                            alt="icon"
                            title="Wallet Transfer"
                        />
                        {show && <p>Wallet Transfer</p>}
                    </NavLink> */}
                    <NavLink
                        onClick={closeHnadler}
                        className={
                            Active === "gst"
                                ? navbarClasses["navActive"]
                                : navbarClasses["navInactive"]
                        }
                        to={"/gst-payment"}>
                        <img src={wallettransfer} alt="icon" title="gst" />
                        {show && <p>GST payments</p>}
                    </NavLink>

                    <NavLink
                        onClick={closeHnadler}
                        className={
                            Active === "Bbps"
                                ? navbarClasses["navActive"]
                                : navbarClasses["navInactive"]
                        }
                        to={"/Bbps"}>
                        <img src={bbps} alt="icon" title="BBPS" />
                        {show && <p>Bill Payment</p>}
                    </NavLink>
                    {/* <NavLink
                        onClick={closeHnadler}
                        className={
                            Active === "transactions"
                                ? navbarClasses["navActive"]
                                : navbarClasses["navInactive"]
                        }
                        to={"/transactions"}>
                        <IoMdWallet color="white" size={21} />
                        {show && <p>Wallet Summary</p>}
                    </NavLink> */}
                    <NavLink
                        onClick={closeHnadler}
                        className={
                            Active === "referral"
                                ? navbarClasses["navActive"]
                                : navbarClasses["navInactive"]
                        }
                        to={"/referralUsers"}>
                        <img src={referral} alt="icon" title="Referral Users" />
                        {show && <p>Refer and earn</p>}
                    </NavLink>

                    <NavLink
                        onClick={closeHnadler}
                        className={
                            Active === "statement"
                                ? navbarClasses["navActive"]
                                : navbarClasses["navInactive"]
                        }
                        to={"/statement"}>
                        <img src={statements} alt="icon" title="Statements" />
                        {show && <p>Payout Statements</p>}
                    </NavLink>
                    <NavLink
                        onClick={closeHnadler}
                        className={
                            Active === "paymentLinks"
                                ? navbarClasses["navActive"]
                                : navbarClasses["navInactive"]
                        }
                        to={"/paymentLinks"}>
                        <img
                            src={statements}
                            alt="icon"
                            title="Payment Links"
                        />
                        {show && <p>Payment Links</p>}
                    </NavLink>
                    <NavLink
                        onClick={closeHnadler}
                        className={
                            Active === "kyc"
                                ? navbarClasses["navActive"]
                                : navbarClasses["navInactive"]
                        }
                        to={"/kyc"}>
                        <img src={statements} alt="icon" title="Kyc" />
                        {show && <p>KYC</p>}
                    </NavLink>
                </div>
                <button
                    className={navbarClasses["logout"]}
                    onClick={() => setlogoutModal(true)}>
                    <img src={logout} alt="icon" title="Layout" />
                    {show && <p>Logout</p>}
                </button>
            </div>
        </div>
    );
}

export default Navbar;
