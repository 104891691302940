import React, { useState, useEffect } from "react";
import { loginServices } from "../Services/loginServices";
import { useForm } from "react-hook-form";
import PayClasses from "./pay.module.css";
import Logo from "../Assets/Homepage/logo.png";
import Loader from "../Utils/loader";
import { useParams } from "react-router-dom";
import { Services } from "../Services";
function Pay() {
    let { id } = useParams();
    const [userIpAddress, setUserIpAddress] = useState("");
    const [receiptId, setReceiptId] = useState("");
    useEffect(() => {
        fetch("https://api.ipify.org/?format=json")
            .then((Response) => {
                return Response.json();
            })
            .then((response) => {
                console.log(response);
                setUserIpAddress(response.ip);
            })
            .catch((err) => {
                console.log(err);
            });

        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
    }, [""]);

    const [loading, setloading] = useState(false);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const onSubmit = (data) => {
        const payload = {
            amount: data.amount,
            paymentPageId: id,
            name: data.name,
            mobileNumber: data.mobileNumber,
            emailId: data.emailId,
            notes: data.notes,
            ipAddress: userIpAddress,
        };
        setloading(true);
        Services.sendPaymentPageID("POST", JSON.stringify(payload), null)
            .then((response) => {
                console.log(response);
                if (response.Status == 1) {
                    localStorage.setItem("receiptId", response.receiptId);
                    setloading(false);
                    // options.amount = 500 * 100;
                    options.order_id = response.orderId;
                    options.prefill = {
                        contact: "+91" + data.mobileNumber,
                        email: data.emailId,
                    };

                    console.log(options);

                    var rzp1 = new window.Razorpay(options);
                    rzp1.open();
                } else {
                    setloading(false);
                    alert(response.Message);
                }
                console.log(response.data);
            })
            .catch((e) => {
                setloading(false);
                console.log(e);
            });
    };
    const options = {
        key: process.env.REACT_APP_RAZOR_PAY_KEY,
        client_id: process.env.REACT_APP_RAZOR_PAY_CLIENT_ID,
        client_secret: process.env.REACT_APP_RAZOR_PAY_CLIENT_SECRET,
        grant_type: process.env.REACT_APP_RAZOR_PAY_GRANT_TYPE,
        mode: "test",
        amount: 0, //  = INR 1
        name: "PayMark",
        description: "Pay",
        order_id: "",
        handler: function (response) {
            console.log(response);
            const payload = {
                receiptId: localStorage.getItem("receiptId"),
                description: "",
                paymentPageId: id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
                // amount: 500
            };
            setloading(true);
            Services.createRazorpayPaymentGlobal(
                "POST",
                JSON.stringify(payload),
                null
            )
                .then((item) => {
                    console.log(item);
                    if (item.Status == 1) {
                        setloading(false);
                        alert(item.Message);
                        window.location.reload();
                    } else {
                        setloading(false);
                        alert(item?.Message);
                    }
                })
                .catch((e) => {
                    setloading(false);
                    console.log(e);
                });
            // alert(response.razorpay_payment_id);
        },
        // prefill: {
        //     name: 'Gaurav',
        //     contact: '9999999999',
        //     email: 'demo@demo.com'
        // },
        notes: {
            address: "some address",
        },
        theme: {
            color: "#6865F0",
            hide_topbar: false,
        },
    };
    return (
        <>
            {loading && <Loader bg={true} />}
            <div className={PayClasses["Container"]}>
                <img src={Logo} alt="logo" />
                <h3>Make Payment</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={PayClasses["form-control"]}>
                        <label htmlFor="">Name</label>
                        <div>
                            <input
                                type="text"
                                placeholder="Enter name"
                                {...register("name", { required: true })}
                            />
                            {errors.name && <span>This field is required</span>}
                        </div>
                    </div>
                    <div className={PayClasses["form-control"]}>
                        <label htmlFor="">Amount</label>
                        <div>
                            <input
                                type="number"
                                placeholder="Enter Amount"
                                {...register("amount", { required: true })}
                            />
                            {errors.amount && (
                                <span>This field is required</span>
                            )}
                        </div>
                    </div>
                    <div className={PayClasses["form-control"]}>
                        <label htmlFor="">Email</label>
                        <div>
                            <input
                                type="text"
                                placeholder="Enter Email"
                                {...register("emailId", { required: true })}
                            />
                            {errors.emailId && (
                                <span>This field is required</span>
                            )}
                        </div>
                    </div>
                    <div className={PayClasses["form-control"]}>
                        <label htmlFor="">Mobile Number</label>
                        <div>
                            <input
                                type="text"
                                placeholder="Enter Mobile number"
                                {...register("mobileNumber", {
                                    required: true,
                                })}
                            />
                            {errors.mobileNumber && (
                                <span>This field is required</span>
                            )}
                        </div>
                    </div>
                    <div className={PayClasses["form-control"]}>
                        <label htmlFor="">Notes</label>
                        <div>
                            <input
                                type="text"
                                placeholder="Enter notes"
                                {...register("notes", { required: true })}
                            />
                            {errors.notes && (
                                <span>This field is required</span>
                            )}
                        </div>
                    </div>
                    <button>Pay</button>
                </form>
            </div>
        </>
    );
}

export default Pay;
