//node packages
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import useStore from "../../Store";

//css modules
import loginClasses from "./login.module.css";

//Assets
import logo from "../../Assets/Homepage/logo.png";
function Login() {
    const setToken = useStore((state) => state.setToken);
    const setUserData = useStore((state) => state.setUserData);
    const Navigate = useNavigate();
    const [show, setshow] = useState({ password: true, confirmPassword: true });
    const [counter, setcounter] = useState(60);
    const [resendenabler, setResendEnabler] = useState(true);
    const [showOtp, setShowOtp] = useState(false);
    const [otp, setOtp] = useState("");
    const [error, setError] = useState(false);
    const [firstTime, setFirstTime] = useState(true);
    const [mobileInput, setMobileInput] = useState("");
    const [mobileError, setMobileError] = useState(false);
    const [referencemobileInput, setreferenceMobileInput] = useState("");
    const [referencemobileError, setreferenceMobileError] = useState(false);
    const [email, setEmail] = useState("");

    const mobilechange = (e) => {
        if (e.target.value.length <= 10) {
            setMobileInput(e.target.value);
            setMobileError(false);
        }
    };
    const referencechage = (e) => {
        if (e.target.value.length <= 10) {
            setreferenceMobileInput(e.target.value);
            setreferenceMobileError(false);
        }
    };
    const emailChange = (e) => {
        setEmail(e.target.value);
    };
    const Useauth = () => {
        const token = localStorage.getItem("Token");

        return token !== null;
    };
    useEffect(() => {
        const isAuth = Useauth();
        if (isAuth == true) {
            setToken(localStorage.getItem("Token"));
            setUserData(JSON.parse(localStorage.getItem("userDetails")));
            Navigate("/dashboard", { replace: true });
        }
    }, []);
    const sendOtp = () => {
        setResendEnabler(true);
        Services.signup(
            "POST",
            JSON.stringify({
                mobileNumber: mobileInput,
                referralCode: referencemobileInput,
                emailId: email,
            })
        )
            .then((Response) => {
                if (Response.Status === 1) {
                    const mytimer = setInterval(() => {
                        setcounter((prev) => prev - 1);
                    }, 1000);
                    setTimeout(() => {
                        setcounter(60);
                        setResendEnabler(() => {
                            clearInterval(mytimer);
                            return false;
                        });
                    }, 60000);
                    setShowOtp(true);
                    setFirstTime(false);
                } else if (Response.Status === 0) {
                    toast.error(`${Response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const onSubmit = (e) => {
        e.preventDefault();
        if (mobileInput.length != 10) {
            setMobileError(true);
            return;
        }

        if (firstTime) {
            sendOtp();
        } else {
            if (otp.length < 4) {
                setError(true);
                return;
            }
            Services.signupVerifyOtp(
                "POST",
                JSON.stringify({
                    mobileNumber: mobileInput,
                    OTP: otp,
                })
            )
                .then((Response) => {
                    if (Response.Status === 1) {
                        localStorage.setItem(
                            "signupData",
                            JSON.stringify({
                                mobileNumber: mobileInput,
                                referralCode: referencemobileInput,
                                emailId: email,
                            })
                        );
                        Navigate("/signupDetails");
                    } else if (Response.Status === 0) {
                        toast.error(`${Response.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    const otpChange = (otp) => {
        setError(false);
        setOtp(otp);
    };

    return (
        <div
            className={`${loginClasses["Container"]} ${loginClasses["Container-signup"]}`}>
            <NavLink to={"/"}>
                <img src={logo} alt="logo" />
            </NavLink>
            <div className={loginClasses["login-form"]}>
                <h3>Signup</h3>
                <p>enter your details to signup to your account</p>

                <form onSubmit={onSubmit}>
                    <div className={loginClasses["form-control"]}>
                        <input
                            type="number"
                            name="mobileNumber"
                            className={mobileError ? loginClasses["error"] : ""}
                            placeholder="Enter mobile number"
                            onChange={mobilechange}
                            value={mobileInput}
                        />
                        {mobileError && <span>Enter a valid Number</span>}
                    </div>
                    <div className={loginClasses["form-control"]}>
                        <input
                            type={"email"}
                            name="emailId"
                            placeholder="Enter Email"
                            onChange={emailChange}
                            value={email}
                        />
                    </div>
                    <div className={loginClasses["form-control"]}>
                        <input
                            type={"text"}
                            className={
                                referencemobileError
                                    ? loginClasses["error"]
                                    : ""
                            }
                            placeholder="Enter Referral mobile number"
                            onChange={referencechage}
                            value={referencemobileInput}
                            name="referralCode"
                        />

                        {referencemobileError && (
                            <span>Enter a valid Number</span>
                        )}
                    </div>
                    {showOtp && (
                        <div className={loginClasses["otp-wrapper"]}>
                            <label htmlFor="Otp">Enter OTP</label>
                            <OtpInput
                                containerStyle={loginClasses["container-otp"]}
                                inputStyle={loginClasses["otp"]}
                                value={otp}
                                onChange={otpChange}
                                numInputs={4}
                                isInputSecure={true}
                                shouldAutoFocus={true}
                                separator={<span> </span>}
                            />
                        </div>
                    )}
                    {showOtp && (
                        <button
                            className={loginClasses["sendotp"]}
                            disabled={resendenabler}
                            onClick={sendOtp}
                            type="button">
                            Resend OTP{" "}
                            {resendenabler == true ? `(${counter})` : ""}
                        </button>
                    )}
                    {error && (
                        <span className={loginClasses["error-text"]}>
                            Invalid OTP
                        </span>
                    )}
                    <button type="submit">Signup</button>
                </form>
                <p>
                    Already Member? <NavLink to={"/login"}>Signin</NavLink>
                </p>
            </div>
        </div>
    );
}

export default Login;
