import React, { useState, useEffect } from "react";
import Layout from "../../Utils/layout/layout";
import PaymentClasses from "./payment.module.css";
import { useForm } from "react-hook-form";
import { Services } from "../../Services";
import useStore from "../../Store";
import { toast } from "react-toastify";
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/react";
import Swal from "sweetalert2";
function Payment() {
    const userData = useStore((state) => state.userData);
    const Token = useStore((state) => state.Token);
    const token = { Authorization: `token ${Token}` };
    const [load, setload] = useState(false);
    // spinner css

    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const onSubmit = (data) => {
        setload(true);
        Services.paymentLink(
            "POST",
            JSON.stringify(data),
            token,
            userData.userId
        )
            .then((response) => {
                console.log(response);
                if (response.Status === 1) {
                    Swal.fire(
                        "",
                        "payment link request has successfully sent",
                        "success"
                    );
                    setload(false);
                } else {
                    setload(false);
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                setload(false);
                console.log(err);
            });
    };

    return (
        <>
            <Layout Active={"paymentLinks"}>
                <div className={PaymentClasses["Container"]}>
                    <h3>Payment Links</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={PaymentClasses["form-control"]}>
                            <input
                                className={
                                    errors.mobileNumber
                                        ? PaymentClasses["error"]
                                        : ""
                                }
                                type="number"
                                placeholder="Enter Mobile Number"
                                {...register("mobileNumber", {
                                    required: true,
                                })}
                            />
                            {errors.mobileNumber && (
                                <span>This field is required</span>
                            )}
                        </div>
                        <div className={PaymentClasses["form-control"]}>
                            <input
                                className={
                                    errors.amount ? PaymentClasses["error"] : ""
                                }
                                type="number"
                                placeholder="Enter Amount"
                                {...register("amount", { required: true })}
                            />
                            {errors.amount && (
                                <span>This field is required</span>
                            )}
                        </div>
                        {load ? (
                            <div className={PaymentClasses["loader"]}>
                                <BounceLoader
                                    color={"blue"}
                                    loading={true}
                                    css={override}
                                    size={60}
                                />
                            </div>
                        ) : (
                            <button>Send Request</button>
                        )}
                    </form>
                </div>
            </Layout>
        </>
    );
}

export default Payment;
