import React, { useRef, useEffect } from "react";
import Contact from "../../../Assets/Homepage/contact1.png";
import { NavLink } from "react-router-dom";
import ContactClasses from "./contact.module.css";
import party from "party-js";
function ContactUs() {
    const ref = useRef();
    useEffect(() => {
        party.confetti(ref.current);
    }, []);
    return (
        <div className={ContactClasses["Container"]}>
            <img src={Contact} alt="contact" ref={ref} />
            <h3>thank you for contacting us</h3>
            <p>we have received your message we will reach you out soon </p>
            <NavLink to={"/"}>Back to Home</NavLink>
        </div>
    );
}

export default ContactUs;
