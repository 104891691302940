import React, { useEffect } from "react";
import logo from "../../images/80 PX logo-01.png";
import googleplay from "../../images/google-play-store.svg";
import applestore from "../../images/apple-store.svg";
import Mobilemockup from "../../images/Mobilemockup.mp4";
import nd_image from "../../images/2nd-image-570 x 570-compressed.jpg";
import easytocustom from "../../images/Easy To Customize.png";
import userfriendly from "../../images/user friendly.png";
import freecredit from "../../images/Upto 50 days of Free Credit.png";
import rewards from "../../images/rewards and cashback.png";
import register from "../../images/register.png";
import bankaccount from "../../images/add bank accpunt.png";
import payment from "../../images/pay (1).png";
import addcard from "../../images/add credit card.png";
import internet from "../../images/internet.jpg";
import electric from "../../images/electricity.jpg";
import water from "../../images/water.jpg";
import cabletv from "../../images/cable TV.jpg";
import insurance from "../../images/Insurance.jpg";
import cards from "../../images/cards.jpg";
import education from "../../images/education.jpg";
import lpg from "../../images/LPG gas.jpg";
import phonesolid from "../../images/phone-solid.svg";
import whatsapp from "../../images/whatsapp-vector.svg";
import SimpleSlider from "./slider";
import useStore from "../../Store";
import "../../App.css";
import { NavLink } from "react-router-dom";

function App() {
    const setToken = useStore((state) => state.setToken);
    const setUserData = useStore((state) => state.setUserData);
    const Useauth = () => {
        const token = localStorage.getItem("Token");

        return token !== null;
    };
    useEffect(() => {
        let isAuth = Useauth();
        if (isAuth == true) {
            setToken(localStorage.getItem("Token"));
            setUserData(JSON.parse(localStorage.getItem("userDetails")));
        }
    }, []);
    return (
        <div>
            <section className="pt-0">
                <div className="container">
                    <div className="header">
                        <div className="container px-0">
                            <nav className="navbar navbar-expand-lg navbar-light navbar-lg-dark py-0">
                                <div className="container-fluid">
                                    <a className="navbar-brand" href="/">
                                        <img
                                            src={logo}
                                            style={{ width: "65%" }}
                                            alt=""
                                        />
                                    </a>
                                    <button
                                        className="navbar-toggler"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#navbarSupportedContent"
                                        aria-controls="navbarSupportedContent"
                                        aria-expanded="false"
                                        aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div
                                        className="collapse navbar-collapse"
                                        id="navbarSupportedContent">
                                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                            <li className="nav-item pe-2">
                                                <a
                                                    rel="noreferrer"
                                                    className="android nav-link mb-2 mb-lg-0 btn border-0 special"
                                                    target="_blank"
                                                    href="https://play.google.com/store/apps/details?id=com.app.easemybill">
                                                    Get it on Google Play
                                                </a>
                                            </li>
                                            <li className="nav-item pe-2">
                                                <a
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="ios nav-link mb-2 mb-lg-0 btn border-0 special"
                                                    href="https://apps.apple.com/in/app/easemybill/id6449734329">
                                                    Get it on App Store
                                                </a>
                                            </li>
                                            <li className="nav-item pe-2">
                                                {localStorage.getItem(
                                                    "Token"
                                                ) ? (
                                                    <NavLink
                                                        className="ios nav-link mb-2 mb-lg-0 btn border-0 special"
                                                        to="/dashboard">
                                                        Dashboard
                                                    </NavLink>
                                                ) : (
                                                    <NavLink
                                                        className="ios nav-link mb-2 mb-lg-0 btn border-0 special"
                                                        to="/login">
                                                        Login
                                                    </NavLink>
                                                )}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-lg-6 banner">
                            <h1 className="mb-2">
                                MAKE UTILITY AND EDUCATIONAL PAYMENTS
                            </h1>
                            <p className="lead mt-4 mb-4">
                                EasemyBill is a digital payment service provide
                                platforms where you can get instant funds for
                                your utilities bill payments
                            </p>
                            <div className="apps">
                                <a
                                    className="android"
                                    rel="noreferrer"
                                    target="_blank"
                                    href="https://play.google.com/store/apps/details?id=com.app.easemybill">
                                    <img
                                        className="my-1"
                                        src={googleplay}
                                        alt=""
                                    />
                                </a>

                                <a
                                    className="android"
                                    rel="noreferrer"
                                    target="_blank"
                                    href="https://apps.apple.com/in/app/easemybill/id6449734329">
                                    <img
                                        className="my-1"
                                        src={applestore}
                                        alt=""
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="d-md-block col-12 col-lg-6 bg-primary mt-5 mt-md-5 mt-lg-0">
                            <div className="video-3">
                                <video
                                    className="bcr"
                                    width="100%"
                                    height="auto"
                                    autoPlay
                                    playsInline
                                    muted
                                    loop>
                                    <source
                                        src={Mobilemockup}
                                        type="video/mp4"
                                    />
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="cardwrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <h1 className="abe">
                                ABOUT{" "}
                                <span className="accent-light">EASEMYBILL</span>
                            </h1>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <p>
                                Welcome to EasemyBills, the leading digital
                                payment service platform dedicated to
                                simplifying your utility bill payments. Our app
                                provides a seamless experience, offering instant
                                funds for all your bill payments. With
                                EasemyBills, enjoy the convenience of secure
                                transactions and timely payment solutions,
                                ensuring a hassle-free experience for all your
                                utility needs.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="faq" id="faq">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-6 text-center">
                            <img
                                loading="lazy"
                                className="screen"
                                src={nd_image}
                                alt=""
                            />
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 mt-5 mt-md-0">
                            <div className="aboutus">
                                <h1>
                                    WHY{" "}
                                    <span className="accent-light">
                                        EASEMYBILL
                                    </span>
                                </h1>
                                <p>
                                    Now Say goodbye to the stress of standing in
                                    long queues and late payments. Experience
                                    the future of bill payments with
                                    EasemyBills. Our innovative app provides
                                    instant funds for your utilities, giving you
                                    the freedom to pay your bills anytime,
                                    anywhere. Experience convenience, speed, and
                                    peace of mind with EasemyBills – the
                                    ultimate solution for your utility bill
                                    payments.
                                </p>
                                <div className="apps2">
                                    <button
                                        type="submit"
                                        className="btn btn-primary px-5"
                                        onClick={() => {
                                            window.open(
                                                "https://play.google.com/store/apps/details?id=com.app.easemybill",
                                                "_blank",
                                                "noreferrer"
                                            );
                                        }}>
                                        Download
                                    </button>
                                    {/* <button
                                        type="submit"
                                        className="btn btn-primary px-5">
                                        Learn More
                                    </button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="feature">
                <div className="container">
                    <div className="col-12 col-lg-12 mb-5 text-center px-0 px-lg-5">
                        <h2>
                            NEXT GENERATION{" "}
                            <span className="accent-light">
                                PAYMENT SOLUTION
                            </span>
                        </h2>
                    </div>
                    <div className="row">
                        <div className="col-12"></div>
                        <div className="col-12 col-lg-6 mb-5 d-flex align-items-center">
                            <aside>
                                <img
                                    height="60"
                                    loading="lazy"
                                    src={easytocustom}
                                    alt=""
                                />
                            </aside>
                            <article>
                                <h3>Customize</h3>
                                <p className="mb-0">
                                    Customize Your Payments, Your Way.
                                </p>
                            </article>
                        </div>
                        <div className="col-12 col-lg-6 mb-5 d-flex align-items-center">
                            <aside>
                                <img
                                    height="60"
                                    loading="lazy"
                                    src={userfriendly}
                                    alt=""
                                />
                            </aside>
                            <article>
                                <h3>User Friendly</h3>
                                <p className="mb-0">
                                    User-Friendly Payments at Your Fingertips.
                                </p>
                            </article>
                        </div>
                        <div className="col-12 col-lg-6 mb-5 d-flex align-items-center">
                            <aside>
                                <img
                                    height="60"
                                    loading="lazy"
                                    src={freecredit}
                                    alt=""
                                />
                            </aside>
                            <article>
                                <h3>50 Days Credit</h3>
                                <p className="mb-0">
                                    Enjoy 50 Days of Credit-Free Bill Payments,
                                    On Us!
                                </p>
                            </article>
                        </div>
                        <div className="col-12 col-lg-6 mb-5 d-flex align-items-center">
                            <aside>
                                <img
                                    height="60"
                                    loading="lazy"
                                    src={rewards}
                                    alt=""
                                />
                            </aside>
                            <article>
                                <h3>Rewards and Cashback</h3>
                                <p className="mb-0">
                                    Unlock Cashbacks and Exciting Benefits.
                                </p>
                            </article>
                        </div>
                    </div>
                </div>
            </section>
            <section className="getstarted" id="onboarding">
                <div className="container">
                    <div className="row">
                        <div className="col-12 mb-5 text-center">
                            <h2>
                                STEP UP YOUR{" "}
                                <span className="accent-light">
                                    BUSINESS PAYMENTS
                                </span>{" "}
                                GAME
                            </h2>
                        </div>
                    </div>
                    <div className="row icons">
                        <div className="col-md-3 px-md-1">
                            <aside>
                                <img
                                    src={register}
                                    className="forimg wow tpfadeUp rgimg"
                                    data-wow-delay=".3s"
                                    alt=""
                                />
                                <strong>Register on the app</strong>
                            </aside>
                        </div>
                        <div className="col-md-3 px-md-1">
                            <aside>
                                <img
                                    loading="lazy"
                                    src={addcard}
                                    className="forimg"
                                    alt=""
                                />
                                <strong>Add your Credit Card</strong>
                            </aside>
                        </div>
                        <div className="col-md-3 px-md-1">
                            <aside>
                                <img
                                    loading="lazy"
                                    src={bankaccount}
                                    className="forimg"
                                    alt=""
                                />
                                <strong>Add payee bank account</strong>
                            </aside>
                        </div>
                        <div className="col-md-3 px-md-1 active">
                            <aside>
                                <img
                                    loading="lazy"
                                    src={payment}
                                    className="forimg"
                                    alt=""
                                />
                                <strong>Pay!</strong>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <section className="cardwrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <h2>SERVICES WE PROVIDE</h2>
                        </div>
                    </div>
                    <div className="serviceprovid">
                        <div className="row">
                            <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-5 mb-md-0 kl">
                                <div className="card h-100">
                                    <img
                                        loading="lazy"
                                        src={internet}
                                        className="card-img-top"
                                        alt="Credit Card to make rental payments"
                                    />
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            Internet Payments
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-5 mb-md-0 kl">
                                <div className="card h-100">
                                    <img
                                        loading="lazy"
                                        src={electric}
                                        className="card-img-top"
                                        alt="Credit Card to make rental payments"
                                    />
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            Electricity Payments
                                        </h5>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-5 mb-md-0 kl">
                                <div className="card h-100">
                                    <img
                                        loading="lazy"
                                        src={water}
                                        className="card-img-top"
                                        alt="Credit Card to make rental payments"
                                    />
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            Water Payments
                                        </h5>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-5 mb-md-0 kl">
                                <div className="card h-100">
                                    <img
                                        loading="lazy"
                                        src={cabletv}
                                        className="card-img-top"
                                        alt="Credit Card to make rental payments"
                                    />
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            Cable TV Payments
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-5 mb-md-0 kl">
                                <div className="card h-100">
                                    <img
                                        loading="lazy"
                                        src={insurance}
                                        className="card-img-top"
                                        alt="Credit Card to make rental payments"
                                    />
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            Insurance Payments
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-5 mb-md-0 kl">
                                <div className="card h-100">
                                    <img
                                        loading="lazy"
                                        src={cards}
                                        className="card-img-top"
                                        alt="Credit Card to make rental payments"
                                    />
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            Cards Payments
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-5 mb-md-0 kl">
                                <div className="card h-100">
                                    <img
                                        loading="lazy"
                                        src={education}
                                        className="card-img-top"
                                        alt="Credit Card to make rental payments"
                                    />
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            Education Payments
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-5 mb-md-0 kl">
                                <div className="card h-100">
                                    <img
                                        loading="lazy"
                                        src={lpg}
                                        className="card-img-top"
                                        alt="Credit Card to make rental payments"
                                    />
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            LPG Gas Payments
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <SimpleSlider />

            <footer className="text-light">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-3 mb-2 mb-md-0">
                            <p className="p50">
                                <strong className="d-block mt-2">About</strong>
                                <br />
                                Easemybill is an indian leading platform for all
                                utility payments which provides real time
                                payments
                            </p>
                        </div>

                        <div className="col-12 col-md-3 mb-2 mb-md-0">
                            <p className="p50">
                                <strong className="d-block mt-2">
                                    Other Links
                                </strong>
                            </p>
                            <ul className="gridft">
                                <div>
                                    <li>
                                        <NavLink to="/terms">
                                            • Terms & Condition
                                        </NavLink>
                                    </li>
                                </div>
                                <div>
                                    <li>
                                        <NavLink to="/Privacy">
                                            • Privacy Policy
                                        </NavLink>
                                    </li>
                                </div>
                                <div>
                                    <li>
                                        <NavLink to="/refund-policy">
                                            • Refund Policy
                                        </NavLink>
                                    </li>
                                </div>
                            </ul>
                        </div>

                        <div className="col-12 col-md-3 mb-2 mb-md-0">
                            <p className="p50">
                                <strong className="d-block mt-2">
                                    Corporate Address
                                </strong>
                                <br />
                                1st floor, M square Building, Outer Ring Road
                                Bellandur Bangalore, Karnataka- 560103
                            </p>
                        </div>

                        <div className="col-12 col-md-3 mb-2 mb-md-0">
                            <p className="p50">
                                <strong className="d-block mt-2">
                                    Contact Us
                                </strong>
                            </p>

                            <div className="my-3">
                                <img className="w15" src={phonesolid} alt="" />{" "}
                                &nbsp;6360581523
                            </div>
                            {/* <ul className="list-unstyled mb-4">
                                <li>
                                    <a
                                        href="mailto:admin@ezycredits.com"
                                        rel="noopener">
                                        <img
                                            loading="lazy"
                                            src={emailcircle}
                                            alt="Connect us through email"
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a href="/" rel="noopener">
                                        <img
                                            loading="lazy"
                                            src={linkedin}
                                            alt="Connect on Linkedin"
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a href="/" rel="noopener">
                                        <img
                                            loading="lazy"
                                            src={facebook}
                                            alt="Follow on Facebook"
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a href="/" rel="noopener">
                                        <img
                                            loading="lazy"
                                            src={twitter}
                                            alt="Follow on Twitter"
                                        />
                                    </a>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                </div>

                <a href="/" rel="noopener" className="whatsapp">
                    <img loading="lazy" src={whatsapp} alt="" />
                </a>
            </footer>
        </div>
    );
}

export default App;
