import React, { useState, useEffect } from "react";
import Layout from "../../Utils/layout/layout";
import { BsSearch } from "react-icons/bs";
import PaybillsClasses from "./paybills.module.css";
import img from "../../Assets/dashboard/img.png";
import { RiDeleteBin6Line } from "react-icons/ri";
import { toast } from "react-toastify";
import { Services } from "../../Services";
// import swal from "sweetalert";
import Swal from "sweetalert2";
import BankContact from "./contacts/bank/bankContact";
import Pay from "./pay/pay";
import { GrFormClose } from "react-icons/gr";
import useStore from "../../Store";
import NoContacts from "../../Assets/noContacts.png";
function Paybills() {
    const [paybillModal, setPayBillModal] = useState(false);
    const userData = useStore((state) => state.userData);
    const Token = useStore((state) => state.Token);
    const [payoutContacts, setPayoutContacts] = useState([]);
    const [active, setActive] = useState("1");
    const [selectedContact, setSelectedContact] = useState("");
    const [payoutFilter, setPayoutFilter] = useState([]);
    const [searchString, setSearchString] = useState("");
    const token = { Authorization: `token ${Token}` };
    const getPayoutContacts = () => {
        Services.getPayoutContacts("GET", null, token, userData.userId)
            .then((response) => {
                if (response.Status === 1) {
                    console.log(response);
                    setPayoutContacts(response.Contacts);
                    setPayoutFilter(
                        response?.Contacts?.filter(
                            (item) => item.IsAdminApproved == 1
                        )
                    );
                } else {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        if (payoutContacts.length > 0 && active) {
            setPayoutFilter(
                payoutContacts.filter((item) => item.IsAdminApproved == active)
            );
        }
    }, [active]);
    useEffect(() => {
        let timeout;
        if (searchString.length > 0) {
            timeout = setTimeout(() => {
                searchData();
            }, 1000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [searchString]);
    useEffect(() => {
        getPayoutContacts();
    }, []);

    const [modal, setmodal] = useState(false);

    const contactHandler = (e) => {
        e.stopPropagation();
        setPayBillModal(true);
        let id = e.currentTarget.getAttribute("id");
        let [selected] = payoutContacts.filter(
            (item) => item.payout_contactId == id
        );
        console.log(selected);
        setSelectedContact(selected);
    };
    const deleteHandler = (e) => {
        let id = e.currentTarget.getAttribute("id");
        e.stopPropagation();
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete",
        }).then((result) => {
            if (result.isConfirmed) {
                Services.DeletePayoutContact(
                    "DELETE",
                    null,
                    token,
                    userData.userId,
                    id
                )
                    .then((response) => {
                        if (response.Status === 1) {
                            Swal.fire("Deleted Successfully", "", "success");
                            getPayoutContacts();
                        } else {
                            toast.error(`${Response.Message}`, {
                                position: "top-center",
                                autoClose: 1000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };
    const searchHandler = (e) => {
        setSearchString(e.target.value);
    };
    const searchData = () => {
        let search = { keyword: searchString };
        Services.searchContact(
            "POST",
            JSON.stringify(search),
            token,
            userData.userId
        )
            .then((response) => {
                if (response.Status === 1) {
                    console.log(response);
                    setPayoutContacts(response.Contacts);
                    setPayoutFilter(
                        response?.Contacts?.filter(
                            (item) => item.IsAdminApproved == active
                        )
                    );
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const deletesearchString = () => {
        setSearchString("");
        getPayoutContacts();
    };
    return (
        <>
            {modal && (
                <BankContact
                    modal={modal}
                    setmodal={setmodal}
                    getPayoutContacts={getPayoutContacts}
                />
            )}

            {paybillModal && (
                <Pay
                    modal={paybillModal}
                    setmodal={setPayBillModal}
                    selected={selectedContact}
                />
            )}
            <Layout Active={"paybills"}>
                <div className={PaybillsClasses["Container"]}>
                    <h3>Beneficiary List</h3>
                    <div className={PaybillsClasses["search-contact"]}>
                        <div className={PaybillsClasses["search"]}>
                            <input
                                placeholder="search with name, mobile number, acc number.."
                                onChange={searchHandler}
                                value={searchString}
                            />
                            <BsSearch
                                color="grey"
                                className={PaybillsClasses["icon"]}
                            />
                            <button
                                className={`${PaybillsClasses["close"]} ${
                                    searchString
                                        ? PaybillsClasses["show-close"]
                                        : ""
                                }`}
                                onClick={deletesearchString}>
                                <GrFormClose color="black" size={25} />
                            </button>
                        </div>
                        <button onClick={() => setmodal(true)}>
                            Add beneficiary
                        </button>
                    </div>
                    <div className={PaybillsClasses["nav-buttons"]}>
                        <button
                            onClick={() => setActive("1")}
                            className={
                                active == "1" ? PaybillsClasses["active"] : ""
                            }>
                            Verified
                        </button>
                        <button
                            onClick={() => setActive("0")}
                            className={
                                active == "0" ? PaybillsClasses["active"] : ""
                            }>
                            Unverified
                        </button>
                        <button
                            onClick={() => setActive("2")}
                            className={
                                active == "2" ? PaybillsClasses["active"] : ""
                            }>
                            Blocked
                        </button>
                    </div>
                    <div className={PaybillsClasses["Contacts"]}>
                        {payoutFilter.length > 0 &&
                            payoutFilter.map((item) => (
                                <div
                                    className={PaybillsClasses["contact"]}
                                    onClick={contactHandler}
                                    id={item.payout_contactId}>
                                    <button
                                        className={PaybillsClasses["del"]}
                                        onClick={deleteHandler}
                                        id={item.payout_contactId}>
                                        <RiDeleteBin6Line />
                                    </button>
                                    <div className={PaybillsClasses["name"]}>
                                        <img src={img} alt="profile" />
                                        <p>{item.name}</p>
                                    </div>
                                    <p>Mobile No: {item.mobileNumber}</p>
                                    <p>Account No :{item.bankAccountNumber}</p>
                                    <p>IFSC Code: {item.bankIFSCCode}</p>
                                </div>
                            ))}
                    </div>
                    {payoutFilter.length == 0 && (
                        <div className={PaybillsClasses["nocontacts"]}>
                            {" "}
                            <img src={NoContacts} alt="NoContacts" />
                        </div>
                    )}
                </div>
            </Layout>
        </>
    );
}

export default Paybills;
