import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import payClasses from "./pay.module.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import people from "../../../Assets/dashboard/img.png";
import { toast } from "react-toastify";
import { Services } from "../../../Services";
import useStore from "../../../Store";
function Pay(props) {
    const [userIpAddress, setUserIpAddress] = useState("");
    const [error, setError] = useState("");
    const Token = useStore((state) => state.Token);
    const token = { Authorization: `token ${Token}` };
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    useEffect(() => {
        fetch("https://api.ipify.org/?format=json")
            .then((Response) => {
                return Response.json();
            })
            .then((response) => {
                console.log(response);
                setUserIpAddress(response.ip);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    // console.log();
    const onSubmit = (data) => {
        console.log("res");
        const payload = {
            payout_contactId: props.selected.payout_contactId,
            amount: data.amount,
            notes: data.notes,
            ipAddress: userIpAddress,
            payoutPurpose: data.payoutPurpose,
        };
        Services.payToContact("POST", JSON.stringify(payload), token)
            .then((response) => {
                if (response.Status === 1) {
                    console.log(response);
                    props.setmodal(false);
                    toast.success(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <Modal
            open={props.modal}
            center
            onClose={() => {
                props.setmodal(false);
            }}>
            <div className={payClasses["form"]}>
                <img src={people} alt="people" />
                <h3>{props.selected.name}</h3>
                <p>mobile no: {props.selected.mobileNumber}</p>
                <p>Account no: {props.selected.bankAccountNumber}</p>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    autoComplete={false}
                    autoCorrect={false}>
                    <div className={payClasses["form-control"]}>
                        <input
                            placeholder="Enter Amount"
                            type={"number"}
                            {...register("amount", { required: "true" })}
                        />
                        {errors.amount && <p>This is required!</p>}
                    </div>
                    <div className={payClasses["form-control"]}>
                        <input
                            type={"text"}
                            placeholder="Enter Note"
                            {...register("notes", { required: "true" })}
                        />
                        {errors.notes && <p>This is required!</p>}
                    </div>
                    <div className={payClasses["form-control"]}>
                        <select
                            name=""
                            id=""
                            defaultValue=""
                            {...register("payoutPurpose", {
                                required: "true",
                            })}>
                            <option value="" disabled>
                                Payment Purpose
                            </option>
                            <option value="Education Fee">Education Fee</option>
                            <option value="Vendor Payment">
                                Vendor Payment
                            </option>
                            <option value="Housing Society">
                                Housing Society
                            </option>
                            <option value="Rental Payment">
                                Rental Payment
                            </option>
                            <option value="Credit Card bill payment">
                                Credit Card bill payment
                            </option>
                        </select>
                        {errors.payoutPurpose && <p>This is required!</p>}
                    </div>
                    {error && <p style={{ color: "red" }}>{error}</p>}
                    <button
                        type="submit"
                        disabled={
                            props.selected.IsAdminApproved == 0 ||
                            props.selected.IsAdminApproved == 2
                        }>
                        Transfer Amount
                    </button>
                </form>
            </div>
        </Modal>
    );
}

export default Pay;
