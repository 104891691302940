import React, { useState } from "react";
import KycClasses from "./kyc.module.css";
import Layout from "../../Utils/layout/layout";
import { useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import useStore from "../../Store";
import check from "../../Assets/dashboard/check.png";
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/react";
import { Services } from "../../Services";
import { toast } from "react-toastify";
function Kyc() {
    const userData = useStore((state) => state.userData);
    const setUserData = useStore((state) => state.setUserData);
    const [RequestID, setRequestID] = useState("");
    const Token = useStore((state) => state.Token);
    const token = { Authorization: `token ${Token}` };
    const [firstTime, setFirstTime] = useState(true);
    const [error, setError] = useState(null);
    const [showOtp, setShowOtp] = useState(false);
    const [otp, setOtp] = useState("");
    const [load, setload] = useState(false);
    // spinner css
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const formData = watch();
    const sendOtp = () => {
        setError("");
        let data = { aadharNumber: formData.aadharNumber };
        Services.kycSendOTP("POST", JSON.stringify(data), token)
            .then((response) => {
                console.log(response);
                if (response.Status === 1) {
                    setload(false);
                    setRequestID(response.RequestID);
                    setShowOtp(true);
                    setFirstTime(false);
                } else {
                    setload(false);
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                setload(false);
                console.log(err);
            });
    };
    const onSubmit = async (data) => {
        if (!firstTime) {
            if (otp.length < 6) {
                setError("invalid OTP");
                return;
            }
            setload(true);
            Services.kycverifyOTP(
                "POST",
                JSON.stringify({
                    ...data,
                    OTP: otp,
                    RequestID: RequestID,
                }),
                token
            )
                .then((response) => {
                    console.log(response);
                    if (response.Status === 1) {
                        setload(false);
                        localStorage.setItem(
                            "userDetails",
                            JSON.stringify({
                                ...userData,
                                isKYCverified: 1,
                            })
                        );
                        setUserData({ ...userData, isKYCverified: 1 });
                    } else {
                        setload(false);
                        toast.error(`${response.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch((err) => {
                    setload(false);
                    console.log(err);
                });
        } else {
            setError("please verify aadhar");
        }
    };
    const otpChange = (otp) => {
        setError(null);
        setOtp(otp);
    };
    return (
        <Layout Active={"kyc"}>
            <div className={KycClasses["Container"]}>
                <h3>Kyc Verification </h3>
                {userData.isKYCverified === 0 ? (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={KycClasses["form-control"]}>
                            <select
                                className={
                                    errors.BusinessType
                                        ? KycClasses["error"]
                                        : ""
                                }
                                defaultValue={""}
                                {...register("BusinessType", {
                                    required: true,
                                })}>
                                <option value="">Select Business Type</option>
                                <option value="Proprietership/Individual">
                                    Proprietership/Individual
                                </option>
                                <option value="PVT ltd">PVT ltd</option>
                                <option value="Partnership">
                                    Partnership{" "}
                                </option>
                                <option value="Public Limited">
                                    Public Limited
                                </option>
                            </select>
                            {errors.BusinessType && (
                                <span>This field is required</span>
                            )}
                        </div>
                        <div className={KycClasses["form-control"]}>
                            <input
                                className={
                                    errors.PAN ? KycClasses["error"] : ""
                                }
                                placeholder="Enter Pan number"
                                type="text"
                                {...register("PAN", {
                                    required: true,
                                })}
                            />
                            {errors.PAN && <span>This field is required</span>}
                        </div>
                        {(formData.BusinessType == "PVT ltd" ||
                            formData.BusinessType == "Public Limited") && (
                            <div className={KycClasses["form-control"]}>
                                <input
                                    className={
                                        errors.CIN ? KycClasses["error"] : ""
                                    }
                                    type="text"
                                    placeholder="Enter CIN number"
                                    {...register("CIN", {
                                        required: true,
                                    })}
                                />
                                {errors.CIN && (
                                    <span>This field is required</span>
                                )}
                            </div>
                        )}
                        <div className={KycClasses["form-control"]}>
                            <input
                                className={
                                    errors.aadharNumber
                                        ? KycClasses["error"]
                                        : ""
                                }
                                disabled={showOtp}
                                type="number"
                                placeholder="Please enter Aadhar Number"
                                {...register("aadharNumber", {
                                    required: true,
                                })}
                            />
                            {errors.aadharNumber && (
                                <span>This field is required</span>
                            )}
                            <button type="button" onClick={sendOtp}>
                                GET OTP
                            </button>
                        </div>
                        {showOtp && (
                            <div className={KycClasses["otp-wrapper"]}>
                                <label htmlFor="Otp">Enter OTP</label>
                                <OtpInput
                                    containerStyle={KycClasses["container-otp"]}
                                    inputStyle={KycClasses["otp"]}
                                    value={otp}
                                    onChange={otpChange}
                                    numInputs={6}
                                    isInputSecure={true}
                                    shouldAutoFocus={true}
                                    separator={<span> </span>}
                                />
                            </div>
                        )}
                        {error && (
                            <span className={KycClasses["error-text"]}>
                                {error}
                            </span>
                        )}
                        {load && (
                            <div className={KycClasses["loader"]}>
                                <BounceLoader
                                    color={"blue"}
                                    loading={true}
                                    css={override}
                                    size={60}
                                />
                            </div>
                        )}
                        <button disabled={load}>Submit</button>
                    </form>
                ) : (
                    <div className={KycClasses["verified"]}>
                        <p>
                            <img src={check} alt="verify" />
                            Kyc verification Done
                        </p>
                    </div>
                )}
            </div>
        </Layout>
    );
}

export default Kyc;
