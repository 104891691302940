import React, { useRef, useEffect } from "react";
import ContactClasses from "./contact.module.css";
import Message from "../../Assets/Homepage/message.png";
import { NavLink } from "react-router-dom";
import party from "party-js";

function Subscription() {
    const ref = useRef();
    useEffect(() => {
        party.confetti(ref.current);
    }, []);
    return (
        <div className={ContactClasses["Container"]}>
            <img src={Message} alt="Message" ref={ref} />
            <h3>thank you for subscribing</h3>
            <p>
                you have successfully subscribed to our pay2me team. we will let
                you know when we launch
            </p>
            <NavLink to={"/"}>Back to Home</NavLink>
        </div>
    );
}

export default Subscription;
