import React from "react";
import classes from "./Bbps.module.css";
import { payBills } from "./Assets";
import { useNavigate } from "react-router";
function QuickPay() {
    const Navigate = useNavigate();
    const buttonHandler = (e) => {
        let category = e.currentTarget.getAttribute("id");
        localStorage.setItem("category", category);
        localStorage.setItem("QuickPaySupported", 0);
        Navigate(`/Quickpayment`);
    };
    return (
        <>
            <div className={classes["categories"]}>
                <div className={classes["actions"]}>
                    {payBills.map((item) => (
                        <div
                            className={classes["category"]}
                            onClick={buttonHandler}
                            id={item.Name}>
                            <img src={item.icon} alt="icon" />
                            <p>{item.Name}</p>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default QuickPay;
