import React from "react";
import Moment from "moment/moment";
import ComplaintClasses from "./Complaint.module.css";
function ComplaintFetch({ status }) {
    return (
        <div className={ComplaintClasses["fetch-Container"]}>
            <h1>Complaint Status</h1>
            <div>
                <div className={ComplaintClasses["fetch-detail"]}>
                    <h3>Agent ID</h3>
                    <p>:</p>
                    <h5>{status?.txnList?.agentId}</h5>
                </div>
                <div className={ComplaintClasses["fetch-detail"]}>
                    <h3>Amount</h3>
                    <p>:</p>
                    <h5>{Number(status?.txnList?.amount) / 100}</h5>
                </div>
                <div className={ComplaintClasses["fetch-detail"]}>
                    <h3>Biller Name</h3>
                    <p>:</p>
                    <h5>{status?.txnList?.billerName}</h5>
                </div>
                <div className={ComplaintClasses["fetch-detail"]}>
                    <h3>Txn Reference Id</h3>
                    <p>:</p>
                    <h5>{status?.txnList?.txnReferenceId}</h5>
                </div>
                <div className={ComplaintClasses["fetch-detail"]}>
                    <h3>Txn Date</h3>
                    <p>:</p>
                    <h5>
                        {/* <Moment>{new Date(status?.txnList?.txnDate)}</Moment> */}
                    </h5>
                </div>
                <div className={ComplaintClasses["fetch-detail"]}>
                    <h3>Txn Status</h3>
                    <p>:</p>
                    <h5>{status?.txnList?.txnStatus}</h5>
                </div>
            </div>
        </div>
    );
}

export default ComplaintFetch;
